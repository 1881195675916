import { createSlice } from "@reduxjs/toolkit";
import { exhibitionsAPI } from "../api/exhibitions";

const initialState = {
  myExhibitions: {
    items: [],
    total: 0
  },
  editExhibitionId: null,
  editStandId: null,
  isEditMode: false,
  existExhibitions: [],
  existExhibitionsArchive: [],
  exhibitionPanel: false,
  exhibitionFilterParams: null,
  editOnlyStand: false,
  filterParams: {
    limit: 10,
    page: 0
  },
  exhibitionFilters: [],
  exhibitionInitialValues: {},
  changeEdit: {
    exhibitionId: null,
    standId: null
  },
  checkBoxSelected: { isPrivate: null, isVisible: null }
};
const exhibitionSlice = createSlice({
  name: "exhibitions",
  initialState,
  reducers: {
    setIsExistExhibitions(state, action) {
      state.existExhibitions = action.payload;
    },
    setExistExhibitionsArchive(state, action) {
      state.existExhibitionsArchive = action.payload;
    },
    setMyExhibitions(state, action) {
      state.myExhibitions = action.payload;
    },
    setEditExhibitionId(state, action) {
      state.editExhibitionId = action.payload;
    },
    setEditStandId(state, action) {
      state.editStandId = action.payload;
    },
    setIsEditMode(state, action) {
      state.isEditMode = action.payload;
    },
    setExhibitionPanel(state, action) {
      state.exhibitionPanel = action.payload;
    },
    // setExhibitionFilterParams(state, action) {
    //   state.exhibitionFilterParams = action.payload;
    // },
    setExhibitionFilterParams(state, action) {
      state.exhibitionFilters = action.payload.filters;
      state.filterParams = action.payload.res;
    },
    setEditOnlyStand(state, action) {
      state.editOnlyStand = action.payload;
    },
    setExhibitionInitialValues(state, action) {
      state.exhibitionInitialValues = action.payload;
    },
    setChangeEdit(state, action) {
      state.changeEdit = action.payload;
    },
    setCheckBoxSelected(state, action) {
      state.checkBoxSelected = action.payload;
    }
  }
});

export const {
  setMyExhibitions,
  setEditStandId,
  setCheckBoxSelected,
  setEditExhibitionId,
  setIsEditMode,
  setExhibitionPanel,
  setIsExistExhibitions,
  setExhibitionFilterParams,
  setExistExhibitionsArchive,
  setEditOnlyStand,
  setExhibitionInitialValues,
  setChangeEdit
} = exhibitionSlice.actions;

export const fetchMyExhibitions =
  (qParams = {}) =>
  async (dispatch) => {
    console.log("qParams", qParams);
    try {
      const myExhibitions = (await exhibitionsAPI.getExhibitions(qParams)).data;
      dispatch(setMyExhibitions(myExhibitions));
    } catch (e) {
      console.log(e);
    }
  };

export default exhibitionSlice.reducer;
