import { Modal } from "antd";
import React from "react";
import { mediaUrl } from "../../api/api";
import { CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import styles from "./image-preview.module.less";

const ImagePreview = ({ closePreview, imageUrl, isOpen, openPreview }) => {
  return (
    <>
      <Modal
        style={{ justifyContent: "center" }}
        className={`${styles.modal} ${styles.modal}`}
        visible={isOpen}
        onOk={closePreview}
        onCancel={closePreview}
        footer={null}
        closeIcon={<CloseOutlined style={{ fontSize: "30px", color: "#fff" }} />}
      >
        <img className={styles.imgModal} src={`${mediaUrl}${imageUrl}`} alt="" />
      </Modal>
      <div onClick={openPreview} className={styles.imgContainer}>
        {imageUrl !== 'null' && <img
          className={styles.img}
          src={`${mediaUrl}${imageUrl}`}
          alt="passage diagram image"
        />}
      </div>
    </>
  );
};
ImagePreview.propTypes = {
  closePreview: PropTypes.func,
  openPreview: PropTypes.func,
  imageUrl: PropTypes.string,
  isOpen: PropTypes.bool
};

export default ImagePreview;
