/* eslint-disable react/prop-types */
/* eslint-disable no-extra-boolean-cast */
import React from "react";
import PropTypes from "prop-types";
import Trash from "../../../assets/svg/trash";
import { Checkbox, Form, Space, Tooltip, Upload } from "antd";
import {
  setCheckBoxSelected,
  setEditExhibitionId,
  setEditOnlyStand,
  setEditStandId,
  setIsEditMode
} from "../../../store/exhibitionSlice";
import { useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import ImagePreview from "../../ImagePreview/ImagePreview";
import { useState } from "react";
import { uploadsAPI } from "../../../api/api";
import dayjs from "dayjs";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Edit from "../../../assets/svg/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import s from "./secondary-table-row.module.less";
import { useEffect } from "react";

const SecondaryTableRow = ({
  isOnlyExhibition,
  stand = {},
  item,
  t,
  form,
  OnlyLatinLettersTextInput,
  handleDelete,
  handleOpenModal,
  isStandEditable = false,
  passageDiagram,
  setPassageDiagram,
  setIsImageDeleted = () => {},
  isImageDeleted = false,
  handleEdit,
  isUserExhibition,
  isArchive
}) => {
  const { isEditMode, editStandId, editExhibitionId, editOnlyStand } = useSelector(
    (state) => state.exhibitions
  );
  const { isAdmin } = useSelector((state) => state.auth);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const { pathname } = useLocation();

  const initialCheckbox = {
    isPrivate: item.isPrivate,
    isVisible: stand.isVisible
  };

  const [checkBoxSelected, setCheckboxSelected] = useState(initialCheckbox);

  const isEditIconShow =
    editExhibitionId === item.id
      ? isEditMode && (editExhibitionId === item.id || editStandId === stand?.id)
      : false;

  const itemPassageDiagram = item?.stands?.[0]?.passageDiagram;

  useEffect(() => {
    dispatch(setCheckBoxSelected(checkBoxSelected));
  }, [checkBoxSelected]);

  useEffect(() => {
    if (editExhibitionId !== item.id) {
      setCheckBoxSelected(initialCheckbox);
    }
  }, [editExhibitionId, item.id]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (itemPassageDiagram && isStandEditable) {
      setPassageDiagram(itemPassageDiagram);
    }
  }, [itemPassageDiagram, isStandEditable]);

  useEffect(() => {
    if (item.isPrivate || stand.isVisible) {
      setCheckboxSelected((prev) => ({
        ...prev,
        isPrivate: item.isPrivate,
        isVisible: stand.isVisible
      }));
    }
  }, [item.isPrivate, stand.isVisible]);

  const uploadImg = async (data) => {
    const formData = new FormData();
    formData.append("file", data.file, data.file.type.replace("/", "."));
    formData.append("originalname", data.file.name);
    const uploadedImage = await (await uploadsAPI.uploadImage(formData)).data.filename;
    setPassageDiagram(uploadedImage);
    setIsImageDeleted(false);
  };
  const normalizeFile = (event) => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };
  const beforeUpload = (file) => {
    if (file.size / 1024 > 1024) {
      clearImage();
      return false;
    }
  };
  const clearImage = () => {
    setIsImageDeleted(true);
    setPassageDiagram("");
  };

  const normalizeExhibitionData = ({
    exhibition,
    stand,
    boothNumber,
    description,
    website,
    areaName,
    exhibitionName,
    city,
    ...rest
  }) => {
    const startDate = exhibition.startDate;
    const endDate = exhibition.endDate;
    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);
    const daysCount = date2.diff(date1, "day");

    const extractedRangePickerArray = [];
    for (const key in rest) {
      if (key.includes("rangePicker")) {
        extractedRangePickerArray.push(rest[key]);
      }
    }

    function formatTime(date) {
      return dayjs(date.$d).format("HH:mm");
    }

    const formattedTimeArray = extractedRangePickerArray.map((nestedArray) => {
      const startTime = formatTime(nestedArray[0]);
      const endTime = formatTime(nestedArray[1]);
      return { startTime, endTime };
    });

    const workingHours = Array.from({ length: daysCount + 1 }, (_, i) => {
      return {
        startDate: date1.add(i, "day").format("DD.MM.YYYY"),
        timeRange: formattedTimeArray
      };
    });

    const newObj = {
      exhibitionName,
      website: website,
      countryId: exhibition.countryId,
      city: city,
      areaName: areaName,
      startDate: dayjs(exhibition.startDate.$d).format("DD-MM-YYYY"),
      endDate: dayjs(exhibition.endDate.$d).format("DD-MM-YYYY"),
      times: workingHours,
      isPrivate: isOnlyExhibition ? item.isPrivate : checkBoxSelected.isPrivate,
      stand: {
        isVisible: checkBoxSelected.isVisible,
        passageDiagram: passageDiagram ? passageDiagram : stand?.passageDiagram,
        boothNumber: boothNumber,
        description: description
      }
    };
    return newObj;
  };

  const closeImagePreview = () => setImagePreviewOpen(false);
  const openImagePreview = () => setImagePreviewOpen(true);

  const handleIsVisible = ({ target: checked }) => {
    setCheckboxSelected((prevState) => ({
      ...prevState,
      isVisible: checked.checked,
      isPrivate: false
    }));
  };

  const handleIsPrivate = ({ target: checked }) => {
    setCheckboxSelected((prevState) => ({
      ...prevState,
      isVisible: false,
      isPrivate: checked.checked
    }));
  };

  return (
    <div key={`${stand.id}`} className={s.stand} style={{ borderLeft: isOnlyExhibition && "none" }}>
      {!isAdmin && <div className={`${s.cell2} ${s.small}`}></div>}
      {!isOnlyExhibition && (
        <div className={s.standLeft}>
          <div>
            <div className="d-flex">
              <div className={`${s.cell} ${s.big}`} style={{ height: 54, alignItems: "center" }}>
                {t("table.boothNumber")}:
              </div>
            </div>
            <div className="d-flex">
              <div className={`${s.cell2} ${s.big}`}>
                {!isEditMode || !isStandEditable ? (
                  <p>{stand.boothNumber}</p>
                ) : (
                  <Form.Item
                    name={"boothNumber"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <TextArea
                      className={s.textInput}
                      defaultValue={stand.boothNumber}
                      placeholder={t("enterboothNumber", { ns: "CreateExhibitionPanel" })}
                      onChange={(e) =>
                        !isAdmin
                          ? OnlyLatinLettersTextInput(
                              e.target.value,
                              "boothNumber",
                              form,
                              true,
                              null,
                              null,
                              true
                            )
                          : e.target.value
                      }
                    />
                  </Form.Item>
                )}
              </div>
            </div>
          </div>
          <div key={stand.id}>
            <div style={{ height: 54, alignItems: "center" }} className={`${s.cell} ${s.big}`}>
              {t("table.schemaToStand")}:
            </div>
            <div className={`${s.cell2} ${s.big}`} style={{ width: 160 }}>
              {!isEditMode && !isStandEditable ? (
                stand?.passageDiagram &&
                stand?.passageDiagram.length && (
                  <>
                    <ImagePreview
                      isOpen={imagePreviewOpen}
                      closePreview={closeImagePreview}
                      openPreview={openImagePreview}
                      imageUrl={`${stand?.passageDiagram}`}
                    />
                  </>
                )
              ) : (
                <Form.Item
                  name={["stand", "passageDiagram"]}
                  valuePropName="fileList"
                  getValueFromEvent={normalizeFile}
                >
                  {isEditMode && isStandEditable && passageDiagram ? (
                    <>
                      {!isImageDeleted && passageDiagram ? (
                        <>
                          <ImagePreview
                            isOpen={imagePreviewOpen}
                            closePreview={closeImagePreview}
                            openPreview={openImagePreview}
                            imageUrl={`${passageDiagram}`}
                          />
                          <p className={s.deleteText} onClick={clearImage}>
                            {t("delete", { ns: "CreateExhibitionPanel" })}
                          </p>
                        </>
                      ) : (
                        <Upload
                          maxCount={1}
                          multiple={false}
                          accept=".jpg,.jpeg"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          customRequest={(data) => uploadImg(data)}
                        >
                          <p className={s.uploadText}>{t("uploadPassageToStandScheme")}</p>
                        </Upload>
                      )}
                    </>
                  ) : (
                    <>
                      {stand?.passageDiagram && isEditMode && !isStandEditable ? (
                        <ImagePreview
                          isOpen={imagePreviewOpen}
                          closePreview={closeImagePreview}
                          openPreview={openImagePreview}
                          imageUrl={`${stand?.passageDiagram}`}
                        />
                      ) : (
                        !passageDiagram &&
                        isEditMode &&
                        isStandEditable && (
                          <Upload
                            maxCount={1}
                            multiple={false}
                            accept=".jpg,.jpeg"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            customRequest={(data) => uploadImg(data)}
                          >
                            <p className={s.uploadText}>{t("uploadPassageToStandScheme")}</p>
                          </Upload>
                        )
                      )}
                    </>
                  )}
                </Form.Item>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={s.standRight}
        style={{
          borderLeft: isAdmin && !pathname.includes("/admin-panel/users-list") ? "none" : "1px"
        }}
      >
        <div
          className={s.standDescription}
          style={{
            width: "calc(100% - 160px)",
            maxWidth: isAdmin ? (isEditMode ? 2000 : 1851) : 1340,
            minWidth: isArchive ? 1100 : "unset"
          }}
        >
          {isEditMode && ((isOnlyExhibition && item.id === editExhibitionId) || isStandEditable) ? (
            <Form.Item
              name={"description"}
              rules={[
                {
                  required: true,
                  message: ""
                }
              ]}
            >
              <TextArea
                className={s.textInput}
                style={{ minHeight: 215 }}
                defaultValue={stand?.description || item?.description}
                placeholder={t("description", { ns: "CreateExhibitionPanel" })}
                onChange={(e) =>
                  !isAdmin
                    ? OnlyLatinLettersTextInput(
                        e.target.value,
                        "description",
                        form,
                        true,
                        null,
                        null,
                        true
                      )
                    : e.target.value
                }
              />
            </Form.Item>
          ) : (
            <p>{stand?.description || item?.description}</p>
          )}
        </div>
        <div
          className={`${s.controls} ${s.big}`}
          style={{ width: isAdmin && !pathname.includes("/admin-panel/users-list") ? 256 : 180 }}
        >
          {!isOnlyExhibition && (
            <div className={s.checkboxes}>
              {!isOnlyExhibition && (
                <Space>
                  <Form.Item
                    className={s.formItem}
                    name={["stand", "isVisible"]}
                    valuePropName="checked"
                    rules={[
                      {
                        required: editStandId === stand.id,
                        message: ""
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const isVisible = getFieldValue(["exhibition", "isPrivate"]);
                          if (!Boolean(value) && isVisible) {
                            return Promise.resolve();
                          } else if (Boolean(value) && !isVisible) {
                            return Promise.resolve();
                          } else if (Boolean(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject();
                          }
                        }
                      })
                    ]}
                  >
                    <Space>
                      <Checkbox
                        className="small"
                        style={{ borderColor: "red" }}
                        defaultChecked={stand.isVisible}
                        ariaChecked="isVisible"
                        checked={
                          editExhibitionId !== item.id
                            ? initialCheckbox.isVisible
                            : checkBoxSelected.isVisible
                        }
                        onChange={handleIsVisible}
                        disabled={
                          (!isAdmin && !isUserExhibition) || editOnlyStand || !isEditIconShow
                        }
                      />
                      <span>{t("table.isVisible")}</span>
                      <Tooltip
                        placement="bottomLeft"
                        title={
                          isUserExhibition
                            ? t("visibility", { ns: "CreateExhibitionPanel" })
                            : t("visibility_v2", { ns: "CreateExhibitionPanel" })
                        }
                      >
                        <QuestionCircleOutlined
                          style={{
                            position: "relative",
                            cursor: "help",
                            alignSelf: "end",
                            marginBottom: 0,
                            color: "#474747"
                          }}
                        />
                      </Tooltip>
                    </Space>
                  </Form.Item>
                </Space>
              )}
              <Space>
                <Form.Item
                  className={s.formItem}
                  name={["exhibition", "isPrivate"]}
                  valuePropName="checked"
                  rules={[
                    {
                      required: editExhibitionId === item.id,
                      message: ""
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const isVisible = getFieldValue(["stand", "isVisible"]);
                        if (!Boolean(value) && isVisible) {
                          return Promise.resolve();
                        } else if (Boolean(value) && !isVisible) {
                          return Promise.resolve();
                        } else if (Boolean(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject();
                        }
                      }
                    })
                  ]}
                >
                  <Space>
                    <Checkbox
                      className="small"
                      style={{ borderColor: "red" }}
                      defaultChecked={item.isPrivate}
                      checked={
                        editExhibitionId !== item.id
                          ? initialCheckbox.isPrivate
                          : checkBoxSelected.isPrivate
                      }
                      ariaChecked="isPrivate"
                      onChange={handleIsPrivate}
                      disabled={(!isAdmin && !isUserExhibition) || editOnlyStand || !isEditIconShow}
                    />
                    <span>{t("table.isPrivate")}</span>
                    <Tooltip
                      placement="bottomLeft"
                      title={
                        isUserExhibition
                          ? t("private", { ns: "CreateExhibitionPanel" })
                          : t("private_v2", { ns: "CreateExhibitionPanel" })
                      }
                    >
                      <QuestionCircleOutlined
                        style={{
                          position: "relative",
                          cursor: "help",
                          alignSelf: "end",
                          marginBottom: 0,
                          color: "#474747"
                        }}
                      />
                    </Tooltip>
                  </Space>
                </Form.Item>
              </Space>
            </div>
          )}
          <div
            className={s.actions}
            style={
              isOnlyExhibition || isEditMode
                ? {
                    marginTop: "auto",
                    borderTop: editExhibitionId === item.id ? "1px solid #e8edf0" : "none"
                  }
                : { width: isEditMode ? 169 : 177, marginTop: "auto" }
            }
          >
            <div className={s.wrap}>
              {!isArchive && (
                <>
                  {!isEditIconShow && (
                    <Tooltip
                      placement="topRight"
                      title={t("editTooltip")}
                      align={{ offset: [15, 0] }}
                    >
                      <div style={{ height: 17 }}>
                        <Edit
                          onClick={
                            !isEditMode
                              ? () => {
                                  if (!isAdmin && item.moderationStatusId === 1) {
                                    handleOpenModal("EDIT_MODE_ONMODER");
                                  } else if (!isAdmin && item.moderationStatusId === 3) {
                                    handleOpenModal("EDIT_MODE_ACCEPTED");
                                  } else if (!isAdmin) {
                                    handleOpenModal("EDIT_MODE_V2");
                                  } else {
                                    handleOpenModal("EDIT_MODE");
                                  }
                                  if (pathname.includes("/admin-panel/users-list")) {
                                    dispatch(setEditOnlyStand(true));
                                  }
                                  dispatch(setEditExhibitionId(item.id));
                                  dispatch(setIsEditMode(false));
                                  dispatch(setEditStandId(stand.id));
                                }
                              : () => {
                                  if (item.id !== editExhibitionId) {
                                    handleOpenModal("FINISH_EDITING");
                                    handleEdit({
                                      exhibitionId: item.id,
                                      standId: item?.stands?.[0].id
                                    });
                                    setCheckboxSelected(initialCheckbox);
                                  }
                                }
                          }
                        />
                      </div>
                    </Tooltip>
                  )}
                  {(isAdmin && !pathname.includes("/admin-panel/users-list")) || !isAdmin ? (
                    <Tooltip placement="left" title={isEditIconShow ? t("saveAndContinue") : ""}>
                      <button
                        className={`${s.save} ${!isEditIconShow ? s.disabled : ""}`}
                        type={!isEditIconShow ? "button" : "submit"}
                        disabled={!isEditIconShow}
                      >
                        S
                      </button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Tooltip placement="left" title={isEditIconShow ? t("sendModerationBtn") : ""}>
                    <button
                      className={`${s.moderate} ${!isEditIconShow ? s.disabled : ""}`}
                      type={"button"}
                      disabled={!isEditIconShow}
                      onClick={() => {
                        const sendData = form.getFieldsValue();

                        if (isEditMode && isAdmin) {
                          form.validateFields().then(() => {
                            navigate(
                              `/company-profile/${item.creatorId}/exhibition/${editExhibitionId}${
                                editStandId ? `?standId=${editStandId}` : ""
                              }`,
                              {
                                state: {
                                  fromAdminSendStand:
                                    isAdmin && pathname.includes("/admin-panel/users-list"),
                                  isFromAdmin: true,
                                  exhibitionId: editExhibitionId,
                                  standId: editStandId,
                                  exhibitionData: sendData,
                                  passageDiagram: passageDiagram,
                                  standDescription: stand?.description,
                                  exhibitionDescription: sendData?.description,
                                  isPrivate: item?.isPrivate,
                                  isImageDeleted: isImageDeleted,
                                  isNew: item?.isNew,
                                  isUserExhibition: isUserExhibition
                                }
                              }
                            );
                          });
                        } else if (!isEditMode && isAdmin) {
                          return false;
                        }

                        if (isEditMode && !isAdmin) {
                          form
                            .validateFields()
                            .then(() => {
                              const data = normalizeExhibitionData(sendData);
                              navigate("/account/exhibition-preview", {
                                state: {
                                  exhibition: { ...data },
                                  isPrivate: checkBoxSelected.isPrivate,
                                  isVisible: checkBoxSelected.isVisible,
                                  isNewExhibition: false,
                                  editStandId: editStandId,
                                  editExhibitionId: editExhibitionId,
                                  passageDiagram: isImageDeleted ? null : passageDiagram,
                                  standDescription: stand?.description,
                                  exhibitionDescription: item?.description,
                                  isImageDeleted: isImageDeleted,
                                  item: item,
                                  isUserExhibition: isUserExhibition
                                }
                              });
                            })
                            .catch(({ errorFields }) => console.log("errorFields", errorFields));
                        } else if (!isAdmin && !isEditMode) {
                          return false;
                        }
                      }}
                    >
                      {isAdmin ? "P" : "M"}
                    </button>
                  </Tooltip>
                </>
              )}
              <Tooltip placement="top" title={t("deleteTooltip")}>
                <div style={{ height: 20 }}>
                  <Trash onClick={() => handleDelete(stand, item)} stroke={"#222222"} />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryTableRow;

SecondaryTableRow.propTypes = {
  isOnlyExhibition: PropTypes.string,
  t: PropTypes.func,
  form: PropTypes.object,
  OnlyLatinLettersTextInput: PropTypes.func,
  passageDiagram: PropTypes.string,
  handleDelete: PropTypes.func,
  stand: PropTypes.object,
  item: PropTypes.object,
  setPassageDiagram: PropTypes.func,
  handleOpenModal: PropTypes.func,
  clearImage: PropTypes.func,
  isStandEditable: PropTypes.bool,
  isImageDeleted: PropTypes.bool,
  setIsImageDeleted: PropTypes.func,
  handleEdit: PropTypes.func
};
