import { Button, Col, Row, Tooltip } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import { adminLogout, userLogout } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";

import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";
import PropTypes from "prop-types";
import s from "./header.module.less";
import { useTranslation } from "react-i18next";
import Notifications from "../Notifications/Notifications";

export const Header = ({
  h1 = "Заголовок",
  withBottomPanel = true,
  redirect = false,
  setRegisterSuccess
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["Header", "FK0-mainCatalog"]);
  const { user, isAdmin } = useSelector((state) => state.auth);
  const ref = useRef(null);
  const [headerIsSticky, setHeaderIsSticky] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const location = useLocation();
  useEffect(() => {
    setTopOffset(ref.current?.clientHeight);
  }, [ref, t]);
  const linkState = useMemo(() => {
    if (location.pathname === "/") {
      return { goBack: false };
    } else {
      return { goBack: true };
    }
  }, [location]);
  useEffect(() => {
    if (location.pathname === "/") {
      if (headerIsSticky) setHeaderIsSticky(false);
    } else {
      if (!headerIsSticky) setHeaderIsSticky(true);
    }
  }, [location]);
  const [accOrAdminPage, setAccOrAdminPage] = useState(false);

  useEffect(() => {
    if (
      location.pathname.includes("account") ||
      location.pathname.includes("admin-panel") ||
      location.pathname.includes("meeting-moderator-cabinet")
    ) {
      setAccOrAdminPage(true);
    } else {
      setAccOrAdminPage(false);
    }
  }, [location]);
  // // console.log(location)
  const handleLogout = () => {
    dispatch(adminLogout());
    dispatch(userLogout());
  };
  // // console.log(user);
  useEffect(() => {
    // // console.log(user);
    // // console.log(isAdmin);
  }, [user, isAdmin]);
  return (
    <>
      {headerIsSticky && (
        <div style={{ height: topOffset }} className={`${!accOrAdminPage ? "mb-32" : ""}`}></div>
      )}
      <header
        ref={ref}
        className={`${s.header} ${headerIsSticky ? s.fixed : ""} ${
          !accOrAdminPage || !headerIsSticky ? "" : "mb-32"
        }`}
      >
        <Row className={s.top_row}>
          <div
            className={`${s.col} d-flex justify-content-center align-items-center`}
            style={{ flexBasis: "303px", flexShrink: 2 }}
          >
            <Link to="/" onClick={() => redirect && setRegisterSuccess(false)}>
              <img src="/images/logo.svg" alt="logo" />
            </Link>
          </div>
          <Col className={s.col} style={{ flexBasis: "auto", flexShrink: 2 }}>
            {h1 === "catalog" ? (
              <h1 className={user || isAdmin ? s.authed : s.not_authed}>
                {t("header_title1", { ns: "FK0-mainCatalog" })} <br /> B2B-ONE
              </h1>
            ) : (
              <h1>{h1}</h1>
            )}
          </Col>
          <Col
            className={`${s.col} ${s.auth_col} ml-auto pl-12`}
            style={{ flexBasis: "auto", flexShrink: 1 }}
          >
            {user && !isAdmin && <Notifications />}
            <LanguageSwitcher />
            {!accOrAdminPage ? (
              !user && !isAdmin ? (
                <>
                  <Button className={s.btn} type="text">
                    {" "}
                    <NavLink to="/login" state={linkState}>
                      {t("login")}
                    </NavLink>
                  </Button>
                  <Button className={s.btn} type="text">
                    <NavLink to="/register">{t("register")}</NavLink>
                  </Button>
                </>
              ) : (
                <>
                  {isAdmin && (
                    <Button className={`${s.btn}  mr-15`} type="text">
                      <NavLink to={"/admin-panel"}>{t("admin")}</NavLink>
                    </Button>
                  )}
                  {!isAdmin && (
                    <Tooltip title={t("lkTooltip")}>
                      <Button className={`${s.btn}  mr-15`} type="text">
                        <NavLink to={"/login"}>{t("profile")}</NavLink>
                      </Button>
                    </Tooltip>
                  )}
                  <Button className={`${s.btn}  mr-15`} type="text" onClick={handleLogout}>
                    <span style={{ padding: "12px 24px" }}>{t("logout")}</span>
                  </Button>
                </>
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        {withBottomPanel && (
          <Row className={s.bottom_row} justify="center">
            <Col className={s.col} span={4} align="center">
              <Button className={s.btn} type="text">
                <NavLink
                  to="/main-catalog"
                  className={({ isActive }) => (isActive ? s.active : "")}
                >
                  {t("toCatalogue")}
                </NavLink>
              </Button>
            </Col>
            <Col className={s.col} span={4} align="center">
              <Button className={s.btn} type="text">
                <NavLink to="/how-to-buy" className={({ isActive }) => (isActive ? s.active : "")}>
                  {t("howToBuy")}
                </NavLink>
              </Button>
            </Col>
            <Col className={s.col} span={4} align="center">
              <Button className={s.btn} type="text">
                <NavLink to="/how-to-sell" className={({ isActive }) => (isActive ? s.active : "")}>
                  {t("howToSell")}
                </NavLink>
              </Button>
            </Col>
            <Col className={s.col} span={4} align="center">
              <Button className={s.btn} type="text">
                <NavLink
                  to="/subscription-management"
                  className={({ isActive }) => (isActive ? s.active : "")}
                >
                  {t("priceList")}
                </NavLink>
              </Button>
            </Col>
            <Col className={s.col} span={4} align="center">
              <Button className={s.btn} type="text">
                <NavLink
                  to="/match-making"
                  className={({ isActive }) => (isActive ? s.active : "")}
                >
                  {t("matchMaking")}
                </NavLink>
              </Button>
            </Col>
            <Col className={s.col} span={4} align="center">
              <Button className={s.btn} type="text">
                <NavLink to="/contact-us" className={({ isActive }) => (isActive ? s.active : "")}>
                  {t("contactUs")}
                </NavLink>
              </Button>
            </Col>
          </Row>
        )}
      </header>
    </>
  );
};

Header.propTypes = {
  h1: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  withBottomPanel: PropTypes.bool,
  setRegisterSuccess: PropTypes.func,
  redirect: PropTypes.bool
};
