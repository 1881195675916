import { Button, Col, Form, Input, Modal, Row } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useOutletContext } from "react-router-dom";
import { backendUrl, domain, moderationChangesAPI } from "../../api/api";
import FilterTable from "../../components/FilterTable/FilterTable";
import FilterTableSearch from "../../components/FilterTable/FilterTableSearch";
import { makeColumnItem } from "../../helpers/tableHelpers";
import { convertDate, convertTime, getDateFrom } from "../../helpers/timeFormat";
import styles from "../FA9-RequestsList/requests-list.module.less";
import s from "./ModerationChanges.module.less";
export const ModerationChanges = () => {
  const { setTitle } = useOutletContext();
  const { t } = useTranslation([
    "FA52A-Moderation-changes",
    "utils",
    "FA9-RequestsList",
    "FK2-Meeting",
    "utils"
  ]);
  const { user, lang } = useSelector((state) => state.auth);

  const [moderationCount, setModerationCount] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [all_changes, setAllChanges] = useState([]);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [moderId, setModerId] = useState(null);

  const [rejectModalIsVisible, setRejectModalIsVisible] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [reason, setReason] = useState("");
  const [link, setLink] = useState("");
  const [disabledButtonId, setDisabledButtonId] = useState(null);
  const refContainer = useRef();

  const initialFilters = [
    {
      title: t("changesType"),
      filterName: "changeTypes",
      request: `${backendUrl}/moderations/search?changeTypes=`,
      items: [
        { id: 1, name: "company" },
        { id: 2, name: "product" },
        { id: 3, name: "service" },
        { id: 4, name: "exhibition" },
        { id: 5, name: "stand" }
      ],
      selectedValue: [],
      filterType: "text",
      dataField: "name",
      t: t
    },
    {
      title: t("company"),
      filterName: "companiesIds",
      request: `${backendUrl}/moderations/search?companyName=`,
      items: [],
      selectedValue: [],
      filterType: "search",
      dataField: "id",
      viewValue: "companyName"
    },
    {
      title: t("dateTo"),
      filterName: "dateFrom",
      request: `${backendUrl}/matchmaking/search?dateFrom=`,
      items: [],
      selectedValue: "",
      filterType: "date",
      dateData: ""
    },
    {
      title: t("dateFrom"),
      filterName: "dateTo",
      request: `${backendUrl}/matchmaking/search?dateTo=`,
      items: [],
      selectedValue: "",
      filterType: "date",
      dateData: ""
    },
    {
      title: t("changesStatus"),
      filterName: "statusesIds",
      request: `${backendUrl}/moderations/resources/statuses`,
      items: [],
      selectedValue: [],
      filterType: "text",
      dataField: "id",
      t: t
    }
  ];

  const [filters, setFilters] = useState(initialFilters);
  const [sorting, setSorting] = useState({ sortingName: "", sorter: "" });

  useEffect(() => {
    if (submitting) {
      submitHandler();
      setSubmitting(false);
    }
  }, [submitting, filters]);

  useEffect(() => {
    setTitle(t("title"));

    let params = {
      limit: pageSize,
      page: currentPage
    };

    filters.forEach((item) => {
      if (typeof item.dateData !== "undefined" && item.dateData) {
        return (params[item.filterName] = item.dateData);
      } else if (item.selectedValue.length) {
        return (params[item.filterName] = item.selectedValue.join(","));
      }
    });

    if (sorting.sortingName && sorting.sorter) {
      params["sortColumn"] = sorting.sortingName;
      params["sortDirection"] = sorting.sorter === "descend" ? "DESC" : "ASC";
    }
    moderationChangesAPI.moderationCount().then((res) => {
      setModerationCount(res.data);
    });

    moderationChangesAPI.getAllModerations({ ...params }).then((res) => {
      setAllChanges(res.data.items);
      setTotalPages(res.data.total);
      if (!res.data.items.length) {
        setCurrentPage(1);
      }
    });
  }, [t, lang, currentPage, pageSize, sorting]);

  const filterTableData = all_changes.length
    ? [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...all_changes?.map((item, i) => ({ ...item, key: `${item.id}_${i}` }))
      ]
    : [];

  const submitHandler = async () => {
    const res = {};
    filters.forEach((item) => {
      if (typeof item.dateData !== "undefined" && item.dateData) {
        return (res[item.filterName] = item.dateData);
      } else if (item.selectedValue.length) {
        return (res[item.filterName] = item.selectedValue.join(","));
      }
    });

    if (sorting.sortingName && sorting.sorter) {
      res["sortColumn"] = sorting.sortingName;
      res["sortDirection"] = sorting.sorter === "descend" ? "DESC" : "ASC";
    }

    if (sorting.sortingName && sorting.sorter) {
      res["sortColumn"] = sorting.sortingName;
      res["sortDirection"] = sorting.sorter === "descend" ? "DESC" : "ASC";
    }

    const resKeys = Object.keys(res);
    const resValues = Object.values(res);
    const response = await axios.get(
      `${backendUrl}/moderations?limit=${pageSize}&page=${currentPage}&${resKeys
        .map((key, i) => `${key}=${resValues[i]}`)
        .join("&")}`
    );
    if (!response.data.items.length) {
      setCurrentPage(1);
    }
    setAllChanges(response.data.items);
    setTotalPages(response.data.total);
  };

  const handleReject = (id, link) => {
    setModerId(id);
    setLink(link);
    setRejectModalIsVisible(true);
  };

  const handleSubmit = async (id) => {
    try {
      setDisabledButtonId(id);
      setIsLoading(true);
      const response = await moderationChangesAPI.changeModerationById(id, 2);
      if (response.status === 200) submitHandler();
    } catch (error) {
      setModalMessage(t(error.response.data.message, { ns: "utils" }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleLinkView = async (id) => {
    try {
      const response = await moderationChangesAPI.viewModarationLink(id);
      if (response.status === 200) submitHandler();
    } catch (error) {
      console.log(error);
    }
  };

  const filterTableColumns = [
    {
      ...makeColumnItem(t, "changesType", 200),
      sorter: true,
      dataIndex: "changeType",
      sortDirections: ["ascend", "descend"],
      render(_, { entityType }) {
        return <span>{t(entityType)}</span>;
      }
    },
    {
      ...makeColumnItem(t, "linkToChangedPage", 200),
      dataIndex: "entityType",
      render(_, { entityType, company, entityId, id, viewed, creatorAdmin, newInfo }) {
        return (
          <Link
            className={`${s.contact_link}`}
            to={
              entityType === "company"
                ? `/company-profile/${company?.id}?moderationId=${id}`
                : entityType === "exhibition"
                ? `/company-profile/${company?.id || creatorAdmin.id}/exhibition/${entityId}`
                : entityType === "stand"
                ? `/company-profile/${company?.id || creatorAdmin.id}/exhibition/${
                    newInfo.exhibitionId
                  }${`?standId=${entityId}`}`
                : `/company-profile/${company?.id}/${entityType}/${entityId}?moderationId=${id}`
            }
            target={"_blank"}
            onClick={!viewed ? () => handleLinkView(id) : () => false}
          >
            {entityType === "company"
              ? `${domain}company-profile/${company?.id}?moderationId=${id}`
              : entityType === "exhibition"
              ? `${domain}/company-profile/${company?.id || creatorAdmin.id}/exhibition/${entityId}`
              : entityType === "stand"
              ? `${domain}/company-profile/${company?.id || creatorAdmin.id}/exhibition/${
                  newInfo.exhibitionId
                }${`?standId=${entityId}`}`
              : `${domain}company-profile/${company?.id}/${entityType}/${entityId}`}
          </Link>
        );
      }
    },
    {
      ...makeColumnItem(t, "company", 200),
      sorter: true,
      dataIndex: "companyName",
      sortDirections: ["ascend", "descend"],
      render(_, { company, creatorAdmin }) {
        return <span>{company?.companyName || creatorAdmin?.firstName}</span>;
      }
    },
    {
      ...makeColumnItem(t, "changesStatus", 200),
      sorter: true,
      dataIndex: "status",
      sortDirections: ["ascend", "descend"],
      render(_, { status }) {
        return <span>{status?.localizedName[lang]}</span>;
      }
    },
    {
      ...makeColumnItem(t, "changesDate", 200),
      sorter: true,
      dataIndex: "createdAt",
      sortDirections: ["ascend", "descend"],
      render(_, { createdAt }) {
        return (
          <span>
            {convertDate(createdAt)} {convertTime(createdAt)}
          </span>
        );
      }
    },
    {
      ...makeColumnItem(t, "changeUpdate", 200),
      sorter: true,
      dataIndex: "updatedAt",
      sortDirections: ["ascend", "descend"],
      render(_, { updatedAt, updatedByAdmin }) {
        return (
          <>
            <span>
              {convertDate(updatedAt)} {convertTime(updatedAt)}
            </span>
            {updatedByAdmin && <p style={{ marginTop: "10px" }}>{updatedByAdmin?.email}</p>}
          </>
        );
      }
    },
    {
      ...makeColumnItem(t, "actions", 200),
      dataIndex: "entityType",
      render(_, { reason, id, entityType, company, entityId, status, viewed }) {
        if (reason) {
          return <span>{reason}</span>;
        } else if (reason === null && status?.id === 2) {
          return null;
        } else {
          return (
            <div className={s.actions}>
              <p
                className={`${s.contact_link} ${
                  (isLoading && disabledButtonId === id) || !viewed ? s.disabled : ""
                }`}
                onClick={() => handleSubmit(id)}
              >
                {t("confirm", { ns: "FK2-Meeting" })}
              </p>
              <p
                className={`${s.contact_link} ${
                  (isLoading && disabledButtonId === id) || !viewed ? s.disabled : ""
                }`}
                onClick={() =>
                  handleReject(
                    id,
                    entityType === "company"
                      ? `company-profile/${company?.id}`
                      : `company-profile/${company?.id}/${entityType}/${entityId}`
                  )
                }
              >
                {t("reject")}
              </p>
            </div>
          );
        }
      }
    }
  ];

  const changePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const onFinish = () => {
    moderationChangesAPI
      .rejectModerationById(moderId, {
        reason: reason,
        statusId: 3
      })
      .then(() => {
        form.resetFields();
        setRejectModalIsVisible(false);
        setModalIsVisible(false);
        submitHandler();
      });
  };
  const firstTableFilter = (period, status = "", lastDay, withTime = null) => {
    let dateFrom = moderationCountTemp.find((item) => item.period === period)?.dateFromWithTime;
    let dateTo = moderationCountTemp.find((item) => item.period === period)?.dateToday;
    let newFilters = initialFilters.map((item) => {
      if (item.filterName === "dateFrom") {
        if (!dateFrom) return { ...item, selectedValue: "", dateData: "" };
        return {
          ...item,
          selectedValue: dayjs(dateFrom.replace("%2B", "+")),
          dateData: dateFrom
        };
      }
      if (lastDay && !withTime && item.filterName === "dateTo") {
        if (!dateFrom) return { ...item, selectedValue: "", dateData: "" };
        return {
          ...item,
          selectedValue: dayjs(dateTo.replace("%2B", "+")),
          dateData: dateTo
        };
      }
      return item;
    });
    if (status) {
      newFilters = newFilters.map((item) => {
        if (item.filterName === "statusesIds") {
          return {
            ...item,
            selectedValue: [status]
          };
        }
        return item;
      });
      setFilters(newFilters);
      setSubmitting(true);
    } else {
      setFilters(newFilters);
      setSubmitting(true);
    }
  };
  const moderationCountTemp = moderationCount?.map((item) => ({
    dateFrom: getDateFrom(item.period),
    dateFromWithTime: getDateFrom(item.period, true),
    dateToday: getDateFrom("Today"),
    allmoderations: item.allmoderations ? item.allmoderations : 0,
    period: item?.period.toLowerCase()
  }));
  return (
    <div className={`regular_block ${styles.layout_block} ${styles.scrollable}`}>
      <Modal
        onOk={() => setModalMessage("")}
        onCancel={() => setModalMessage("")}
        visible={modalMessage}
        footer={[
          <Button key="ok" className="btn btn_v2" onClick={() => setModalMessage("")}>
            OK
          </Button>
        ]}
      >
        <p>{modalMessage}</p>
      </Modal>
      {modalIsVisible && (
        <Modal
          className={s.second_modal}
          visible={modalIsVisible}
          onOk={() => setModalIsVisible(false)}
          onCancel={() => setModalIsVisible(false)}
          footer={null}
        >
          <section style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "16px", fontWeight: 400 }}>{t("dearUser")}</span>
            <br />
            <span style={{ fontSize: "16px", fontWeight: 400 }}>{t("span")}</span>
            <Link style={{ margin: "10px 0 10px 0" }} to={`/${link}`}>
              {`${domain}${link}`}
            </Link>
            <span style={{ fontSize: "16px", fontWeight: 400 }}>{t("cancelledBySystem")}</span>
            <span style={{ fontSize: "16px", fontWeight: 400 }}>
              {t("reason")} {reason}
            </span>
            <br />
            <br />
            <span style={{ fontSize: "16px", fontWeight: 400 }}>{t("resolve")}</span>
            <br />
            <span style={{ fontSize: "16px", fontWeight: 400 }}>
              {t("span2")}{" "}
              <Link
                className={s.contact_link}
                style={{ display: "inline" }}
                to={"/terms-and-conditions"}
              >
                {t("agreement")}.
              </Link>
            </span>
            <br />
            <span style={{ fontSize: "16px", fontWeight: 400 }}>{t("withrespect")}</span>
            <span style={{ fontSize: "16px", fontWeight: 400 }}>{t("admin")}</span>
          </section>
          <br />
          <div className={s.buttons}>
            <Button
              className="btn btn_v8"
              type="default"
              onClick={() => setModalIsVisible(false)}
              size="large"
              style={{ padding: "14px 40px", float: "left" }}
            >
              {t("backToEdit")}
            </Button>

            <Button
              className="btn btn_v2"
              type="default"
              size="large"
              onClick={onFinish}
              style={{ padding: "14px 40px", float: "left" }}
            >
              {t("sendToUser")}
            </Button>
          </div>
        </Modal>
      )}
      {rejectModalIsVisible && (
        <Modal
          className={s.modal}
          visible={rejectModalIsVisible}
          onOk={() => setRejectModalIsVisible(false)}
          onCancel={() => {
            setRejectModalIsVisible(false);
            form.resetFields();
          }}
          footer={null}
        >
          <Form
            labelAlign="left"
            form={form}
            name="reason"
            initialValues={{}}
            autoComplete="off"
            requiredMark={false}
            validateTrigger="onSubmit"
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                marginBottom: "10px"
              }}
            >
              {t("yourReason")}
            </span>
            <Form.Item
              name={"reasonMessage"}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 40 }}
              style={{
                display: "flex",
                flexDirection: "column",
                height: "auto"
              }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || (value && value.trim().length === 0)) {
                      return Promise.reject(t("yourDesicion"));
                    } else if (value && value.trim().length < 5) {
                      return Promise.reject(t("min"));
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <Input.TextArea
                rows={5}
                autoSize
                size={"large"}
                cols={5}
                minLength={5}
                maxLength={300}
                className={s.TextArea}
                allowClear
                showCount
                placeholder={t("textInput")}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Item>
            <div className={s.buttons}>
              <Button
                className="btn btn_v2"
                type="default"
                htmlType="submit"
                onClick={() => {
                  reason.trim().length >= 5 ? setModalIsVisible(true) : "";
                }}
                size="large"
                style={{ padding: "14px 40px", float: "left" }}
              >
                {t("send")}
              </Button>

              <Button
                className="btn btn_v8"
                type="default"
                size="large"
                style={{ padding: "14px 40px", float: "left" }}
                onClick={() => {
                  setRejectModalIsVisible(false);
                  form.resetFields();
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
      <div className={styles.scrollable}>
        <div ref={refContainer} className={s.wrapper}>
          {user.roleId && user.roleId === 1 && (
            <a
              href={`${backendUrl}/exports/moderations`}
              style={{ display: "inline-block", marginRight: "auto" }}
            >
              <button className={`btn btn_v3 excel_btn`}>
                {t("downloadExel", { ns: "utils" })}
              </button>
            </a>
          )}
          <br />
          <br />

          <Row>
            <Col span={10}>
              <div className={s.countWrapper}>
                <span>
                  {t("allChanges")}{" "}
                  <span
                    className={s.statistic}
                    onClick={() => firstTableFilter("all", null, false)}
                  >
                    {moderationCountTemp.find((item) => item.period === "all")?.allmoderations}
                  </span>
                </span>
                <span>
                  {t("lastMonth")}{" "}
                  <span
                    className={s.statistic}
                    onClick={() => firstTableFilter("month", null, false)}
                  >
                    {moderationCountTemp.find((item) => item.period === "month")?.allmoderations}
                  </span>
                </span>
                <span>
                  {t("lastDay")}{" "}
                  <span className={s.statistic} onClick={() => firstTableFilter("day", null, true)}>
                    {moderationCountTemp.find((item) => item.period === "day")?.allmoderations}
                  </span>
                </span>
              </div>
            </Col>
            <Col span={10}>
              <div className={s.countWrapper}>
                <span>
                  {t("unproccessed")}{" "}
                  <span
                    className={s.unproccessed}
                    onClick={() => firstTableFilter("notprocessed", 1, false)}
                  >
                    {
                      moderationCountTemp.find((item) => item.period === "notprocessed")
                        ?.allmoderations
                    }
                  </span>
                </span>
                <span>
                  {t("proccessed")}{" "}
                  <span
                    className={s.statistic}
                    onClick={() => firstTableFilter("accepted", 2, false)}
                  >
                    {moderationCountTemp.find((item) => item.period === "accepted")?.allmoderations}
                  </span>
                </span>
                <span>
                  {t("rejected")}{" "}
                  <span
                    className={s.statistic}
                    onClick={() => firstTableFilter("rejected", 3, false)}
                  >
                    {moderationCountTemp.find((item) => item.period === "rejected")?.allmoderations}
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <FilterTableSearch
            node={refContainer}
            className={s.filter_table_search}
            filters={filters}
            setFilters={setFilters}
            initialFilters={initialFilters}
            submitHandler={submitHandler}
            changePageSize={changePageSize}
            pageSize={pageSize}
          />
          <div className={s.filter_table_container}>
            <FilterTable
              columns={filterTableColumns}
              data={filterTableData}
              current={currentPage}
              pageSize={pageSize}
              total={totalPages}
              onChange={setCurrentPage}
              tableChange={(newPagination, filters, sorter) => {
                if (sorter.order) {
                  setSorting({ sortingName: sorter.field, sorter: sorter.order });
                } else {
                  setSorting({ sortingName: "", sorter: "" });
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
