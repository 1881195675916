import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import productsReducer from "./productsSlice";
import dialogsReducer from "./dialogsSlice";
import incomingRequestsSellerReducer from "./incomingRequestsSellerSlice";
import incomingRequestsMeetingSellerReducer from "./incomingRequestsMeetingSellerSlice";
import incomingRequestsMeetingModerSellerReducer from "./incomingRequestsMeetingModerSellerSlice";
import outgoingRequestsMeetingSellerReducer from "./outgoingRequestsMeetingSellerSlice";
import outgoingRequestsMeetingModerSellerReducer from "./outgoingRequestsMeetingModerSellerSlice";
import outgoingRequestsBuyerReducer from "./outgoingRequestsBuyerSlice";
import paymentApplicationsReducer from "./paymentApplicationsSlice";
import outgoingRequestsMeetingBuyerReducer from "./outgoingRequestsMeetingBuyerSlice";
import outgoingRequestsMeetingModerBuyerReducer from "./outgoingRequestsMeetingModerBuyerSlice";
import incomingRequestsMeetingBuyerReducer from "./incomingRequestsMeetingBuyerSlice";
import incomingRequestsMeetingModerBuyerReducer from "./incomingRequestsMeetingModerBuyerSlice";
import usersListReducer from "./usersListSlice";
import companySlice from "./companySlice";
import catalogSlice from "./catalogSlice";
import settingSlice from "./settingSlice";
import resourcesSlice from "./resourcesSlice";
import servicesSlice from "./servicesSlice";
import changeCompanyProfile from "./changeCompanyProfileSlice";
// import adminSlice from "./adminSlice";
import mailTemplatesSlice from "./mailTemplatesSlice";
import complaintsSlice from "./complaintsSlice";
import techIssueSlice from "./techIssueSlice";
import employeesListSlice from "./employeesListSlice";
import promocodesReducer from "./promocodesSlice";
import addUserReducer from "./addUserSlice";
import subscriptionManagementReducer from "./subscriptionManagementSlice";
import notificationsReducer from "./notificationsSlice";
import adminInquiriesReducer from "./adminInquiriesSlice";
import countryReducer from "./countrySlice";
import matchMakingSlice from "./matchMakingSlice";
import balanceSlice from "./balanceSlice";
import notificationsFeedReducer from "./notificationsFeedSlice";
import exhibitionsReducer from "./exhibitionSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    // admin: adminSlice,
    settings: settingSlice,
    products: productsReducer,
    services: servicesSlice,
    company: companySlice,
    catalog: catalogSlice,
    resources: resourcesSlice,
    dialogs: dialogsReducer,
    changeCompanyProfile: changeCompanyProfile,
    incomingRequestsSeller: incomingRequestsSellerReducer,
    incomingRequestsMeetingSeller: incomingRequestsMeetingSellerReducer,
    incomingRequestsMeetingModerSeller: incomingRequestsMeetingModerSellerReducer,
    outgoingRequestsMeetingSeller: outgoingRequestsMeetingSellerReducer,
    outgoingRequestsMeetingModerSeller: outgoingRequestsMeetingModerSellerReducer,
    mailTemplates: mailTemplatesSlice,
    paymentApplications: paymentApplicationsReducer,
    outgoingRequestsBuyer: outgoingRequestsBuyerReducer,
    outgoingRequestsMeetingBuyer: outgoingRequestsMeetingBuyerReducer,
    outgoingRequestsMeetingModerBuyer: outgoingRequestsMeetingModerBuyerReducer,
    incomingRequestsMeetingBuyer: incomingRequestsMeetingBuyerReducer,
    incomingRequestsMeetingModerBuyer: incomingRequestsMeetingModerBuyerReducer,
    usersList: usersListReducer,
    complaints: complaintsSlice,
    techIssues: techIssueSlice,
    employees: employeesListSlice,
    promocodes: promocodesReducer,
    addUser: addUserReducer,
    subscriptionManagement: subscriptionManagementReducer,
    notifications: notificationsReducer,
    adminInquiries: adminInquiriesReducer,
    countries: countryReducer,
    matchmaking: matchMakingSlice,
    balance: balanceSlice,
    notificationsFeed: notificationsFeedReducer,
    exhibitions: exhibitionsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {},
      serializableCheck: false
    })
});

export default store;
