/* eslint-disable react/prop-types */

import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import React from "react";

const Sorter = ({ handleSort, name, sorting }) => {
  return (
    <span
      className="ant-table-column-sorter ant-table-column-sorter-full"
      style={{ fontSize: 11 }}
      onClick={() => handleSort(name)}
    >
      <span className="ant-table-column-sorter-inner">
        <CaretUpOutlined
          className={`anticon anticon-caret-up ant-table-column-sorter-up ${
            sorting?.sorter === "ASC" && sorting.sortingName === name ? "active" : ""
          }`}
        />
        <CaretDownOutlined
          className={`anticon anticon-caret-down ant-table-column-sorter-down ${
            sorting?.sorter === "DESC" && sorting.sortingName === name ? "active" : ""
          }`}
        />
      </span>
    </span>
  );
};

export default Sorter;
