import { convertDate, convertTime } from "../../helpers/timeFormat";
import s from "./Notifications.module.less";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { sendMarkNotificationsAsRead } from "../../store/notificationsFeedSlice";
import { useDispatch } from "react-redux";
import { showWorkTime } from "../../helpers/timeDate";
import { useSelector } from "react-redux";

const NotificationItem = ({ item, t, setOpened, tz }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.auth);
  const action = item?.notification?.action;
  const entityType = item?.notification?.entityType;
  const isModerated = item?.notification?.info?.isModerated;
  const side = item?.notification?.side;
  const startDate = item?.notification?.info?.startDate;
  const companyName = item?.notification?.info?.companyName;
  const remainingTimeBeforeMeeting = item?.notification?.info?.msgTime;
  const initiator = item?.notification?.info?.initiator;
  const entityId = item?.notification?.entityId;
  const reason = item?.notification?.info?.reason;

  let message = ``;
  let href = "";
  let customLink = null;
  if (entityType === "MEETING") {
    if (action === "NEW_INCOMING") {
      if (side === "SELLER") {
        if (isModerated) {
          href = "/account/incoming-requests-meeting-moder-seller";
        } else {
          href = "/account/incoming-requests-meeting-seller";
        }
        message = (
          <span>
            {isModerated ? t("NEW_MEETING_REQUEST_MODERATED") : t("NEW_MEETING_REQUEST")}.
          </span>
        );
      } else if (side === "BUYER") {
        if (isModerated) {
          href = "/account/incoming-requests-meeting-moder-buyer";
        } else {
          href = "/account/incoming-requests-meeting-buyer";
        }
        message = (
          <span>
            {isModerated ? t("NEW_MEETING_REQUEST_MODERATED") : t("NEW_MEETING_REQUEST")}{" "}
            {t("FROM_COMPANY")} {companyName}.
          </span>
        );
      }
    } else if (action === "NOTICE") {
      if (side === "SELLER") {
        if (isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-moder-seller";
        } else if (isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-moder-seller";
        } else if (!isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-seller";
        } else if (!isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-seller";
        }
      } else if (side === "BUYER") {
        if (isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-moder-buyer";
        } else if (isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-moder-buyer";
        } else if (!isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-buyer";
        } else if (!isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-buyer";
        }
      }
      message = (
        <span>
          {t("REMAINING_TIME_BEFORE_MEETING_1")} {companyName}{" "}
          {t("REMAINING_TIME_BEFORE_MEETING_2")} {t(remainingTimeBeforeMeeting)}.
        </span>
      );
    } else if (action === "CANCEL") {
      if (side === "SELLER") {
        if (isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-moder-seller";
        } else if (isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-moder-seller";
        } else if (!isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-seller";
        } else if (!isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-seller";
        }
        message = (
          <span>
            {isModerated ? t("BUYER_CANCELED_MEETING_MODERATED") : t("BUYER_CANCELED_MEETING")}{" "}
            {convertTime(startDate)} {convertDate(startDate)}.
          </span>
        );
      } else if (side === "BUYER") {
        if (isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-moder-buyer";
        } else if (isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-moder-buyer";
        } else if (!isModerated && initiator) {
          href = "/account/outgoing-requests-meeting-buyer";
        } else if (!isModerated && !initiator) {
          href = "/account/incoming-requests-meeting-buyer";
        }
        message = (
          <span>
            {t("COMPANY")} {companyName} {t("CANCELED")}{" "}
            {isModerated ? t("MEETING_WITH_MODERATION") : t("MEETING")} {t("SCHEDULED_FOR")}{" "}
            {convertTime(startDate)} {convertDate(startDate)}
          </span>
        );
      }
    } else if (action === "CONFIRMED") {
      if (side === "SELLER") {
        if (isModerated) {
          href = "/account/outgoing-requests-meeting-moder-seller";
        } else {
          href = "/account/outgoing-requests-meeting-seller";
        }
      } else if (side === "BUYER") {
        if (isModerated) {
          href = "/account/outgoing-requests-meeting-moder-buyer";
        } else {
          href = "/account/outgoing-requests-meeting-buyer";
        }
      }
      message = (
        <span>
          {t("COMPANY")} {companyName} {t("CONFIRMED")}{" "}
          {isModerated ? t("MEETING_WITH_MODERATION") : t("MEETING")} {t("SCHEDULED_FOR")}{" "}
          {convertTime(startDate)} {convertDate(startDate)}.
        </span>
      );
    }
  } else if (entityType === "INQUIRY") {
    href = "/account/payment-applications";
    if (action === "CHANGE_STATUS") {
      message = <span>{t("INQUIRY_STATUS_CHANGED")}</span>;
    } else if (action === "DETAILS_RECEIVED") {
      message = <span>{t("INQUIRY_FILE_RECEIVED")}</span>;
    } else if (action === "SUCCESS") {
      message = <span>{t("INQUIRY_SUCCESS")}</span>;
    } else if (action === "LINK_RECEIVED") {
      message = <span>{t("INQUIRY_LINK_RECEIVED")}</span>;
    }
  } else if (entityType === "ORDER") {
    if (action === "NEW_INCOMING" && side === "SELLER") {
      message = <span>{t("NEW_ORDER_REQUEST")}</span>;
      href = "/account/incoming-requests-seller";
    } else if (action === "GET_ACCESS" && side === "BUYER") {
      message = (
        <span>
          {t("CONTACT_DATA_SUCCESS_1")} {companyName} {t("CONTACT_DATA_SUCCESS_2")}
        </span>
      );
      href = "/account/outgoing-requests-buyer";
    }
  } else if (entityType === "COMPANY") {
    if (action === "NEW_MESSAGE") {
      message = (
        <span>
          {t("NEW_MESSAGE_FROM")} {companyName}.
        </span>
      );
      href = `/account/dialogs/${entityId}`;
    } else if (action === "TARIFF_EXPIRATION") {
      message = (
        <span>
          {t("TARIFF_EXPIRATION_1")} {t("TARIFF_EXPIRATION_2")}
        </span>
      );
      customLink = (
        <>
          <Link
            className="orange"
            to={"/subscription-management"}
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {t("TARIFF_EXPIRATION_3")}
          </Link>
          .
        </>
      );
    } else if (action === "MODERATION_UPPROVED") {
      message = (
        <span>
          {t("attention")} {t("COMPANYS")} {t("moderation_success")}
        </span>
      );
      href = `change-company-profile`;
    } else if (action === "MODERATION_REJECTED") {
      message = (
        <span>
          {t("attention_reject")} {t("COMPANYS").toLowerCase()} {t("reject_text")} {t("reason")}{" "}
          {reason}.
        </span>
      );
      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/change-company-profile"}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {`${t("theLink")}`}
          </Link>{" "}
          {t("moderation_reject")} {lang !== "en" && t(`your_${entityType}`)}
        </>
      );
    }
  } else if (entityType === "SERVICE") {
    if (action === "MODERATION_UPPROVED") {
      message = (
        <span>
          {t("attention")} {t(entityType)} {t("moderation_success")}
        </span>
      );
      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/edit-product-or-service"}
            state={{
              key: "2"
            }}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {t("theLink")}
          </Link>
          {t("forDetails")}
        </>
      );
    } else if (action === "MODERATION_REJECTED") {
      message = (
        <span>
          {t("attention_reject")} {t(entityType).toLowerCase()} {t("reject_text")} {t("reason")}{" "}
          {reason}.
        </span>
      );

      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/edit-product-or-service"}
            state={{
              key: "2"
            }}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {`${t("theLink")}`}
          </Link>{" "}
          {t("moderation_reject")} {lang !== "en" && t(`your_${entityType}`)}
        </>
      );
    }
  } else if (entityType === "PRODUCT") {
    if (action === "MODERATION_UPPROVED") {
      message = (
        <span>
          {t("attention")} {t(entityType)} {t("moderation_success")}
        </span>
      );
      href = `edit-product-or-service`;
    } else if (action === "MODERATION_REJECTED") {
      message = (
        <span>
          {t("attention_reject")} {t(entityType).toLowerCase()} {t("reject_text")} {t("reason")}{" "}
          {reason}.
        </span>
      );
      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/edit-product-or-service"}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {`${t("theLink")}`}
          </Link>{" "}
          {t("moderation_reject")} {lang !== "en" && t(`your_${entityType}`)}
        </>
      );
    }
  } else if (entityType === "EXHIBITION") {
    if (action === "MODERATION_UPPROVED") {
      message = (
        <span>
          {t("attention")} {t(entityType)} {t("moderation_success")}
        </span>
      );
      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/my-exhibitions"}
            state={{
              key: "2"
            }}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {t("theLink")}
          </Link>
          {t("forDetails")}
        </>
      );
    } else if (action === "MODERATION_REJECTED") {
      message = (
        <span>
          {t("attention_reject")} {t(entityType).toLowerCase()} {t("reject_text")} {t("reason")}{" "}
          {reason}.
        </span>
      );
      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/my-exhibitions"}
            state={{
              key: "2"
            }}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {`${t("theLink")}`}
          </Link>{" "}
          {t("moderation_reject")} {lang !== "en" && t(`your_${entityType}`)}
        </>
      );
    }
  } else if (entityType === "STAND") {
    if (action === "MODERATION_UPPROVED") {
      message = (
        <span>
          {t("attention")} {t("STAND")} {t("moderation_success")}
        </span>
      );
      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/my-exhibitions"}
            state={{
              key: "2"
            }}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {t("theLink")}
          </Link>
          {t("forDetails")}
        </>
      );
    } else if (action === "MODERATION_REJECTED") {
      message = (
        <span>
          {t("attention_reject")} {t(entityType).toLowerCase()} {t("reject_text")} {t("reason")}{" "}
          {reason}.
        </span>
      );
      customLink = (
        <>
          {t("click")}{" "}
          <Link
            to={"/account/my-exhibitions"}
            state={{
              key: "2"
            }}
            className="orange"
            onClick={() => {
              setOpened(false);
              dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
            }}
          >
            {`${t("theLink")}`}
          </Link>{" "}
          {t("moderation_reject")} {lang !== "en" && t(`your_${entityType}`)}
        </>
      );
    }
  } else {
    message = null;
    href = "/no-link";
  }
  return (
    <div className={s.notificationItem} data-id={item?.id} data-isread={item?.isRead}>
      <div className={!item?.isRead ? s.notRead : ""}>
        {!message
          ? `${item?.notification?.action} - ${item?.notification?.entityType} isModerated: ${isModerated}`
          : message}{" "}
        {customLink ? (
          customLink
        ) : (
          <>
            {t("click")}{" "}
            <Link
              to={href}
              className="orange"
              onClick={() => {
                setOpened(false);
                dispatch(sendMarkNotificationsAsRead([Number(item.id)]));
              }}
            >
              {t("theLink")}
            </Link>
            {t("forDetails")}
          </>
        )}
      </div>

      <div className={s.date}>
        {/*{convertDate(item?.createdAt)} {convertTime(item?.createdAt)}*/}
        {showWorkTime(item?.createdAt, tz)}
      </div>
    </div>
  );
};
NotificationItem.propTypes = {
  item: PropTypes.object,
  t: PropTypes.func,
  setOpened: PropTypes.func,
  tz: PropTypes.string
};
export default NotificationItem;
