import React, {useCallback, useMemo, useState} from 'react';
import {convertDate, convertDateWithoutYears, convertTime} from '../../../helpers/timeFormat';
import {Button, Image, Modal, Table} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {mediaUrl, profileAPI} from '../../../api/api';
import s from './ExhibitionTable.module.less';
import style from '../../FK2.1-productPage/ProductPage.module.less';
import {CloseOutlined} from '@ant-design/icons';
import {NavLink, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import ImagePreview from '../../../components/ImagePreview/ImagePreview';
import {me} from '../../../store/authSlice';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';

const ExhibitionTableItem = ({index, item, t, companyId, user, link}) => {
  const dispatch = useDispatch()
  const {lang} = useSelector((state) => state.auth);
  const [img, setImg] = useState();
  const [expand, setExpand] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [openIsModal, setOpenIsModal] = useState(null);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const closeImagePreview = () => setImagePreviewOpen(false);
  const openImagePreview = () => setImagePreviewOpen(true);
  const navigate = useNavigate();
  const { id } = useParams()
  const modalText = useTranslation('FK2-Meeting');

  const btnHandler = useCallback(() => {
    if (user?.id === companyId) setModalIsVisible(true)
    else if (!user) return navigate('/login', {state: {moveOn: `/company-profile/${companyId}/meeting-at-the-exhibition?id=${item.id}#table`}})
    else if (user?.company?.type === 2) setOpenIsModal(true)
    else return navigate(`/company-profile/${id}/meeting-at-the-exhibition?id=${item.id}#table`)
  }, [item, id, user])





  const convertDescription = useCallback((string = '', length) => {
    if (string?.length > length && !expand) {
      return <div><span>{string.slice(0, length) + '...'}</span> <span className={s.expand} onClick={() => setExpand(true)}>{t('exhibition.expand')}</span></div>
    } else if (string.length < length || expand) {
      return string;
    }
  }, [expand, t, lang]);

  const columns = useMemo(() => [
    {
      title: <div className={s.index}>{index}</div>,
      dataIndex: 'index',
      key: 'index',
      width: 50,
    },
    {
      title: t('exhibition.name'),
      dataIndex: 'exhibitionName',
      key: 'exhibitionName',
      width: 200,
      render(_, {exhibitionName, stands}) {
        if (stands) return (
          <NavLink to={`/company-profile/${stands[0]?.companyId}/exhibition/${stands[0]?.exhibitionId}?standId=${stands[0]?.id}`} target={'_blank'} className={s.linkExhibitionName}>{exhibitionName}</NavLink>
        );
      },
    },
    {
      title: t('exhibition.website'),
      dataIndex: 'website',
      key: 'website',
      width: 200,
      render(_, {website}) {
        return (
          <a target='_blank' className={s.linkExhibitionName} href={website}>{website}</a>
        );
      },
    },
    {
      title: t('exhibition.country'),
      dataIndex: 'country',
      key: 'country',
      render(_, {country}) {
        return (
          <p>{country?.localizedName[lang]}</p>
        );
      },
    },
    {
      title: t('exhibition.city'),
      dataIndex: 'city',
      key: 'city',

    },
    {
      title: t('exhibition.area'),
      dataIndex: 'areaName',
      key: 'areaName',
    },
    {
      title: t('exhibition.dateStart'),
      dataIndex: 'startDate',
      key: 'startDate',
      render(_, {startDate}) {
        return (
          <p>{dayjs(startDate).format("DD.MM.YYYY")}</p>
        );
      },
    },
    {
      title: t('exhibition.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      render(_, {endDate}) {
        return (
          <p>{dayjs(endDate).format("DD.MM.YYYY")}</p>
        );
      },
    },
    {
      title: t('exhibition.workingHours'),
      dataIndex: 'times',
      key: 'times',
      render(_, {times}) {
        return (
          <>{times?.map(m =>
            <p>{m.date}:
              {m.startTime}-
              {m.endTime}</p>)}</>
        );
      },
    },
  ], [lang, t]);
  const columnsBottom = useMemo(() => [
    {
      title: <div className={s.title}/>,
      dataIndex: 'index',
      key: 'index',
      width: 50,
    },
    {
      title: t('exhibition.boothNumber'),
      dataIndex: 'boothNumber',
      key: 'boothNumber',
      width: 200,
    },
    {
      title: t('exhibition.passageScheme'),
      dataIndex: 'passageDiagram',
      key: 'passageDiagram',
      width: 200,
      render: (passageDiagram) => (
        <div className={s.imgWrapper}>
          {passageDiagram && <ImagePreview
            isOpen={imagePreviewOpen}
            openPreview={openImagePreview}
            closePreview={closeImagePreview}
            imageUrl={passageDiagram}
          />
          }
        </div>
      ),
    },
  ], [lang, t, imagePreviewOpen]);

  return (
    <>
      <Modal
        visible={modalIsVisible}
        onCancel={() => setModalIsVisible(false)}
        footer={[
          <Button
            key="ok"
            className="btn btn_v2"
            onClick={() => {
              setModalIsVisible(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <p>{modalText.t('cannotSendToThisCompany')}</p>
      </Modal>

      <Modal
        className={s.modal}
        visible={openIsModal}
        onOk={() => setOpenIsModal(null)}
        onCancel={() => setOpenIsModal(null)}
        footer={null}
      >
        <p>{t('modalTitle')}</p>
        <div className={s.btnWrapper}>
          <Button
            key="ok"
            className="btn btn_v2"
            style={{marginRight: '10px'}}
            onClick={() => {
              profileAPI
                .enableProfile(1).then(() => {
                dispatch(me());
                navigate(`/company-profile/${companyId}/meeting-at-the-exhibition?id=${item.id}#table`)
              })
                .catch((e) => {
                  console.log(e.response);
                }).finally(() => setOpenIsModal(null));
            }}
          >
            {t(openIsModal === 3 ? 'btn3' : 'btn1')}
          </Button>
          <Button
            key="cancel"
            className="btn btn_v3"
            onClick={() => {
              setOpenIsModal(null);
            }}
          >
            {t('btn2')}
          </Button></div>
      </Modal>
      <Modal
        style={{justifyContent: 'center'}}
        className={`${style.modal} ${s.modal}`}
        visible={img}
        onOk={() => setImg(null)}
        onCancel={() => setImg(null)}
        footer={null}
        closeIcon={<CloseOutlined style={{fontSize: '30px', color: '#fff'}}/>}
      >
        <img className={style.imgModal} src={`${mediaUrl}${img}`} alt=""/>
      </Modal>
      <div style={{display: 'flex'}}>
        <div className={s.tableWrapper} style={{overflow: 'auto'}}>
          <div className={s.main}>
            <Table
                columns={columns}
                dataSource={[item]}
            />
            <div className={s.tableWrapper}>
              <Table
                  className={s.table}
                  columns={columnsBottom}
                  dataSource={item?.stands}
              />
              <div className={s.descr}>{convertDescription(item?.stands && item?.stands[0]?.description, 400)}</div>
            </div>
          </div>
        </div>
        <div className={s.btn} onClick={btnHandler}>{t('exhibition.meeting')}</div>
      </div>
    </>
  );
};

export default ExhibitionTableItem;
