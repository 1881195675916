/* eslint-disable react/prop-types */
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Input, Select } from "antd";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import { debounce } from "lodash";

const DebounceSearchSelect = ({ search, store, debounceTimeout = 800, ...props }) => {
  const { t } = useTranslation("Search");
  const [openState, setOpenState] = useState(false);
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (e) => {
      if (!search || !store) return;

      const value = e.target.value.trim();
      if (!value) {
        store([]);
        return;
      }

      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      search(value)
        .then(({ data }) => {
          if (fetchId !== fetchRef.current) return;
          store(data);
        })
        .finally(() => {
          setFetching(false);
        });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);

  useEffect(() => debounceFetcher.cancel, [debounceFetcher]);

  return (
    <Select
      showArrow
      showSearch={false}
      placeholder={t("all")}
      maxTagCount="responsive"
      // maxTagTextLength={4}
      style={{ height: "32px !important" }}
      open={openState}
      getPopupContainer={() => (props?.node ? props.node.current : document.getElementById("root"))}
      onDropdownVisibleChange={setOpenState}
      filterSort={(a, b) => a.label.localeCompare(b.label)}
      suffixIcon={
        <DropdownIcon
          onClick={() => {
            setOpenState((prevState) => !prevState);
          }}
        />
      }
      dropdownClassName="z"
      dropdownRender={(menu) => (
        <div style={{ padding: "0 8px 4px" }}>
          <Input.Search
            placeholder={t("search")}
            allowClear
            loading={fetching}
            onChange={debounceFetcher}
          />
          <Divider style={{ margin: "8px 0" }} />
          {menu}
        </div>
      )}
      {...props}
    />
  );
};

export default DebounceSearchSelect;
