import { createSlice } from "@reduxjs/toolkit";
import { mailTemplateAPI } from "../api/api";
import { setIsLoading } from "./authSlice";

export const EXPORTER_INCOMING_REQUEST_MAIL_TEMPLATE = "exporterIncomingRequestMailTemplate";
export const EMAIL_CONFIRMATION_MAIL_TEMPLATE = "emailConfirmationMailTemplate";
export const PASSWORD_RECOVERY_TEMPLATE = "passwordRecoveryTemplate";
export const MEETING_INVITATION_TEMPLATE = "meetingInvitationTemplate";
export const MEETING_INVITATION_MODER_TEMPLATE = "meetingInvitationModerTemplate";
export const MEETING_INVITATION_FROM_SELLER_TEMPLATE = "meetingInvitationFromSellerTemplate";
export const MEETING_INVITATION_FROM_SELLER_MODER_TEMPLATE =
  "meetingInvitationFromSellerModerTemplate";
export const PAYMENT_CONFIRMATION_MAIL_TEMPLATE = "paymentConfirmationMailTemplate";
export const BUYER_AND_SELLER_FEEDBACK_MAIL_TEMPLATE = "buyerAndSellerFeedbackMailTemplate";
export const REJECTED_CHANGES_NOTIFICATION_MAIL_TEMPLATE =
  "rejectedChangesNotificationMailTemplate";
export const MEETING_NOTIFICATION_MAIL_TEMPLATE = "meetingNotificationMailTemplate";
export const STAND_CANCELED_MAIL_TEMPLATE = "standCanceledMailTemplate";
const initialState = {
  exporterIncomingRequestMailTemplate: {},
  emailConfirmationMailTemplate: {},
  passwordRecoveryTemplate: {},
  meetingInvitationTemplate: {},
  meetingInvitationModerTemplate: {},
  paymentConfirmationMailTemplate: {},
  buyerAndSellerFeedbackMailTemplate: {},
  rejectedChangesNotificationMailTemplate: {},
  meetingInvitationFromSellerTemplate: {},
  meetingInvitationFromSellerModerTemplate: {},
  meetingNotificationMailTemplate: {},
  standCanceledMailTemplate: {}
};
const mailTemplatesSlice = createSlice({
  name: "mailTemplates",
  initialState,
  reducers: {
    setMailTemplate(state, action) {
      state[action.payload.mailTemplateName][action.payload.lang] = {
        template: action.payload.mailTemplateContent,
        subject:
          action.payload?.subject ||
          state[action.payload.mailTemplateName][action.payload.lang]?.subject ||
          ""
      };
    },
    setMailTemplateSubject(state, action) {
      state[action.payload.mailTemplate] = {
        ...state[action.payload.mailTemplate],
        [action.payload.lang]: {
          ...state[action.payload.mailTemplate][action.payload.lang],
          subject: action.payload.subject
        }
      };
    }
  }
});

export const { setMailTemplate, setMailTemplateSubject } = mailTemplatesSlice.actions;
export const getEIRTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getExporterIncomingRequestTemplate(lang)
    .then((res) => {
      dispatch(
        // setEIRTemplate({ lang, template: res.data.template, subject: res.data.subject })
        setMailTemplate({
          lang,
          mailTemplateName: EXPORTER_INCOMING_REQUEST_MAIL_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getEmailConfirmationMailTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getEmailConfirmationMailTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: EMAIL_CONFIRMATION_MAIL_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getPasswordRecoveryTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getPasswordRecoveryTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: PASSWORD_RECOVERY_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getMeetingInvitationTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getMeetingInvitationTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: MEETING_INVITATION_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getMeetingInvitationModerTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getMeetingInvitationModerTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: MEETING_INVITATION_MODER_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getMeetingInvitationFromSellerTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getMeetingInvitationFromSellerTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: MEETING_INVITATION_FROM_SELLER_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {})
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getMeetingInvitationFromSellerModerTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getMeetingInvitationFromSellerModerTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: MEETING_INVITATION_FROM_SELLER_MODER_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {})
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getPaymentConfirmationMailTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getPaymentConfirmationMailTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: PAYMENT_CONFIRMATION_MAIL_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getBuyerAndSellerFeedbackMailTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getBuyerAndSellerFeedbackMailTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: BUYER_AND_SELLER_FEEDBACK_MAIL_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getRejectedChangesNotificationMailTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getRejectedChangesNotificationMailTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: REJECTED_CHANGES_NOTIFICATION_MAIL_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      // console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getMeetingNotificationMailTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getMeetingNotificationMailTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: MEETING_NOTIFICATION_MAIL_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      console.log(e.response);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const getStandCanceledMailTemplate = (lang) => (dispatch) => {
  dispatch(setIsLoading(true));
  mailTemplateAPI
    .getStandCanceledMailTemplate(lang)
    .then((res) => {
      dispatch(
        setMailTemplate({
          lang,
          mailTemplateName: STAND_CANCELED_MAIL_TEMPLATE,
          mailTemplateContent: res.data.template,
          subject: res.data.subject
        })
      );
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export default mailTemplatesSlice.reducer;
