import { Button, Modal, Table } from "antd";
import { convertDate, convertTime } from "../../helpers/timeFormat";
import { makeColumn, sortHandler } from "Helpers/tableHelpers";
import { useCallback, useMemo, useState } from "react";

import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Sorter from "Components/ExhibitionTable/Sorter";
import { recordsAPI } from "../../api/api";
import s from "../Promocodes/PromocodesTable.module.less";
import styles from "./RecordsOfDeletionsTable.module.less";
import { useTranslation } from "react-i18next";

const RecordsOfDeletionsTable = ({
  pagination,
  sorting,
  dataSource,
  loading,
  setSorting,
  fetchData
}) => {
  const { t } = useTranslation(["FA100-RecordsOfDeletions", "utils"]);
  const [record, setRecord] = useState({ name: "", id: 0 });
  const [modal, showModal] = useState(false);

  const restoreRecord = useCallback(
    (id) => {
      recordsAPI
        .restoreDeletionHistory(id)
        .then(() => {
          fetchData();
        })
        .catch(console.log);
    },
    [fetchData]
  );

  const renderSorter = (props) => props;

  // я использую ту же функцию сортировки setSorting, которая используется внутри самой таблицы, но просто вставляю имена и значения вручную
  const handleCountSort = (name) => {
    if (sorting.sortColumn === name) {
      setSorting({
        sortColumn: name,
        sortDirection: sorting.sortDirection === "ASC" ? "DESC" : "ASC"
      });
    } else {
      setSorting({
        sortColumn: name,
        sortDirection: "ASC"
      });
    }
  };

  const countSorting = useMemo(
    () => ({ sortingName: sorting?.sortColumn, sorter: sorting.sortDirection }),
    [sorting, sorting?.sortColumn, sorting?.sortDirection]
  );

  const columns = useMemo(
    () => [
      makeColumn(t, "name", 170, "name"),
      {
        ...makeColumn(t, "type", 120, "type"),
        render: (_, { type }) =>
          type === "product"
            ? t("types.product")
            : type === "service"
            ? t("types.service")
            : type === "stand"
            ? t("types.stand")
            : t("types.exhibition")
      },
      {
        ...makeColumn(t, "creator", 130, "creator"),
        render: (_, { creator, creatorId, creatorType }) => (
          <NavLink to={`/company-profile/${creatorId}`}>
            {creatorType === "admin" ? creator.email : creator.companyName}
          </NavLink>
        )
      },
      {
        ...makeColumn(t, "createdAt", 130, "createdAt"),
        render: (_, { createdAt }) => `${convertDate(createdAt)} ${convertTime(createdAt)}`
      },
      {
        ...makeColumn(t, "deletedAt", 130, "deletedAt"),
        render: (_, { deletedAt }) => `${convertDate(deletedAt)} ${convertTime(deletedAt)}`
      },
      {
        ...makeColumn(t, "whoDeleted", 130, "destroyer"),
        render: (_, { destroyer, destroyerId, destroyerType }) => (
          <NavLink to={`/company-profile/${destroyerId}`}>
            {destroyerType === "admin" ? destroyer.email : destroyer.companyName}
          </NavLink>
        )
      },
      // вместо заголовка, который отображается внутри заголовка таблицы, я добавил компонент с отдельными именами и сортировщиками.
      {
        // renderSorter, чтобы просто отображать компонент вместо перевода
        ...makeColumn(
          renderSorter,
          // имя столбца и сортировщик для каждого типа
          <div className={styles.multiSorterContainer}>
            <div className={styles.multiSorterContainer__sorter}>
              {t("count1")}
              <Sorter
                handleSort={() => handleCountSort("statsCountCompany")}
                sorting={countSorting}
              />
            </div>
            <div
              className={styles.multiSorterContainer__sorter}
              onClick={() => handleCountSort("statsUnconfirmedMeetings")}
            >
              {t("count2")}
              <Sorter
                handleSort={handleCountSort}
                name={"statsUnconfirmedMeetings"}
                sorting={countSorting}
              />
            </div>
            <div
              className={styles.multiSorterContainer__sorter}
              onClick={() => handleCountSort("statsConfirmedMeetings")}
            >
              {t("count3")}
              <Sorter
                handleSort={handleCountSort}
                name={"statsConfirmedMeetings"}
                sorting={countSorting}
              />
            </div>
          </div>,
          220
        ),
        render: (_, { type, statsConfirmedMeetings, statsUnconfirmedMeetings, data }) =>
          type === "product" || type === "service"
            ? "-"
            : type === "stand"
            ? `0/${data.statsUnconfirmedMeetings || 0}/${data.statsConfirmedMeetings || 0}`
            : type === "exhibition"
            ? `${data.statsCountCompany || 0}/${data.statsUnconfirmedMeetings || 0}/${
                data.statsConfirmedMeetings || 0
              }`
            : `0/${statsUnconfirmedMeetings || 0}/${statsConfirmedMeetings || 0}`
      },
      {
        ...makeColumn(t, "actions", 130),
        render: (_, { id, name }) => (
          <a
            className={s.link}
            onClick={() => {
              setRecord({
                id,
                name
              });

              showModal(true);
            }}
          >
            {t("restore")}
          </a>
        )
      }
    ],
    [t, restoreRecord]
  );

  return (
    <section className={s.section}>
      <Modal
        visible={modal}
        onCancel={() => showModal(false)}
        footer={[
          <Button
            key="ok"
            className="btn btn_v12"
            onClick={() => {
              restoreRecord(record.id);
              showModal(false);
            }}
          >
            {t("buttons.yes", { ns: "utils" })}
          </Button>,
          <Button key="cancel" className="btn btn_v6" onClick={() => showModal(false)}>
            {t("buttons.no", { ns: "utils" })}
          </Button>
        ]}
      >
        <p>{t("restoreModalMessage")}</p>
        <p>{record.name}</p>
      </Modal>
      <Table
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        onChange={sortHandler(setSorting)}
      />
    </section>
  );
};

RecordsOfDeletionsTable.propTypes = {
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  dataSource: PropTypes.array,
  fetchData: PropTypes.func,
  setSorting: PropTypes.func,
  sorting: PropTypes.obj
};

export default RecordsOfDeletionsTable;
