import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Dubai");

const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
};
/* eslint-disable */
const formatter = new Intl.DateTimeFormat("ru-Ru", options);

export const timeFormatter = (date) => formatter.format(new Date(date)).replace(", ", "\n");

export const timeFormatter2 = (items) =>
  items.map((item) => {
    const [date, time] = formatter.format(new Date(item.createdAt)).split(", ");
    return {
      ...item,
      createdAt: date
    };
  });

export const convertDate = (str) => {
  const date = new Date(str);
  const day = typeof str === "undefined" ? "Invalid date" : dayjs(str).tz().format("DD.MM.YYYY");
  return day;
};

export const convertTime = (str) => {
  // // console.log(typeof str === "undefined");
  const date = new Date(str);
  const hours = typeof str === "undefined" ? "Invalid date" : dayjs(str).tz().format("HH:mm");
  return `${hours}`;
};

export const offsetTime = (updatedAt, t) => {
  dayjs.extend(relativeTime);
  function normalizeNumber(num) {
    return num < 0 ? num * -1 : num;
  }
  function toNormalString(num) {
    return num.toString().split(".")[0];
  }
  function showTime(dateVariable, dateTexts) {
    function num_word(words) {
      let num = +dateVariable % 10;
      if (+dateVariable > 10 && +dateVariable < 20) return words[2];
      if (num > 1 && num < 5) return words[1];
      if (num == 1) return words[0];
      return words[2];
    }

    return +dateVariable >= 1 ? `${dateVariable} ${num_word(dateTexts)}` : "";
  }

  let days = toNormalString(dayjs(updatedAt).diff(dayjs(), "hours") / 24);
  let hours = toNormalString(dayjs(updatedAt).diff(dayjs(), "hours") - +days * 24);
  let minutes = toNormalString(
    dayjs(updatedAt).diff(dayjs(), "minutes") - +days * 24 * 60 - +hours * 60
  );

  days = normalizeNumber(days);
  hours = normalizeNumber(hours);
  minutes = normalizeNumber(minutes);

  const dayString = showTime(days, [t("a_day"), t("day2"), t("days")]);
  const hoursString = showTime(hours, [t("an_hour"), t("hours2"), t("hours")]);
  const minutesString = showTime(minutes, [t("minute2"), t("minutes"), t("minute")]);

  if (days >= 3) {
    return `${dayString}`;
  }

  return `${dayString} ${hoursString} ${minutesString}`;
};

export const getTodayDate = (date) => {
  let now = dayjs().tz("Asia/Dubai");
  if (date === "today") return now.date(now.date()).format("DD.MM.YYYY");
  if (date === "yesterday") return now.date(now.date() - 1).format("DD.MM.2024");
};

export const getDateFrom = function (period, withTime) {
  let now = dayjs().tz("Asia/Dubai");
  // now = now.hour(now.hour()-1)

  switch (period) {
    case "All":
      return null;
    case "Month":
      return `${now.date(now.date() - 30).format("YYYY-MM-DD")}`;
    case "Week":
      return `${now.date(now.date() - 7).format("YYYY-MM-DD")}`;
    case "Day":
      return withTime
        ? `${now
            .date(now.date() - 1)
            .format("YYYY-MM-DDTHH:mm:ss.sssZ")
            .replace("+", "%2B")}`
        : `${now.date(now.date() - 1).format("YYYY-MM-DD")}`;
    case "Today":
      return `${now.date(now.date()).format("YYYY-MM-DDTHH:mm:ss.sssZ").replace("+", "%2B")}`;
  }
};
