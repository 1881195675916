import axios from "axios";
import { backendUrl } from "./api";
import queryParamseParser from "../helpers/queryParamseParser";
const instance = axios.create({
  baseURL: `${backendUrl}`
});
export const exhibitionsAPI = {
  getExhibitions(qParams = {}) {
    const reqString = queryParamseParser("/exhibitions", qParams);
    return instance.get(reqString);
  },
  findExhibitionByName(name = "") {
    if (name && name.length) {
      return instance.get(`/exhibitions/search?exhibitionName=${name}`);
    }
  },
  getExhibitionById(id) {
    if (id) {
      return instance.get(`/exhibitions/${id}`);
    }
  },
  createExhibition(exhibition) {
    return instance.post("/exhibitions", exhibition);
  },
  getDraft(companyId) {
    return instance.get(`/moderations/draft?companyId=${companyId}&entityType=exhibition`);
  }
};
