import { RetweetOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography } from "antd";
import { MaskedInput } from "antd-mask-input";
import { feedbackAPI } from "Api/api";
import mailImg from "Assets/image/mail.svg";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import { Footer } from "Components/footer/Footer";
import { Header } from "Components/header/Header";
import { checkboxRequiredValidation } from "Helpers/validation-rules";
import useOnlyLatinLetterInputHandlerAndModal from "Hooks/useOnlyLatinLetterInputHandlerAndModal";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha
} from "react-simple-captcha";
import { getCountries } from "Store/resourcesSlice";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";
import inputStyles from "Styles/autoSizeInput.module.less";
import s from "./ContactUs.module.less";

export const ContactUs = () => {
  const { t } = useTranslation([
    "FS4-Contact_us",
    "FS3-MatchMaking",
    "EmailConfirm",
    "FS0-Home",
    "Countries",
    "FA14-OrderDetailsModal"
  ]);
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const initialState = {
    countries: false
  };
  const [openState, setOpenState] = useState(initialState);
  const { countries } = useSelector((state) => state.resources);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [captcha, setCapthca] = useState("");

  useEffect(() => {
    if (!countries.length) {
      dispatch(getCountries());
    }
  }, [countries]);

  useEffect(() => {
    setDocumentTitle(t("header"));
  }, [t]);

  useEffect(() => {
    form.setFieldsValue({
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.company?.phone,
      countryId: user?.company?.countryId
    });
  }, [user]);

  useEffect(() => {
    if (!registerSuccess) {
      loadCaptchaEnginge(5, "#fff", "#000", "lower");
    }
  }, [registerSuccess]);

  const handlePhoneInput = (val) => {
    const regex = /^(\+)?(\d+)?/g;
    const match = val.match(regex);
    form.setFieldsValue({ phone: match[0] });
    form.setFields([{ name: "phone", errors: [] }]);
  };

  const onFinish = ({ phone, ...values }) => {
    const sendParams = { ...values, phone: phone.replace(/[()-]/g, "") };
    feedbackAPI
      .feedbackGeneral(sendParams)
      .then((res) => {
        form.resetFields();
        form.setFieldsValue({
          agreement: false
        });
        if (res.status === 201 && user === null) {
          setRegisterSuccess(true);
          validateCaptcha(0);
        } else if (user) {
          setModalMessage(t("responses.success", { ns: "FS0-Home" }));
          setModalIsVisible(true);
          validateCaptcha(0);
        }
      })
      .catch(() => {
        setModalMessage(t("response.error", { ns: "FS3-MatchMaking" }));
        setModalIsVisible(true);
      });
  };

  const onFinishFailed = (errors) => {
    let isCaptchaError = errors.errorFields.some((error) => error.name[0] === "captcha");
    if (isCaptchaError) {
      form.setFields([
        {
          name: "captcha",
          errors: [
            t("form.validation.captchaError", {
              ns: "FS0-Home"
            })
          ]
        }
      ]);
    }
  };

  const renderSelectOptions = useMemo(
    () =>
      countries.length &&
      countries.map((cat) => (
        <Select.Option key={cat.id} value={cat.id}>
          {" "}
          {t(cat.name, { ns: "Countries" })}
        </Select.Option>
      )),
    [countries, t]
  );

  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();
  return (
    <>
      {OnlyLatinLettersModal}
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onCancel={() => setModalIsVisible(false)}
          onOk={() => setModalIsVisible(false)}
          footer={[
            <Button
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                setModalIsVisible(false);
              }}
            >
              OK
            </Button>
          ]}
        >
          <p style={{ textAlign: "center" }}>{modalMessage}</p>
        </Modal>
      ) : null}
      <Header h1={t("header")} />
      <Row justify="center">
        {!registerSuccess ? (
          <Col xl={{ span: 16 }} md={{ span: 18 }} xxl={{ span: 16 }}>
            <div className="regular_block">
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "31.2px",
                  fontWeight: "500px"
                }}
              >
                {t("title")}
              </p>
              <p
                style={{
                  fontSize: "15px",
                  lineHeight: "24px",
                  fontWeight: "400px",
                  margin: "3px 0 10px 0"
                }}
              >
                {t("title_p")}
              </p>
              <Form
                colon={false}
                name="contactUs"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                labelAlign="left"
                labelWrap
                requiredMark={false}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
                validateTrigger="onSubmit"
                initialValues={{
                  email: user?.email,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  phone: user?.company?.phone,
                  countryId: user?.company?.countryId
                }}
              >
                <Row gutter={[32]}>
                  <Col span={8}>
                    <Form.Item
                      name="email"
                      style={{ display: "flex", flexDirection: "column" }}
                      label={
                        <div className={`${`label`} ${`req`}`}>
                          {t("email.label", { ns: "FS3-MatchMaking" })}
                        </div>
                      }
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: t("email.errors.required", {
                            ns: "FS3-MatchMaking"
                          })
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.trim().length === 0) {
                              return Promise.reject(
                                t("email.errors.required", {
                                  ns: "FS3-MatchMaking"
                                })
                              );
                            } else {
                              return Promise.resolve();
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        size="large"
                        onChange={(e) =>
                          OnlyLatinLettersTextInput(
                            e.target.value,
                            "email",
                            form,
                            true,
                            null,
                            null,
                            true
                          )
                        }
                        disabled={Boolean(user?.email)}
                      />
                    </Form.Item>
                    <Form.Item
                      name="firstName"
                      style={{ display: "flex", flexDirection: "column" }}
                      label={
                        <span className={`${`label`} ${`req`}`}>
                          {t("name.label", { ns: "FS3-MatchMaking" })}
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: t("name.errors.required", {
                            ns: "FS3-MatchMaking"
                          })
                        },
                        {
                          min: 2,
                          message: t("name.errors.min", {
                            ns: "FS3-MatchMaking"
                          })
                        },
                        {
                          max: 20,
                          message: t("name.errors.max", {
                            ns: "FS3-MatchMaking"
                          })
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.trim().length === 0) {
                              return Promise.reject(
                                t("fields.lastName.errors.required", {
                                  ns: "FS5-Registration"
                                })
                              );
                            } else {
                              return Promise.resolve();
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        size="large"
                        onChange={(e) =>
                          OnlyLatinLettersTextInput(
                            e.target.value,
                            "firstName",
                            form,
                            false,
                            null,
                            null,
                            true
                          )
                        }
                        disabled={Boolean(user?.firstName)}
                      />
                    </Form.Item>

                    <Form.Item
                      name="lastName"
                      style={{ display: "flex", flexDirection: "column" }}
                      label={
                        <p className={`${`label`} ${`req`}`}>
                          {t("name.lastName.label", { ns: "FS3-MatchMaking" })}
                        </p>
                      }
                      rules={[
                        {
                          required: true,
                          message: t("name.lastName.errors.required", {
                            ns: "FS3-MatchMaking"
                          })
                        },
                        {
                          min: 2,
                          message: t("name.lastName.errors.min", {
                            ns: "FS3-MatchMaking"
                          })
                        },
                        {
                          max: 25,
                          message: t("name.lastName.errors.max", {
                            ns: "FS3-MatchMaking"
                          })
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.trim().length === 0) {
                              return Promise.reject(
                                t("fields.lastName.errors.required", {
                                  ns: "FS5-Registration"
                                })
                              );
                            } else {
                              return Promise.resolve();
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        size="large"
                        onChange={(e) =>
                          OnlyLatinLettersTextInput(
                            e.target.value,
                            "lastName",
                            form,
                            false,
                            null,
                            null,
                            true
                          )
                        }
                        disabled={Boolean(user?.lastName)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      style={{ display: "flex", flexDirection: "column" }}
                      label={
                        <span className={`${`label`} ${`req`}`}>
                          {t("phoneNumber.label", { ns: "FS3-MatchMaking" })}
                        </span>
                      }
                      name="phone"
                      rules={[
                        { required: true, message: "" },
                        {
                          message: t("phoneNumber.errors.required", {
                            ns: "FS3-MatchMaking"
                          }),
                          validator: (_, value) => {
                            if (!value || value.indexOf("_") !== -1) {
                              return Promise.reject(
                                t("phoneNumber.errors.required", {
                                  ns: "FS3-MatchMaking"
                                })
                              );
                            } else {
                              return Promise.resolve();
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        onChange={(e) => handlePhoneInput(e.target.value)}
                        size="large"
                        maxLength={20}
                        style={{ height: "40.14px", backgroundColor: "#F5F7F8" }}
                        disabled={Boolean(user?.company?.phone)}
                      />
                    </Form.Item>

                    <Form.Item
                      name="topic"
                      style={{ display: "flex", flexDirection: "column" }}
                      label={<span className={`${`label`} ${`req`}`}>{t("theme.label")}</span>}
                      rules={[
                        {
                          required: true,
                          message: t("theme.errors.required")
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.trim().length === 0) {
                              return Promise.reject(t("fields.lastName.errors.required"));
                            } else {
                              return Promise.resolve();
                            }
                          }
                        }
                      ]}
                    >
                      <Input
                        size="large"
                        onChange={(e) =>
                          OnlyLatinLettersTextInput(
                            e.target.value,
                            "topic",
                            form,
                            false,
                            null,
                            null,
                            true
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ display: "flex", flexDirection: "column" }}
                      name="countryId"
                      label={
                        <span className={`${`label`} ${`req`}`}>
                          {t("country.label", { ns: "FS3-MatchMaking" })}
                        </span>
                      }
                      labelCol={{ span: 7 }}
                      wrapperCol={{ span: 17 }}
                      rules={[
                        {
                          required: true,
                          message: t("country.errors.required", {
                            ns: "FS3-MatchMaking"
                          })
                        }
                      ]}
                    >
                      <Select
                        style={{ width: "141.7%" }}
                        onDropdownVisibleChange={(state) => {
                          setOpenState({ ...initialState, countries: state });
                          form.setFields([{ name: "countryId", errors: [] }]);
                        }}
                        open={openState.countries}
                        placeholder={t("placeholder.country", { ns: "FS0-Home" })}
                        size="large"
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        suffixIcon={
                          <DropdownIcon
                            onClick={() => {
                              setOpenState((prevState) => ({
                                ...initialState,
                                countries: !prevState.countries
                              }));
                            }}
                          />
                        }
                        disabled={Boolean(user?.company?.countryId)}
                      >
                        {renderSelectOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <section
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        marginTop: "20px",
                        marginLeft: "35px"
                      }}
                    >
                      {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px"
                        }}
                      >
                        <img src={callImg} alt="img" />
                        <a
                          href="tel: +6 (567) 036 886"
                          style={{ fontWeight: 500, fontSize: "16px" }}
                        >
                          +6 (567) 036 886
                        </a>
                      </div> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px"
                        }}
                      >
                        <img src={mailImg} alt="img" />
                        <a
                          href="mailto:welcome@b2b-one.com"
                          style={{ fontWeight: 500, fontSize: "16px" }}
                        >
                          welcome@b2b-one.com
                        </a>
                      </div>
                    </section>
                  </Col>
                </Row>
                <Col span={16} style={{ maxWidth: "65.9%" }}>
                  <Form.Item
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 40 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "auto"
                    }}
                    name="message"
                    label={
                      <span className={`${`label`} ${`req`}`}>
                        {t("message.label", { ns: "FS3-MatchMaking" })}
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.messageError", {
                          ns: "FS0-Home"
                        })
                      }
                    ]}
                  >
                    <Input.TextArea
                      className={inputStyles.Textarea}
                      size="large"
                      maxLength={700}
                      showCount
                      placeholder={t("message.placeholder", {
                        ns: "FS3-MatchMaking"
                      })}
                      autoSize={true}
                      allowClear
                      onChange={(e) =>
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "message",
                          form,
                          true,
                          null,
                          null,
                          true
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Space align="start" size={18} style={{ marginBottom: "15px", display: "flex" }}>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={checkboxRequiredValidation}
                    onChange={() => {
                      form.setFields([{ name: "agreement", errors: [] }]);
                    }}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Typography.Paragraph
                    style={{
                      width: "580px",
                      fontWeight: 400,
                      textAlign: "left"
                    }}
                  >
                    {t("form.formInputs.I_agree", { ns: "FS0-Home" })}
                    <br />
                    <Link to="/terms-and-conditions" target="_blank" className={s.link}>
                      {t("form.formInputs.links.first", { ns: "FS0-Home" })}
                    </Link>{" "}
                    {t("and", { ns: "FA14-OrderDetailsModal" })}{" "}
                    <Link to="/privacy_policy" target="_blank" className={s.link}>
                      {t("form.formInputs.links.second", { ns: "FS0-Home" })}
                    </Link>
                    *
                  </Typography.Paragraph>
                </Space>

                <Space size="large" style={{ alignItems: "flex-start", marginRight: "309px" }}>
                  <Form.Item
                    style={{ marginBottom: 0, width: "154px" }}
                    name="captcha"
                    value={captcha}
                    onChange={(e) => {
                      form.setFields([{ name: "captcha", errors: [] }]);
                      setCapthca(e.target.value);
                    }}
                    rules={[
                      {
                        required: true,
                        message: ""
                      },
                      {
                        message:
                          ("form.validation.captchaError",
                          {
                            ns: "FS0-Home"
                          }),
                        validator: (_, value) => {
                          if (validateCaptcha(value) === false) {
                            form.setFieldsValue({ captcha: "" });
                            return Promise.reject(
                              t("form.validation.captchaError", {
                                ns: "FS0-Home"
                              })
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>

                  <div className={s.captcha}>
                    <LoadCanvasTemplateNoReload />
                  </div>

                  <RetweetOutlined
                    style={{ fontSize: 28, marginTop: "5px", cursor: "pointer" }}
                    onClick={() => {
                      validateCaptcha(0);
                    }}
                  />

                  <Form.Item noStyle>
                    <Button
                      className="btn btn_v6"
                      style={{
                        padding: "0 40px",
                        fontWeight: "300",
                        lineHeight: "16px",
                        fontSize: "14px",
                        color: "#fff",
                        width: "207px"
                      }}
                      type="default"
                      htmlType="submit"
                      size="large"
                    >
                      {t("form.buttons", { ns: "FS0-Home" })}
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </div>
          </Col>
        ) : (
          <Row justify="center">
            <Col>
              <div className={s.emailConfirm}>
                <h3>{t("emailconfirm", { ns: "EmailConfirm" })}</h3>
                <div className={s.instruction}>
                  <p style={{ margin: "15px 0 10px 0" }}>
                    {t("emailSend", { ns: "EmailConfirm" })}
                  </p>
                  <p style={{ marginBottom: "5px" }}>{t("instruction", { ns: "EmailConfirm" })}</p>
                  <ul>
                    <li>{t("ul.li1", { ns: "EmailConfirm" })}</li>
                    <li>{t("ul.li2", { ns: "EmailConfirm" })}</li>
                    <li>{t("ul.li3", { ns: "EmailConfirm" })}</li>
                  </ul>
                </div>
                <div className={s.support}>
                  <p>{t("support.team", { ns: "EmailConfirm" })}</p>
                  <a style={{ color: "#1890ff" }} className="link" href="mailto:support@b2b.com">
                    {t("support.email", { ns: "EmailConfirm" })}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Row>
      <Footer />
    </>
  );
};
