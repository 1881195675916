/* eslint-disable react/prop-types */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Input, Select, Space } from "antd";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";

const { Option } = Select;

const SearchSelect = (props) => {
  const { t } = useTranslation("Search");
  const [openState, setOpenState] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  return (
    <Select
      showArrow
      style={{ height: 32 }}
      getPopupContainer={() => (props?.node ? props.node.current : document.getElementById("root"))}
      suffixIcon={
        <DropdownIcon
          onClick={() => {
            setOpenState((prevState) => !prevState);
          }}
        />
      }
      maxTagCount="responsive"
      maxTagTextLength={4}
      onDropdownVisibleChange={(state) => {
        setSearchValue("");
        setOpenState(state);
      }}
      open={openState}
      optionFilterProp="label"
      filterOption={(input, option) => option.label.toLowerCase().startsWith(input.toLowerCase())}
      showSearch={false}
      searchValue={searchValue}
      placeholder={t("all")}
      dropdownRender={(menu) => (
        <>
          <Space align="center" style={{ padding: "0 8px 4px" }}>
            <Input
              placeholder={t("search")}
              value={searchValue}
              allowClear
              onChange={(e) => {
                if (typeof props.customFunc !== "undefined") {
                  props.customFunc(e.target.value);
                  setSearchValue(e.target.value);
                } else {
                  setSearchValue(e.target.value);
                }
              }}
            />
          </Space>
          <Divider style={{ margin: "8px 0" }} />
          {menu}
        </>
      )}
      {...props}
    >
      {typeof props.customFunc !== "undefined" ? (
        <>
          {props.options.map((item) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </>
      ) : (
        ""
      )}
    </Select>
  );
};

export default SearchSelect;
