import Facebook from 'Assets/svg/Facebook';
import LinkedIn from 'Assets/svg/LinkedIn';
import NotVerified from 'Assets/svg/NotVerified';
import PremiumMember from 'Assets/svg/PremiumMember';
import Professional from 'Assets/svg/Professional';
import React, {useState} from 'react';
import ReactCountryFlag from 'react-country-flag';
import Twitter from 'Assets/svg/Twitter';
import Verified from 'Assets/svg/Verified';
import s from './CompanyHat.module.less';
import {useDispatch, useSelector} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import {CloseOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Button, Modal, Tooltip} from 'antd';
import style from '../../FK2.1-productPage/ProductPage.module.less';
import {mediaUrl, profileAPI} from '../../../api/api';
import {TooltipWithLink} from '../../../components/TooltipWithLink/TooltipWithLink';
import {tariffDescriptionJSONtoHTML} from '../../../helpers/tariffDescriptionJSONtoHTML';
import {me} from '../../../store/authSlice';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import Message from '../../../assets/svg/Message';
import Camera from '../../../assets/svg/Camera';
import QuestionCircleIcon from '../../../assets/svg/QuestionCircleIcon';
/* eslint-disable react/prop-types */
const CompanyHat = ({companyId, profile, url}) => {
  const {t} = useTranslation([
    'FK3-сompanyProfile',
    'FS17-SubscriptionManagement',
    'tariffDescription',
    'Countries',
  ]);

  const {lang} = useSelector((state) => state.auth);
  const {countries} = useSelector((state) => state.countries);
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);

  const data = companyId ? companyId : profile.profile;

  const countryCode = (() => {
    if (companyId) {
      return companyId.country.name;
    } else if (profile) {
      return profile.profile.country.name;
    } else {
      return 'RU';
    }
  })();


  const {user, isAdmin} = useSelector((state) => state.auth);
  const [openIsModal, setOpenIsModal] = useState(null);

  const navigate = useNavigate();

  const nav = () => {
    if (openIsModal === 1) {
      return 'online-meeting';
    } else if (openIsModal === 2) {
      return 'online-meeting-moderation';
    } else if (openIsModal === 3) {
      return `/company-profile/${companyId.id}#form`;
    }
  };
  const disabledNavLink = (e, num, link) => {
    if (profile) {
      return;
    } else if (!isAdmin && user?.company?.type === 2 && url !== user?.company?.id) {
      setOpenIsModal(num);
    } else if (!isAdmin && url === user?.company?.id) {
      return false;
    } else {
      navigate(user ? link : '/login', {state: {moveOn: `/company-profile/${companyId.id}/${link}`, isVisibledExhibitionBlock: num === 1 ? true : false}});
    }
  };


  return (
    <div className={s.wrapper}>

      <Modal
        className={s.modal}
        visible={openIsModal}
        onOk={() => setOpenIsModal(null)}
        onCancel={() => setOpenIsModal(null)}
        footer={null}
      >
        <p>{t('modalTitle')}</p>
        <div className={s.btnWrapper}>
          <Button
            key="ok"
            className="btn btn_v2"
            style={{marginRight: '10px'}}
            onClick={() => {
              profileAPI
                .enableProfile(1).then(() => {
                dispatch(me());
                navigate(nav());
              })
                .catch((e) => {
                  console.log(e.response);
                }).finally(() => setOpenIsModal(null));
            }}
          >
            {t(openIsModal === 3 ? 'btn3' : 'btn1')}
          </Button>
          <Button
            key="cancel"
            className="btn btn_v3"
            onClick={() => {
              setOpenIsModal(null);
            }}
          >
            {t('btn2')}
          </Button></div>
      </Modal>


      <Modal
        style={{justifyContent: 'center'}}
        className={`${style.modal} ${s.modal}`}
        visible={logo}
        onOk={() => setLogo(null)}
        onCancel={() => setLogo(null)}
        footer={null}
        closeIcon={<CloseOutlined style={{fontSize: '30px', color: '#fff'}}/>}
      >
        <img className={style.imgModal} src={`${mediaUrl}${logo}`} alt=""/>
      </Modal>
      <div className={s.img}>
        {(() => {
          if (companyId) {
            return (
              <img
                onClick={() => setLogo(companyId?.logo)}
                src={`${mediaUrl}${companyId?.logo}`}
                className={s.img}
                alt={'logo'}
              />
            );
          } else if (profile?.logo) {
            return (
              <img
                onClick={() => setLogo(profile?.logo)}
                src={`${mediaUrl}${profile?.logo}`}
                className={s.img}
                alt={'logo'}
              />
            );
          } else if (profile?.profile?.logo) {
            return (
              <img
                onClick={() => setLogo(profile?.profile?.logo)}
                src={`${mediaUrl}${profile?.profile?.logo}`}
                className={s.img}
                alt={'logo'}
              />
            );
          }
        })()}
      </div>
      <div className={s.description}>
        <div style={{width: '50%'}}>
          <div className={s.name}>
            {companyId ? companyId.companyName : profile?.profile.companyName}
          </div>
          <div className={s.txt}>
            {companyId ? (companyId.city && companyId.city + ',') : (profile?.profile.city && profile?.profile.city + ',')}{' '}
            {t(countryCode, {ns: 'Countries'})}
            {Object.keys(countries).find((item) => item === countryCode) ? (
              <div>
                <img src={countries[countryCode]} alt="" className="country_flag"/>
              </div>
            ) : (
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{width: '26px', height: '18px', borderRadius: '5px', marginLeft: '10px'}}
              />
            )}{' '}
          </div>
          {companyId && companyId?.tariff?.id !== 1 ? (
            <div className={s.txt}>
              {(() => {
                const type = companyId && companyId?.tariff?.id;
                if (type === 2) {
                  return (
                    <Tooltip
                      overlayStyle={{maxWidth: '700px'}}
                      placement="bottomLeft"
                      title={tariffDescriptionJSONtoHTML('professional', t)}
                    >
                      <Professional style={{marginRight: '10px'}}/>
                      {companyId.tariff.name[lang]}
                    </Tooltip>
                  );
                } else if (type === 3) {
                  return (
                    <Tooltip
                      overlayStyle={{maxWidth: '700px'}}
                      placement="bottomLeft"
                      title={tariffDescriptionJSONtoHTML('professionalPlus', t)}
                    >
                      <Professional style={{marginRight: '10px'}}/>
                      {companyId.tariff.name[lang]}
                    </Tooltip>
                  );
                } else if (type === 4) {
                  return (
                    <Tooltip
                      overlayStyle={{maxWidth: '700px'}}
                      placement="bottomLeft"
                      title={tariffDescriptionJSONtoHTML('premium', t)}
                    >
                      <PremiumMember style={{marginRight: '10px'}}/>
                      {companyId.tariff.name[lang]}
                    </Tooltip>
                  );
                }
              })()}
            </div>
          ) : null}
          {(() => {
            if (companyId && companyId.isVerified) {
              return (
                <div className={s.txt}>
                  <Verified style={{marginRight: '10px'}}/>
                  <Tooltip
                    overlayStyle={{maxWidth: '700px'}}
                    placement="bottomLeft"
                    title={<TooltipWithLink isVerified={true}/>}
                  >
                    {t('isVerified')}
                  </Tooltip>
                </div>
              );
            } else if (companyId && !companyId.isVerified && companyId.isVerified !== null) {
              return (
                <div className={s.txt}>
                  <NotVerified style={{marginRight: '10px'}}/>
                  <Tooltip
                    overlayStyle={{maxWidth: '700px'}}
                    placement="bottomLeft"
                    title={<TooltipWithLink isVerified={false}/>}
                  >
                    {t('isNotVerified')}
                  </Tooltip>
                </div>
              );
            }
          })()}

          <div className={s.tegs}>
            {companyId &&
            companyId.companyKeywords.map(
              (item, index) =>
                item.keyword && (
                  <div key={index} className={s.teg}>
                    {item.keyword.replace(/[\s,%]/g, ' ')}
                  </div>
                ),
            )}
            {profile?.profile.companyKeywords?.length ? (
              typeof profile.profile.companyKeywords === 'object' &&
              profile.profile.companyKeywords !== null &&
              profile.profile.companyKeywords.length ? (
                profile.profile.companyKeywords.map((item, index) => (
                  <div key={index} className={s.teg}>
                    {item.keyword} {index + 1 === profile.profile.companyKeywords?.length ? '' : ''}
                  </div>
                ))
              ) : (
                profile.profile.companyKeywords.split(', ').map((item, index) => (
                  <div key={index} className={s.teg}>
                    {item.replace(/[\s,%]/g, ' ')}
                  </div>
                ))
              )
            ) : (
              <div/>
            )}
          </div>
        </div>


        <div className={s.buttonWrapper}>
          {!isAdmin && url !== user?.company.id ?
            <> <NavLink
              onClick={(e) => {
                if (profile) {
                  e.preventDefault();
                } else if (!isAdmin && user?.company.type === 2) {
                  e.preventDefault();
                  setOpenIsModal(3);
                }
              }}
              className={`btn btn_v2 ${s.btnStyle}`}
              to={`/company-profile/${url}#formTitle`}
            >
              <Message style={{marginRight: '12px'}}/>
              {t('link3')}
            </NavLink>
              <div className={`btn btn_v8 ${s.btnStyle} ${s.hov}`}
                   onClick={(e) => disabledNavLink(e, 1, 'online-meeting')}>
                <Camera style={{marginRight: '12px'}} className={s.svg}/>{t('link4')}</div>
              <div className={`btn btn_v8 ${s.btnStyle} ${s.hov}`}
                   onClick={(e) => disabledNavLink(e, 2, 'online-meeting-moderation')}>
                <Camera style={{marginRight: '12px'}} className={s.svg}/>
                <Trans>
                  <div style={{margin: '0 20px'}}>
                    {t('link5')}
                  </div>
                </Trans>
                <div>
                  <Tooltip placement="bottomLeft" title={t('tooltip')}
                           overlayStyle={{whiteSpace: 'pre-line', maxWidth: '500px'}}>
                    <QuestionCircleIcon/>
                  </Tooltip>
                </div>
              </div>
            </>
            : null}
          <div className={s.socialWrapper}>
            {data?.facebook && (
              <a target={'_blank'} className={s.social} href={data.facebook} rel="noreferrer">
                <Facebook/>
              </a>
            )}
            {data?.linkedin && (
              <a target={'_blank'} className={s.social} href={data.linkedin} rel="noreferrer">
                <LinkedIn/>
              </a>
            )}
            {data?.twitter && (
              <a target={'_blank'} className={s.social} href={data.twitter} rel="noreferrer">
                <Twitter/>
              </a>
            )}
            {data?.youtube && (
              <a target={'_blank'} className={s.social} href={data.youtube} rel="noreferrer">
                <img src={'/images/Youtube.png'}/>
              </a>
            )}
            {data?.instagram && (
              <a target={'_blank'} className={s.social} href={data.instagram} rel="noreferrer">
                <img src={'/images/instagram.png'}/>
              </a>
            )}
          </div>

        </div>


      </div>
    </div>
  );
};

export default CompanyHat;
