import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";
import styles from "./archive-exhibition.module.less";
import ExhibitionTable from "../../components/ExhibitionTable/ExhibitionTable";
import { useSelector } from "react-redux";
import { fetchMyExhibitions } from "../../store/exhibitionSlice";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { exhibitionAPI } from "../../api/api";

import ExhibitionPreview from "../../components/ExhibitionPreview/ExhibitionPreview";

const ArchiveExhibition = () => {
  const { t } = useTranslation(["FS102-ArchiveExhibition", "utils"]);
  const { setTitle } = useOutletContext();
  const [modalType, setModalType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [standId, setStandId] = useState(null);
  const [exhibitionForDelete, setExhibitionForDelete] = useState({});

  const { myExhibitions } = useSelector((state) => state.exhibitions);

  useEffect(() => {
    setTitle(t("h1"));
  }, [t]);

  useEffect(() => {
    fetchExhibitionData();
    window.scrollTo(0, 0);
  }, [currentPage, user, t]);

  const fetchExhibitionData = () => {
    let params = {
      limit: 10,
      page: currentPage,
      involvedCompanyId: user?.id,
      stands: true,
      moderation: true,
      isArchive: true
    };
    dispatch(fetchMyExhibitions(params));
  };

  useEffect(() => {
    let counter = Number(`${currentPage === 1 ? 1 : currentPage - 1}0`);
    if (currentPage === 1) {
      setTableData(myExhibitions.items.map((item, i) => ({ ...item, num: i + 1 })));
    } else {
      setTableData(
        myExhibitions.items.map((item, i) => ({ ...item, num: `${counter + (i + 1)}` }))
      );
    }
  }, [myExhibitions, currentPage]);

  const handleOpenModal = (modalType) => setModalType(modalType);
  const handleCloseModal = () => setModalType("");
  const handleDeleteArchive = async (id) => {
    try {
      await exhibitionAPI.deleteExhibitionStand(id);
      handleOpenModal("DELETE_SUCCESS");
      setStandId(null);
      fetchExhibitionData();
    } catch (error) {
      handleOpenModal("UNKNOWN_ERROR");
      setStandId(null);
    }
  };

  return (
    <div className={styles.regular_block}>
      <div className={styles.head} style={{ maxWidth: '1743px'}}>
        <h3 className={styles.title}>{t("pageTitle")}</h3>
        {myExhibitions.items.length ? (
          <Link to={"/account/my-exhibitions"}>
            <Button className="btn btn_v3">{t("goBack")}</Button>
          </Link>
        ) : (
          ""
        )}
      </div>
      {myExhibitions.items.length ? (
        <ExhibitionTable
          handleOpenModal={handleOpenModal}
          tableData={tableData}
          current={currentPage}
          pageSize={10}
          onChange={setCurrentPage}
          total={myExhibitions.total}
          isArchive={true}
          setStandInfo={setStandId}
          setExhibitionForDelete={setExhibitionForDelete}
        />
      ) : (
        <div className={styles.exhibitionsLengthInfo}>{t("no_events_in_the_archive")}</div>
      )}
      <div className={styles.bottom}>
        <Link to={"/account/my-exhibitions"}>
          <Button className="btn btn_v3">{t("goBack")}</Button>
        </Link>
        <p>
          {t("isError")}{" "}
          <Link className="orange" to={"/account/make-complaint"} state={{ isExhibition: true }}>
            {t("contact_us")}
          </Link>
        </p>
      </div>
      <Modal
        className={styles.modalWrapper}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        visible={modalType}
        footer={null}
      >
        {modalType === "DELETE_SUCCESS" && <p style={{ marginTop: 0 }}>{t("deleted")}</p>}
        {modalType === "UNKNOWN_ERROR" && (
          <p style={{ marginTop: 0 }}>{t("error", { ns: "utils" })}</p>
        )}
        {modalType.includes("DELETE_MODE") && (
          <div className={styles.modal}>
            <div>
              <h3 className={styles.modal_title}>{t("dearUser")}</h3>
              <p className={styles.modal_desc} style={{ marginBottom: 10 }}>
                {t("wantToDelete")}&nbsp;«{modalType.split("_").at(-1)}»?
              </p>
              <ExhibitionPreview exhibition={exhibitionForDelete} isModal />
              <br />
              <p className={styles.modal_desc}>
                <span className="orange">{t("attention")}</span> {t("attention1")}
              </p>
            </div>
            <div className={styles.buttons}>
              <Button
                className={`btn btn_v8 ${styles.buttons_first}`}
                onClick={() => handleCloseModal()}
              >
                {t("goBackArchive")}
              </Button>
              <Button
                className={`btn btn_v2`}
                onClick={() => {
                  handleCloseModal();
                  handleDeleteArchive(standId);
                }}
              >
                {t("delete")}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ArchiveExhibition;
