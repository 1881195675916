import { Button, Col, Form, Modal, Radio, Row } from "antd";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import {
  fetchCompanyCategories,
  fetchServiceCategoriesByCompany,
  setCompanyCategories,
  setSearchingProductType,
  setServiceCategories
} from "../../store/catalogSlice";
import { useDispatch, useSelector } from "react-redux";

import CompanyHat from "./CompanyHat/CompanyHat";
import Facebook from "../../assets/svg/Facebook";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import LinkedIn from "../../assets/svg/LinkedIn";
import Nav from "./Nav/Nav";
import OfferForm from "./OfferForm/OfferForm";
import Search from "../FK4-SearchProducts/Search/Search";
import Twitter from "../../assets/svg/Twitter";
import WhatsApp from "../../assets/svg/WhatsApp";
import { authMe } from "../../store/authSlice";
import { fetchBusinessType } from "../../store/resourcesSlice";
import { fetchCompanyId, setCompanyId, setCompanyInit, setSave } from "../../store/companySlice";
import s from "./CompanyProfile.module.less";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";
import styleForm from "./About/About.module.less";
import styleReg from "../FS3-matchMaking/matchMaking.module.less";
import styles from "../FK4-SearchProducts/search-products.module.less";
import { useTranslation } from "react-i18next";

const CompanyProfile = () => {
  const [form] = Form.useForm();
  const currentUrl = window.location.href;
  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state) => state.auth);
  const { companyId } = useSelector((state) => state.company);
  const [successfully, setSuccessfully] = useState(false);
  const [leftOrRight, setLeftOrRight] = useState(true);
  const [userIsBlocked, setUserIsBlocked] = useState(false);
  const [visibleBtn, setVisibleBtn] = useState(false);
  const { t } = useTranslation([
    "FK3-сompanyProfile",
    "FK4-SearchProducts",
    "FS5-Registration",
    "Countries"
  ]);
  const link = useLocation();
  const navigate = useNavigate();
  const { searchingProductType } = useSelector((state) => state.catalog);
  const { pathname } = useLocation();
  const { region, city, street, houseNumber, country } = companyId;

  useEffect(() => {
    setTimeout(() => {
      if (link.hash === "") {
        if (leftOrRight) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 500);
        }
      }
    }, 10);
  }, [pathname, leftOrRight]);

  useEffect(() => {
    setDocumentTitle(t("mainTitle"));
  }, [t]);

  const productTypeChange = (e) => {
    dispatch(setSearchingProductType(e.target.value));
    dispatch(authMe());
  };
  const { id } = useParams();

  useEffect(() => {
    if (link.hash !== "") {
      setTimeout(() => {
        const formId = link.hash.replace("#", "");
        const element = document.getElementById(formId);
        if (element && id) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [link, companyId]);

  let url;
  useEffect(() => {
    if (link.pathname.includes("product")) {
      url = link.pathname.split("company-profile/")[1].split("/product")[0];
      setLeftOrRight(false);
    } else if (link.pathname.includes("/product-categories")) {
      url = link.pathname.split("company-profile/")[1].split("/product-categories")[0];
      setLeftOrRight(false);
    } else if (link.pathname.includes("service-categories")) {
      url = link.pathname.split("company-profile/")[1].split("/service-categories")[0];
      setLeftOrRight(false);
    } else if (link.pathname.includes("service")) {
      url = link.pathname.split("company-profile/")[1].split("/service")[0];
      setLeftOrRight(false);
    } else {
      url = link.pathname.split("company-profile/")[1] + link.search;
      setLeftOrRight(true);
    }
    dispatch(fetchCompanyId(url, leftOrRight));
    dispatch(fetchBusinessType());
  }, [dispatch, link, successfully]);

  useEffect(() => {
    if (companyId.id && !link.search.includes("moderationId")) {
      if (link.pathname.includes("service")) {
        dispatch(fetchServiceCategoriesByCompany(companyId.id));
      } else if (link.pathname.includes("product")) {
        dispatch(fetchCompanyCategories(companyId.id));
      }
    }
  }, [companyId.id, dispatch, link]);

  useEffect(() => {
    return () => {
      dispatch(setCompanyCategories([]));
      dispatch(setServiceCategories([]));
      dispatch(setCompanyInit());
    };
  }, []);

  const goToMain = () => {
    navigate("/");
  };
  useEffect(() => {
    if (companyId.user?.adminBlockedAt !== null || companyId.user?.selfBlockedAt !== null) {
      setUserIsBlocked(true);
    }
  }, [companyId]);

  const renderAddressInfo = useMemo(
    () => (
      <div className={styleForm.address}>
        {country && `${t(country.name, { ns: "Countries" })},`} {region && `${region},`}{" "}
        {city && `${city},`} {street && `${street},`} {houseNumber && `${houseNumber},`}{" "}
        {companyId.address2 || companyId.address3
          ? `${companyId.address2}, ${companyId.address3}`
          : ""}
      </div>
    ),
    [companyId, successfully]
  );

  return (
    <>
      <Header h1={t("mainTitle")} />
      <div onClick={() => setVisibleBtn(false)}>
        <Modal
          visible={userIsBlocked}
          onOk={goToMain}
          onCancel={goToMain}
          footer={[
            <Button key="ok" className="btn btn_v2" onClick={goToMain}>
              ОК
            </Button>
          ]}
        >
          <p>{t("deactivate")}</p>
        </Modal>
        {userIsBlocked ? null : successfully ? (
          <Row align="center" style={{ position: "relative" }}>
            <Col style={{ display: "flex", background: "#fff", width: "1020px" }}>
              <div className={styleReg.emailConfirm} style={{ marginBottom: "0" }}>
                <h3>{t("emailConfirmationScreen.title", { ns: "FS5-Registration" })}</h3>
                <div className={styleReg.instruction} style={{ width: "100%" }}>
                  <p style={{ margin: "15px 0 10px 0" }}>
                    {t("emailConfirmationScreen.p1", { ns: "FS5-Registration" })}
                  </p>
                  <p style={{ marginBottom: "5px" }}>
                    {t("emailConfirmationScreen.p2", { ns: "FS5-Registration" })}
                  </p>
                  <ul>
                    <li>{t("emailConfirmationScreen.li1", { ns: "FS5-Registration" })}</li>
                    <li>{t("emailConfirmationScreen.li2", { ns: "FS5-Registration" })}</li>
                    <li>{t("emailConfirmationScreen.li3", { ns: "FS5-Registration" })}</li>
                  </ul>
                </div>
                <div className={styleReg.support}>
                  <p>{t("emailConfirmationScreen.p3", { ns: "FS5-Registration" })}</p>
                  <a
                    style={{ color: "#1890ff" }}
                    className="link"
                    href="mailto:support@b2b-one.com"
                  >
                    support@b2b-one.com
                  </a>
                </div>
              </div>
              <div
                className={`${styleForm.rightWrapper} ${s.bef}`}
                style={{ minWidth: "303px", width: 0 }}
              >
                {renderAddressInfo}
                <div className={styleForm.social}>
                  {t("socialTitle")}
                  <div className={styleForm.iconWrapper}>
                    <a
                      target={"_blank"}
                      href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <Facebook />
                    </a>
                    <a
                      target={"_blank"}
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <LinkedIn />
                    </a>
                    <a
                      target={"_blank"}
                      href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <Twitter />
                    </a>
                    <a
                      target={"_blank"}
                      data-action="share/whatsapp/share"
                      href={`https://api.whatsapp.com/send?text=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <WhatsApp />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <div className={s.container}>
            <div onFocus={() => setVisibleBtn(true)} onClick={(e) => e.stopPropagation()}>
              <Search />
              <Radio.Group
                style={{ display: "flex", justifyContent: "center" }}
                className={visibleBtn ? `${s.visible}` : s.invisible}
                onChange={productTypeChange}
                value={searchingProductType}
              >
                <Radio value={0}>{t("productTypes.products", { ns: "FK4-SearchProducts" })}</Radio>
                <Radio value={1}>{t("productTypes.services", { ns: "FK4-SearchProducts" })}</Radio>
                <Radio value={2}>{t("productTypes.companyes", { ns: "FK4-SearchProducts" })}</Radio>
              </Radio.Group>
            </div>

            <CompanyHat companyId={companyId} t={t} url={companyId.id} />
            <Nav t={t} style={{ margin: "0 32px" }} companyId={companyId} />
            <div style={{ width: "100%", padding: "32px 32px 0 32px" }}>
              <Outlet context={[form]} />
            </div>
            <div
              className={(() => {
                if (
                  !isAdmin &&
                  (user?.company?.type !== 0 || user?.company?.type !== 1) &&
                  user?.company?.id !== companyId?.id
                ) {
                  return `${styleForm.formWrapper} ${styleForm.additional} ${styleForm.section}`;
                } else if (user?.company?.id === companyId?.id) {
                  return `${styleForm.formWrapper} ${styleForm.additional1} ${styleForm.section}`;
                } else {
                  return `${styleForm.formWrapper} ${styleForm.section}`;
                }
              })()}
              id={"form"}
              style={
                leftOrRight && !isAdmin && (user?.company?.type !== 0 || user?.company?.type !== 1)
                  ? {}
                  : { marginLeft: "376px" }
              }
            >
              {isAdmin ||
              user?.company.type === 2 ||
              user?.company.type === 4 ||
              user?.company.id === companyId.id ? null : (
                <div
                  id={"formTitle"}
                  className={styleForm.leftWrapper}
                  style={{ ...(leftOrRight ? {} : { width: "70%" }), scrollMarginTop: "155px" }}
                >
                  <div className={styleForm.title} style={{ marginBottom: "8px" }}>
                    {t("form.title")}
                  </div>
                  <div>{t("form.subTitle")}</div>
                  <div>{t("form.helpTitle")}</div>
                  <OfferForm form={form} id={companyId?.id} setSuccessfully={setSuccessfully} />
                </div>
              )}
              <div
                className={(() => {
                  if (!isAdmin) {
                    if (user?.company.type !== 0 || user?.company.type !== 1) {
                      return `${styleForm.additional}`;
                    } else if (!leftOrRight) {
                      return `${styleForm.rightWrapper} ${s.befRight}`;
                    } else if (leftOrRight) {
                      return styleForm.rightWrapper;
                    }
                  }
                })()}
                style={leftOrRight ? {} : { width: "30%" }}
              >
                {renderAddressInfo}
                <div className={styleForm.social}>
                  {t("socialTitle")}
                  <div className={styleForm.iconWrapper}>
                    <a
                      target={"_blank"}
                      href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <Facebook />
                    </a>
                    <a
                      target={"_blank"}
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <LinkedIn />
                    </a>
                    <a
                      target={"_blank"}
                      href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <Twitter />
                    </a>
                    <a
                      target={"_blank"}
                      data-action="share/whatsapp/share"
                      href={`https://api.whatsapp.com/send?text=${currentUrl}`}
                      rel="noreferrer"
                    >
                      <WhatsApp />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CompanyProfile;
