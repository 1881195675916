import { Button, Checkbox, Form, Input, Modal, Select, Space, Table, Typography } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";

import Close from "Assets/svg/Close";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import { Footer } from "Components/footer/Footer";
import { Header } from "Components/header/Header";
import Loader from "../../components/Loader/Loader";
import dayjs from "dayjs";
import { backendUrl, companyAPI, meetingAPI, resourcesAPI } from "../../api/api";
import styles from "../FK2-MeetingWithSeller/meeting.module.less";
import timezone from "dayjs/plugin/timezone";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import utc from "dayjs/plugin/utc";
import ReactCountryFlag from "react-country-flag";
import BlockWithExhibitions from "./BlockWithExhibitions/BlockWithExhibitions";
import style from "./BlockWithExhibitions/BlockWithExhibitions.module.less";
import { MaskedInput } from "antd-mask-input";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha
} from "react-simple-captcha";
import { RetweetOutlined } from "@ant-design/icons";
import useOnlyLatinLetterInputHandlerAndModal from "../../hooks/useOnlyLatinLetterInputHandlerAndModal";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(utc);

const { Title, Paragraph } = Typography;

const initialState = {
  language: false
};

function MeetingAtTheExhibition() {
  const { user, lang } = useSelector((state) => state.auth);
  const { exhibition } = useSelector((state) => state.company);
  const navigate = useNavigate();
  const { t } = useTranslation("FK2-Meeting");
  const countrytrans = useTranslation("Countries");
  let { id } = useParams();
  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();

  const [form] = Form.useForm();

  const location = useLocation();

  const [currentCompany, setCurrentCompany] = useState({
    companyName: "",
    country: {
      name: ""
    },
    annualTurnover: {
      name: ""
    },
    website: "",
    companyKeywords: [""],
    numberEmployees: "",
    countryId: ""
  });

  const [companyMeetingsTime, setCompanyMeetingsTime] = useState([]);
  const [currentCompanyCityTime, setCurrentCompanyCityTime] = useState();
  const [currentMyCityTime, setCurrentMyCityTime] = useState();
  const [choosedDate, setChoosedDate] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [openState, setOpenState] = useState(initialState);
  const [successModalShow, setSuccessModalShow] = useState(false);

  const [requestLoading, setRequestLoading] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [langs, setLangs] = useState([]);
  const { countries } = useSelector((state) => state.countries);
  const { search } = useLocation();
  const exhibitionItem = useMemo(
    () => exhibition?.find((f) => f.id === Number(search.slice(4)))?.times,
    [exhibition, search]
  );
  const standsId = useMemo(
    () => exhibition?.find((f) => f.id === Number(search.slice(4)))?.stands[0]?.id,
    [exhibition, search]
  );
  const currentExhibition = useMemo(
    () => exhibition?.find((f) => f.id === Number(search.slice(4))),
    [exhibition, search]
  );

  const reloadCaptcha = () => {
    loadCaptchaEnginge(5, "#fff", "#000", "lower");
  };

  useEffect(() => {
    if (modalVisible) {
      loadCaptchaEnginge(5, "#fff", "#000", "lower");
    }
  }, [modalVisible]);

  useEffect(() => {
    if (location.hash !== "") {
      setTimeout(() => {
        const formId = location.hash.replace("#", "");
        const element = document.getElementById(formId);
        if (element && id) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [location, exhibitionItem]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentCompany.companyName) {
        const d = new Date();
        d.setMinutes(
          d.getMinutes() +
            currentCompany?.user?.tz.timeZone.split("UTC")[1].split(":")[0] * 60 +
            Number(currentCompany?.user?.tz.timeZone.split(":")[1]) +
            d.getTimezoneOffset()
        );

        const myD = new Date();
        myD.setMinutes(
          myD.getMinutes() +
            user?.tz.timeZone.split("UTC")[1].split(":")[0] * 60 +
            Number(user?.tz.timeZone.split(":")[1]) +
            myD.getTimezoneOffset()
        );

        const hours = d.getHours();
        const minutes = d.getMinutes();

        const myHours = myD.getHours();
        const myMinutes = myD.getMinutes();

        setCurrentCompanyCityTime(
          `${hours}:${`${minutes}`.length < 2 ? "0" + `${minutes}` : minutes}`
        );
        setCurrentMyCityTime(
          `${myHours}:${`${myMinutes}`.length < 2 ? "0" + `${myMinutes}` : myMinutes}`
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [currentCompany]);

  useEffect(() => {
    if (user && id) {
      companyAPI.getCompanyId(id).then((res) => {
        setCurrentCompany(res.data);
      });
      if (user.id) {
        Promise.all([
          meetingAPI.getCompanyMeetingsExhibition(id, standsId),
          meetingAPI.getCompanyMeetingsExhibition(user.company.id, standsId)
        ]).then((res) => {
          setCompanyMeetingsTime([
            ...new Set([
              ...res[0].data.map((m) => m.dateFrom),
              ...res[1].data.map((m) => m.dateFrom)
            ])
          ]);
        });
      }
      form.setFieldsValue({ ...form, languageId: langs[1]?.value });
    }
  }, [id, user, langs, standsId]);

  useEffect(() => {
    meetingAPI
      .getCompanyMeetingsExhibition(id, 5)
      .then((res) => {})
      .catch((e) => {
        console.log({ e });
      });
  }, []);

  useEffect(() => {
    if (location.state) {
      const {
        state: { product, productAmount }
      } = location;
      form.setFieldsValue({ item: product, itemAmount: productAmount });
    }
  }, []);

  useEffect(() => {
    resourcesAPI.getLocalesLanguages().then((res) =>
      setLangs(
        res.data.map((currLang) => ({
          value: currLang.id,
          label: currLang.name[lang]
        }))
      )
    );
  }, [lang]);

  useEffect(() => {
    document.title = t("h1");
  }, [t]);

  const formatDate = (d, uppercase = false) => {
    const day = d.getDay();
    const dayTitle = [
      t("weekDays.Sun"),
      t("weekDays.Mon"),
      t("weekDays.Tue"),
      t("weekDays.Wed"),
      t("weekDays.Thu"),
      t("weekDays.Fri"),
      t("weekDays.Sat")
    ];
    const month = d.getMonth();
    const monthTitle = [
      t("months.jan"),
      t("months.feb"),
      t("months.mar"),
      t("months.apr"),
      t("months.may"),
      t("months.jun"),
      t("months.jul"),
      t("months.aug"),
      t("months.sep"),
      t("months.oct"),
      t("months.nov"),
      t("months.dec")
    ];

    const date = d.getDate().toString().padStart(2, "0");
    if (d < new Date()) return null;
    else
      return `${dayTitle[day]}, ${date} ${
        uppercase
          ? monthTitle[month][0].toUpperCase() + monthTitle[month].slice(1)
          : monthTitle[month]
      }`;
  };

  const meettingDay = (day) => {
    return new Date(new Date(exhibitionItem[day - 1]?.startDate.slice(0, 10)).getTime());
  };

  const openModal = (data) => {
    if (user.id === +id) {
      setModalMessage(t("cannotSendToThisCompany"));
      return setModalIsVisible(true);
    }
    setModalVisible(true);
    setChoosedDate(data);
  };

  const generateColumnHeads = () => {
    const res = [];
    for (let i = 1; i < exhibitionItem?.length + 1; i++) {
      let dateForm = formatDate(meettingDay(i), true);
      if (!dateForm) continue;
      res.push({
        title: <div style={{ textAlign: "center" }}>{dateForm}</div>,
        dataIndex: i + 1,
        key: i + 1,
        render: (_, { data, link }) => {
          let newDate = dayjs(exhibitionItem[i - 1].startDate);
          newDate = newDate.hour(data.hours);
          newDate = newDate.minute(data.minutes);
          newDate = newDate.second(0);
          newDate = newDate.millisecond(0);
          const dayJsDate = newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          const busy = companyMeetingsTime.find((item) => item === dayJsDate);

          const time = +(String(data.hours) + String(data.minutes === 0 ? "00" : data.minutes));

          if (
            +exhibitionItem[i - 1].startTime.replace(":", "") > time ||
            time >= +exhibitionItem[i - 1].endTime.replace(":", "")
          ) {
            return null;
          } else {
            return (
              <span
                onClick={() => openModal(dayJsDate, i)}
                className={`${styles.time_cell} ${busy ? styles.busy_day : ""}`}
              >
                <p>
                  {" "}
                  {t("userTime")}{" "}
                  {`${data.hours}:${
                    `${data.minutes}`.length < 2 ? "0" + `${data.minutes}` : data.minutes
                  }`}
                </p>
                {link}
              </span>
            );
          }
        }
      });
    }
    return res;
  };

  const gen2MyTime = (dayJsDate) => {
    return `${dayJsDate.format("HH:mm")}`;
  };
  const generateData = () => {
    const minStartTime = String(
      exhibitionItem
        ?.map((m) => ({ ...m, startTime: +m.startTime.replace(":", "") }))
        .sort((a, b) => a.startTime - b.startTime)[0].startTime
    );
    const maxEndTime = exhibitionItem
      ?.map((m) => ({ ...m, endTime: +m.endTime.replace(":", "") }))
      .sort((a, b) => b.endTime - a.endTime)[0].endTime;
    const res = [];
    let date = dayjs()
      .hour(+(minStartTime.length < 4 ? minStartTime.slice(0, 1) : minStartTime.slice(0, 2)))
      .minute(+(minStartTime.length < 4 ? minStartTime.slice(1) : minStartTime.slice(2)));
    for (
      let i = 0;
      String(date.hour() === 0 ? date.hour() + 24 : date.hour()) +
        (date.minute() === 0 ? date.minute() + "0" : date.minute()) <
      maxEndTime;
      i++
    ) {
      res.push({
        key: i + 2,
        time: date.format("HH") + ":" + date.format("mm"),
        link: t("chooseThisDate"),
        data: { hours: date.hour(), minutes: date.minute() }
      });
      date = date.minute(date.minute() + 30);
    }
    return res;
  };

  const companyDataColumns = currentCompany.companyName && [
    {
      title: t("aboutCompanyTable.companyName"),
      dataIndex: "name",
      key: "name"
    },
    {
      title: t("aboutCompanyTable.companyTurnover"),
      dataIndex: "turnover",
      key: "turnover"
    },
    {
      title: t("aboutCompanyTable.employeeCount"),
      dataIndex: "employeeCount",
      key: "employeeCount"
    },
    {
      title: t("aboutCompanyTable.companyCountry"),
      dataIndex: "country",
      key: "country"
    },
    {
      title: t("aboutCompanyTable.companySite"),
      dataIndex: "site",
      key: "site",
      render: (_, { site }) => {
        if (site) {
          return (
            <a
              href={site}
              className={styles.table_link}
              target={"_blank"}
              key={site}
              rel="noreferrer"
            >
              {site}
            </a>
          );
        }
      }
    },
    {
      title: t("aboutCompanyTable.companyKeywords"),
      dataIndex: "keywords",
      key: "keywords"
    }
  ];
  const companyData = currentCompany.companyName && [
    {
      key: "1",
      name: currentCompany.companyName,
      turnover: currentCompany.annualTurnover ? currentCompany.annualTurnover.name : "",
      employeeCount: currentCompany.numberEmployees ? currentCompany.numberEmployees.name : "",
      country: countrytrans.t(currentCompany.country.name),
      site:
        currentCompany.website.slice(0, 4) === "http"
          ? `${currentCompany.website}`
          : !!currentCompany.website.length && `https://${currentCompany.website}`,
      keywords: currentCompany.companyKeywords.map((item) => item.keyword).join(", ")
    }
  ];

  const columns = [
    {
      title: <>{t("exhibition.eventTime")}</>,
      dataIndex: "time",
      key: "time",
      render: (_, { time }) => (
        <span className={styles.table_time} target={"_blank"}>
          {time}
        </span>
      )
    },
    ...generateColumnHeads()
  ];

  const onFinish = (values) => {
    try {
      const { captcha, ...data } = values;
      if (validateCaptcha(captcha)) {
        const request = {
          ...data,
          countryId: currentCompany.countryId,
          companyId: currentCompany.id,
          dateTo: choosedDate,
          dateFrom: choosedDate,
          isModerated: false,
          buyerId: user.id,
          exhibitionStandId: standsId
        };
        meetingAPI
          .createMeetingBuyer(request)
          .then((res) => {
            form.resetFields();
            Promise.all([
              meetingAPI.getCompanyMeetings(id),
              meetingAPI.getCompanyMeetings(user.company.id)
            ])
              .then((res) => {
                setCompanyMeetingsTime([
                  ...new Set([
                    ...res[0].data.map((m) => m.dateFrom),
                    ...res[1].data.map((m) => m.dateFrom)
                  ])
                ]);
              })
              .then(() => {
                setModalVisible(false);
                setSuccessModalShow(true);
              })
              .finally(() => {
                setRequestLoading(false);
              });
          })
          .catch((err) => {
            if (err.response.data.message === "USER_BLOCKED") {
              setModalVisible(false);
              setModalIsVisible(true);
              setModalMessage(t("deactivate"));
            } else if (err.response.data.message === "ERROR_CREATE_MEETING") {
              setModalVisible(false);
              setModalIsVisible(true);
              setModalMessage(t("error_create_meeting"));
            } else {
              setModalMessage(t(`error.${err.response.data.message}`));
              setModalIsVisible(true);
            }
          });
        // console.log(request);
      } else {
        form.setFieldsValue({ captcha: "" });
        form.validateFields();
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!id) {
    return;
  }
  useEffect(() => {
    if (currentCompanyCityTime === undefined) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [currentCompanyCityTime]);

  return (
    <>
      {OnlyLatinLettersModal}
      {currentCompanyCityTime === undefined && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            background: "#F5F7F8",
            zIndex: 1111
          }}
        >
          <Loader />
        </div>
      )}
      {modalIsVisible && (
        <Modal
          visible={modalIsVisible}
          onCancel={() => setModalIsVisible(false)}
          footer={[
            <Button
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                setModalIsVisible(false);
              }}
            >
              OK
            </Button>
          ]}
        >
          <p>{modalMessage}</p>
        </Modal>
      )}

      <Modal
        forceRender
        title={t("modal.title")}
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        closeIcon={<Close />}
        className={styles.modal}
        footer={null}
      >
        <div className={styles.modal_info}>
          <span className={styles.info_text}>
            {t("pageTitle")}{" "}
            <Link
              target={"_blank"}
              to={`/company-profile/${currentCompany.id}`}
              className={"orange"}
            >
              {currentCompany?.companyName}
              <img src={`${backendUrl}/files/${currentCompany?.logo}`} alt="" />
            </Link>
          </span>
        </div>
        <Form
          form={form}
          className={styles.modal__form}
          labelAlign="left"
          colon={false}
          requiredMark={false}
          onFinish={onFinish}
          initialValues={{ agreement: false, languageId: langs[1]?.value }}
        >
          <div className={styles.form__top}>
            <Form.Item
              className={styles.top__form_item}
              name="item"
              label={
                <span
                  className={styles.label}
                  style={{ width: lang === "en" || lang === "es" ? "330px" : "auto" }}
                >
                  {t("modal.form.product_label")}
                </span>
              }
              rules={[{ required: true, message: t("modal.form_errors.product_input") }]}
            >
              <Input
                onChange={(e) => OnlyLatinLettersTextInput(e.target.value, "item", form, true)}
                size="large"
                className={styles.form__input}
              />
            </Form.Item>

            <Form.Item
              className={styles.top__form_item}
              name="itemAmount"
              label={
                <span
                  className={styles.label}
                  style={{ width: lang === "en" || lang === "es" ? "330px" : "auto" }}
                >
                  {t("modal.form.product_count_label")}
                </span>
              }
              rules={[
                { required: true, message: t("modal.form_errors.product_count_input") },
                {
                  validator: (_, value) => {
                    if (!value || value.indexOf("_") !== -1) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <MaskedInput
                mask={/.*$/}
                controls={false}
                size="large"
                className={styles.form__input}
              />
            </Form.Item>
          </div>

          <Form.Item
            className={styles.form_item}
            name="message"
            label={<span className={styles.label}>{t("modal.form.message_label")}</span>}
            rules={[{ required: true, message: t("modal.form_errors.message_input") }]}
          >
            <Input.TextArea
              onChange={(e) => OnlyLatinLettersTextInput(e.target.value, "message", form, true)}
              rows={5}
              cols={5}
              autoSize
              className={`${styles.form__input} ${styles.form__input_textarea}`}
              allowClear
              placeholder={t("placeholderMessage")}
            />
          </Form.Item>

          <Form.Item
            className={styles.form_item}
            name="languageId"
            label={<span className={styles.label}>{t("modal.form.select_label")}</span>}
            rules={[{ required: true, message: "" }]}
          >
            {langs.length && (
              <Select
                className={styles.form__input_select}
                onDropdownVisibleChange={(state) => {
                  setOpenState({ ...initialState, language: state });
                }}
                open={openState.language}
                options={langs}
                size="large"
                suffixIcon={
                  <DropdownIcon
                    onClick={() => {
                      setOpenState((prev) => ({ ...initialState, language: !prev.language }));
                    }}
                  />
                }
              />
            )}
          </Form.Item>

          <Space align="start">
            <Form.Item
              name="agreement"
              valuePropName="checked"
              className={`${styles.form_item} ${styles.form_item__check}`}
              rules={[
                {
                  required: true,
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject())
                }
              ]}
            >
              <Checkbox />
            </Form.Item>
            <Paragraph style={{ width: "491px", fontWeight: 400 }}>
              {t("modal.form.agreement")}
              <br />
              <Link to={"/terms-and-conditions"} className={styles.link} target={"_blank"}>
                {" "}
                {t("modal.form.agreement_user")}{" "}
              </Link>{" "}
              {t("modal.form.agreement_and")}
              <Link to={"/privacy_policy"} className={styles.link} target={"_blank"}>
                {" "}
                {t("modal.form.agreement_policy")}
              </Link>
              *
            </Paragraph>
          </Space>

          <Space size="large">
            <Form.Item
              name="captcha"
              messageVariables={{ label: t("modal.form.captcha") }}
              rules={[{ required: true, message: t("modal.form_errors.captcha") }]}
            >
              <Input size="large" className={styles.form__input} />
            </Form.Item>

            <Form.Item className={styles.form__captcha}>
              <LoadCanvasTemplateNoReload />
            </Form.Item>

            <RetweetOutlined
              style={{ fontSize: 28, marginTop: "-10px", cursor: "pointer" }}
              onClick={() => {
                reloadCaptcha();
              }}
            />
          </Space>

          <Button
            htmlType="submit"
            type="default"
            className={`btn btn_v2 ${styles.modal_btn}`}
            disabled={requestLoading}
          >
            {t("sendRequest")}
          </Button>
        </Form>
      </Modal>

      <Header h1={t("h1")} withBottomPanel={false} />

      <div className={styles.container}>
        <Link to={`/company-profile/${id}`}>
          <Close className={styles.close} />
        </Link>
        <div className={styles.page_content}>
          <h2 className={styles.page_title}>
            {t("pageTitle")}
            {/*{lang === "en" && "with"}*/}
            <Link
              to={`/company-profile/${id}`}
              className={styles[`page_title-link`]}
              target={"_blank"}
            >
              {currentCompany.companyName}
              <img
                src={`${backendUrl}/files/${currentCompany.logo}`}
                className={styles[`page_title-link__img`]}
              />
            </Link>
          </h2>

          <Table
            className={styles.company_table}
            columns={companyDataColumns}
            dataSource={companyData}
            pagination={false}
          />
          <BlockWithExhibitions user={user} id={currentCompany?.id} />
          {/*<div className={style.bigOrange}>{t('exhibition.8', {field: exhibition?.length ? 2 : 1})}</div>*/}
          <div className={styles.page_info} style={{ marginTop: "60px" }}>
            <Paragraph className={styles.info__text} style={{ maxWidth: "650px" }}>
              <Trans components={{ p: <p /> }}>{t("exhibition.14")}</Trans>
            </Paragraph>
            <Paragraph className={styles.info__text} style={{ maxWidth: "650px" }}>
              <Trans components={{ p: <p /> }}>{t("exhibition.9")}</Trans>{" "}
              {currentExhibition?.exhibitionName}
            </Paragraph>
            <Paragraph className={styles.info__text}>
              <Trans components={{ p: <p /> }}>{t("exhibition.10")}</Trans>{" "}
              <Trans components={{ p: <p /> }}>
                {currentExhibition?.country?.localizedName[lang]}
              </Trans>
              {Object.keys(countries).find((item) => item === currentExhibition?.country?.name) ? (
                <img
                  src={countries[currentExhibition?.country?.name]}
                  alt=""
                  className="country_flag"
                />
              ) : (
                <ReactCountryFlag
                  countryCode={currentExhibition?.country?.name}
                  svg
                  style={{
                    width: "26px",
                    height: "18px",
                    borderRadius: "5px",
                    marginTop: "-5px",
                    marginLeft: "8px"
                  }}
                />
              )}
            </Paragraph>
            <Paragraph className={styles.info__text} style={{ maxWidth: "650px" }}>
              <Trans components={{ p: <p /> }}>{t("exhibition.11")}</Trans>{" "}
              {currentExhibition?.city}
            </Paragraph>
            <Paragraph className={styles.info__text} style={{ maxWidth: "650px" }}>
              <Trans components={{ p: <p /> }}>{t("exhibition.12")}</Trans>{" "}
              <Trans components={{ p: <p /> }}>
                {t("exhibition.15", {
                  startDate: currentExhibition?.startDate,
                  endDate: currentExhibition?.endDate
                })}
              </Trans>
            </Paragraph>
          </div>

          <div className={styles.page_data} id={"table"}>
            <Title level={4} className={styles.page_data__title}>
              {t("exhibition.13")}
            </Title>
            <Table
              className={styles.data_table}
              columns={columns}
              dataSource={[...generateData()]}
              pagination={false}
              scroll={{ y: 400 }}
            />
          </div>
        </div>
      </div>
      <Modal
        visible={successModalShow}
        onCancel={() => {
          setSuccessModalShow(false);
          navigate("/account/outgoing-requests-meeting-buyer");
        }}
        footer={null}
        title={false}
        closeIcon={<Close />}
      >
        <h2
          style={{
            fontSize: 18,
            textAlign: "center",
            paddingTop: "5px",
            fontWeight: 400
          }}
        >
          {t("successModalText.title")}
        </h2>
        <div className={styles.success_modal_block}>
          <p className={styles.block_bold}>
            {t("successModalText.text1")}
            <Link to={`/company-profile/${id}`} target={"_blank"}>
              {" " + currentCompany?.companyName}
            </Link>
            <span>
              {dayjs(choosedDate).format("DD.MM.YYYY")}
              {t("successModalText.text2")}
              {gen2MyTime(dayjs(choosedDate).tz("Etc/UTC"))}
            </span>
          </p>
          <p>{t("successModalText.text3")}</p>
          <p>{t("successModalText.text4")}</p>
        </div>
        <button
          onClick={() => {
            setSuccessModalShow(false);
            navigate("/account/outgoing-requests-meeting-buyer");
          }}
          style={{
            display: "block",
            margin: "0 auto",
            cursor: "pointer",
            width: 220,
            height: 44,
            border: "none"
          }}
          className={"btn btn_v2"}
        >
          ОК
        </button>
      </Modal>
      <Footer />
    </>
  );
}

export default MeetingAtTheExhibition;
