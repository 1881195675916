import { companyAPI, tariffsAPI } from "Api/api";

import { createSlice } from "@reduxjs/toolkit";

const normalizeTariff = ({ basicCredits, tariffEndDate, moderationCredits, tariff }) => ({
  basicCredits: basicCredits ?? "",
  tariffEndDate: tariffEndDate ?? "",
  moderationCredits: moderationCredits ?? "",
  tariffId: tariff?.id ?? "",
  name: tariff?.name ?? ""
});

const initialState = {
  tariff: {},
  tariffPrice: [],
  services: [],
  creditsServices: [],
  data: {}
};

const subscriptionManagementSlice = createSlice({
  name: "subscriptionManagement",
  initialState,
  reducers: {
    setTariff(state, action) {
      state.tariff = normalizeTariff(action.payload);
    },
    setTariffPrice(state, action) {
      state.tariffPrice = action.payload;
    },
    setServices(state, action) {
      state.services = action.payload;
    },
    setCreditsServices(state, action) {
      state.creditsServices = action.payload;
    }
  }
});

export const { setTariff, setTariffPrice, setServices, setCreditsServices } =
  subscriptionManagementSlice.actions;

export default subscriptionManagementSlice.reducer;

export const getCompanyById = (id) => (dispatch) => {
  companyAPI
    .getCompanyId(id)
    .then((res) => {
      console.log(res.data)
      dispatch(setTariff(res.data));
      // console.log(res.data);
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const getUserTariffs = (isAuth) => (dispatch) => {
  const api = isAuth ? tariffsAPI.getUserTariffs : tariffsAPI.getTariffs;
  tariffsAPI.getTariffs()
    .then((res) => {
      dispatch(setTariffPrice(res.data));
      // console.log(res.data);
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const getServices = () => (dispatch) => {
  tariffsAPI
    .getServices()
    .then((res) => {
      dispatch(setServices(res.data));
      // console.log("SERVICE", res.data);
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const getCreditsServices = () => (dispatch) => {
  tariffsAPI
    .getCreditsServices()
    .then((res) => {
      dispatch(setCreditsServices(res.data));
      // console.log(res.data);
    })
    .catch((e) => {
      // console.log(e.response);
    });
};
