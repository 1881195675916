/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import axios from "axios";
import { backendUrl } from "Api/api";
import styles from "./auto-complete.module.less";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { setIsExistExhibitions } from "../../../store/exhibitionSlice";
import { setExistExhibitionsArchive } from "../../../store/exhibitionSlice";

const AutoCompleteCustom = ({
  handleSelect,
  OnlyLatinLettersTextInput,
  form,
  setNewExhibitionName,
  isError = false,
  value,
  t,
  setErrors,
  clearError
}) => {
  const [inputValue, setInputValue] = useState();
  const { existExhibitions } = useSelector((state) => state.exhibitions);
  const { existExhibitionsArchive } = useSelector((state) => state.exhibitions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value) setInputValue(value);
  }, [value]);

  const handleChange = (data) => {
    if (!OnlyLatinLettersTextInput(data, "exhibitionName", form, true, null, null, true)) {
      form.resetFields(["exhibitionName"]);
    }
    setInputValue(data);
    setNewExhibitionName(data);
    if (data.length > 1) {
      form.setFieldsValue({
        exhibitionName: data
      });
      axios
        .get(`${backendUrl}/exhibitions/search?exhibitionName=${data}&all=true`)
        .then(({ data }) =>
          dispatch(
            setIsExistExhibitions(
              data.map(({ exhibitionName, id }) => ({
                value: exhibitionName,
                key: id
              }))
            )
          )
        );
      // axios
      //   .get(`${backendUrl}/exhibitions/search?exhibitionName=${data}&isArchive=true&all=true`)
      //   .then(({ data }) =>
      //     dispatch(
      //       setExistExhibitionsArchive(
      //         data.map(({ exhibitionName, id }) => ({
      //           value: exhibitionName,
      //           key: id
      //         }))
      //       )
      //     )
      //   );
    } else {
      dispatch(setExistExhibitionsArchive([]));
      dispatch(setIsExistExhibitions([]));
    }
  };

  return (
    <>
      <AutoComplete
        value={inputValue}
        bordered={false}
        className={`${styles.autoComplete} ${isError ? styles.error : ""}`}
        style={{ minWidth: 128 }}
        options={existExhibitions}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        <TextArea
          className={styles.textarea}
          placeholder={t("placeholder")}
          onChange={(e) => {
            OnlyLatinLettersTextInput(
              e.target.value,
              "exhibitionName",
              form,
              true,
              null,
              null,
              true
            );
            setErrors((prev) => ({ ...prev, exhibitionName: false }));
            clearError("exhibitionName");
          }}
        />
      </AutoComplete>
    </>
  );
};

export default AutoCompleteCustom;
