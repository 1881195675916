import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {adminLogout, userLogout} from "../store/authSlice";
import Cookies from "js-cookie";
import {Button, Modal} from "antd";
import s from "../components/AvailableBalance/AvailableBalance.module.less";
import {useTranslation} from "react-i18next";

const LOGOUT_TIMEOUT = 60 * 60 * 1000;
const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const TimeoutLogout = () => {
    // dsdsds
    const { t } = useTranslation(["TimeoutLogout", "utils"] );
    const { user } = useSelector((state) => state.auth);
    const timerId = useRef(0);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {

        const tabsOpen = localStorage.getItem('tabsOpen')
        if (tabsOpen == null || parseInt(tabsOpen) === 0) {
            localStorage.setItem('tabsOpen', 1)
        } else {
            localStorage.setItem('tabsOpen', parseInt(tabsOpen) + 1)
        }
        const decrementOpenWindowsOnUnload = function (e) {
            const newTabCount = localStorage.getItem('tabsOpen')

            if (newTabCount !== null) {
                localStorage.setItem('tabsOpen', newTabCount - 1)
            }
        }

        window.addEventListener('unload', decrementOpenWindowsOnUnload)

        return() =>{
            window.removeEventListener('unload', decrementOpenWindowsOnUnload)
        }
    }, [])
    const logoutAction = () => {
        dispatch(adminLogout());
        dispatch(userLogout());
        setShowModal(true);
    };
    const resetTimer = () => {
        if (timerId.current) clearTimeout(timerId.current);
    };
    const handleLogoutTimer = () => {
        timerId.current = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            logoutAction();
        }, LOGOUT_TIMEOUT);
    };
    const resetUserActivity = () => {
        resetTimer();
        handleLogoutTimer();
    }
    const beforeUnloadFunc = function (e){
        const newTabCount = localStorage.getItem('tabsOpen')
        if(user && Cookies.get("rememberMe") === "false" && (parseInt(newTabCount) === 1 || !newTabCount)){
            const msg = 'NO!'
            e.preventDefault();
            e.returnValue = msg;
            return msg;
        }
    }
    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnloadFunc)
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadFunc)
        }
    }, [beforeUnloadFunc])
    useEffect(() => {
        if(user){
            handleLogoutTimer();
            Object.values(events).forEach((item) => {
                window.addEventListener(item, resetUserActivity);
            });
        }else{
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetUserActivity);
            });
            resetTimer();
        }
        return() => {
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetUserActivity);
            });
            resetTimer();
        }
    }, [user]);





    return (
        <>
            <Modal
            visible={showModal}
            onCancel={() =>setShowModal(false)}
            footer={[
                <Button key="cancel" className="btn btn_v1" onClick={() => setShowModal(false)}>
                    {t("buttons.ok", { ns: "utils" })}
                </Button>
            ]}
            >
                <p>{t("logoutInfo")}</p>
            </Modal>
    </>)
}
export default TimeoutLogout;
