/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tooltip
} from "antd";
import { LeftOutlined, QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Link, NavLink, useNavigate, useOutletContext } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { backendUrl, draftsAPI, mediaUrl, productsAPI, uploadsAPI } from "../../api/api";
import { createImgState, drawImgInputs } from "../../helpers/formElements";
import {
  getCurrencies,
  getDeliveryTime,
  getDeliveryUnits,
  getIncoterms,
  getPaymentMethods,
  getProductCategories
} from "../../store/resourcesSlice";
import { initialCurrentProduct, setCurrentProduct } from "../../store/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import AddAndCropLogo from "../../components/AddAndCropLogo/AddAndCropLogo";
import ArrowLeft from "../../assets/svg/ArrowLeft";
import { AutoSizeInput } from "../../components/AutoSizeInput/AutoSizeInput";
import Close from "../../assets/svg/Close";
import CompanyProfile from "../FK3-сompanyProfile/CompanyProfile";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import EditModeBtns from "../../components/EditModeBtns/EditModeBtns";
import Img from "../../assets/svg/Img";
import Loader from "../../components/Loader/Loader";
import ProductItem from "../FK3-сompanyProfile/About/ProductItem/ProductItem";
import React from "react";
import Trash from "../../assets/svg/trash";
import YouTube from "react-youtube";
import axios from "axios";
import { convertStringToLinks } from "../../helpers/convertStringToLinks";
import { customKeyWordsValidator } from "../../helpers/validation-rules";
import edit from "../../assets/svg/Edit";
import plusSvg from "../../assets/svg/plus.svg";
import s from "./AddNewProduct.module.less";
import s2 from "../FK2.1-productPage/ProductPage.module.less";
import { setIsLoading } from "../../store/authSlice";
import useChangeFormAlert from "../../hooks/useChangeFormAlert";
import useOnlyLatinLetterInputHandlerAndModal from "../../hooks/useOnlyLatinLetterInputHandlerAndModal";
import useProductServiceInfo from "../../hooks/useProductServiceInfo";
import CategoriesSelect from "../../components/CategoriesSelect/CategoriesSelect";

const MIN_IMG_WIDTH = 600;
const MIN_IMG_HEIGHT = 600;
export const AddNewProduct = React.forwardRef(function AddNewProduct(
  {
    editMode = null,
    setEditMode = null,
    editId = 0,
    admin = false,
    customCompanyId,
    showStatistics = true,
    showButtonsAndStatus = true,
    formDisabled = false
  },
  ref
) {
  const imgInputRef = React.createRef();
  const imgInputRef2 = React.createRef();
  const imgInputRef3 = useRef(null);
  const [form] = Form.useForm();
  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();
  const dispatch = useDispatch();
  const { t } = useTranslation([
    "FS10-AddNewProduct",
    "PaymentMethods",
    "FS8-changeCompanyProfile",
    "Incoterms",
    "DeliveryTime",
    "FS12-EditProductOrService",
    "ProductServiceInfo",
    "FK2.1-productPage",
    "ProductServiceInfo",
    "utils"
  ]);
  const navigate = useNavigate();
  const context = useOutletContext();
  const setTitle = context?.setTitle || null;
  const [videoIsUploading, setVideoIsUploading] = useState(false);
  const [displayAlert, setDisplayAlert, EditFormAlert] = useChangeFormAlert();
  let redirectTo = useRef(null);
  const autoSaveTimeoutId = useRef(0);
  useEffect(() => {
    if (setTitle) {
      if (editMode) setTitle(t("editProduct", { ns: "FS12-EditProductOrService" }));
      else setTitle(t("title"));
    }
  }, [t, setTitle]);

  const {
    productCategories,
    currencies,
    deliveryUnits,
    deliveryTime,
    paymentMethods: paymentMethodsArr,
    incoterms: incotermsArr
  } = useSelector((state) => state.resources);
  const { user, isLoading, isAdmin } = useSelector((state) => state.auth);
  const { currentProduct } = useSelector((state) => state.products);
  const [showPreview, setShowPreview] = useState(false);
  const [showSendForModerationInPreview, setShowSendForModerationInPreview] = useState(false);
  const [productStatus, setProductStatus] = useState({ text: "", className: "green" });
  const [mainPreviewImg, setMainPreviewImg] = useState("");
  const [previewImages, setPreviewImages] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const requestError = useRef(false);
  const containerRef = useRef(null);

  const initForm = () => {
    // console.log(currentProduct);
    const dataSource = currentProduct.moderations ? currentProduct.moderations : currentProduct;
    let formPayMethods = {};
    let formIncoterms = {};

    if (dataSource.subcategories[1]) {
      addSelect(1);
    } else if (!dataSource.subcategories[1]) {
      removeSelect(1);
    }
    if (dataSource.video.indexOf(".mp4") !== -1) {
      setVideoType("uploadVideo");
      setVideoFileName(dataSource.video);
      setResponseVideoFileName(dataSource.video);
    } else {
      setVideoType("youTubeLink");
      setVideoLink(dataSource.video);
      setVideoFileName("");
      setResponseVideoFileName("");
    }
    if (currentProduct.image) {
      setImgFileName(dataSource.image);
      setResponseImgFileName(dataSource.image);
      setLocalImgUrl(`${mediaUrl}${dataSource.image}`);
    } else {
      setImgFileName("");
      setResponseImgFileName("");
      setLocalImgUrl("");
    }
    if (dataSource.subcategories.some((obj) => obj.id === undefined)) {
      setSubcategories([]);
    } else {
      setSubcategories(dataSource.subcategories.map((obj) => obj.id));
    }
    createImgState(dataSource, "productPhotos", setAdditionalImgs);
    if (dataSource.paymentMethods.length) {
      // console.log(paymentMethodsArr);
      const payMethods = paymentMethodsArr.map((m) => ({
        ...m,
        checked: false
      }));

      dataSource.paymentMethods.forEach((m) => {
        let el = payMethods.find((pm) => pm.id === m.id);

        if (el) {
          formPayMethods[el.name] = true;
          el.checked = true;
        }
      });
      // console.log(payMethods);
      setPayMethods(payMethods);
    } else {
      setPayMethods([...paymentMethodsArr.map((m) => ({ ...m, checked: false }))]);
      paymentMethodsArr.forEach((el) => {
        formPayMethods[el.name] = false;
      });
    }
    if (dataSource.incoterms.length) {
      // console.log(incotermsArr);
      const incterm = incotermsArr.map((m) => ({ ...m, checked: false }));
      dataSource.incoterms.forEach((inc) => {
        let el = incterm.find((pel) => pel.id === inc.id);
        if (el) {
          formIncoterms[el.name] = true;
          el.checked = true;
        }
      });

      setIncTerms(incterm);
    } else {
      setIncTerms([...incotermsArr.map((m) => ({ ...m, checked: false }))]);
      incotermsArr.forEach((el) => {
        formIncoterms[el.name] = false;
      });
    }

    form.setFieldsValue({
      productName: dataSource.productName,
      keywords: dataSource.productKeywords.map((k) => k.keyword).join(", "),
      description: dataSource.description,
      youtubeLink: dataSource.video.indexOf(".mp4") === -1 ? dataSource.video : "",
      advantage1: dataSource.advantage1,
      advantage2: dataSource.advantage2,
      advantage3: dataSource.advantage3,
      unitPrice: dataSource.unitPrice ? Number(dataSource.unitPrice) / 100 : undefined,
      fobUnitPrice: dataSource.fobUnitPrice ? Number(dataSource.fobUnitPrice) / 100 : undefined,
      currency: dataSource.currency && dataSource.currency.id ? dataSource.currency.id : undefined,
      minimalAmount: dataSource.minimalAmount,
      deliveryUnitId: dataSource.deliveryUnitId,
      deliveryTimeId: dataSource.deliveryTimeId,
      availableCertificates: dataSource.availableCertificates,
      visible: dataSource.visible,
      // visible: true,
      deliveryAddress: dataSource.deliveryAddress,
      productiveCapacity: dataSource.productiveCapacity,
      requirementsPackingDetails: dataSource.requirementsPackingDetails,
      ...formPayMethods,
      ...formIncoterms,
      subcategories:
        dataSource.subcategories && dataSource.subcategories.length
          ? [...Object.values(dataSource.subcategories).map((el) => el.id)]
          : [undefined]
    });
  };

  useEffect(() => {
    initForm();
    if (editMode) {
      if (
        currentProduct.moderationStatus.name === "ACCEPTED" ||
        currentProduct.moderationStatus.name === "PROCESSED"
      ) {
        setDisplayAlert(true);
      } else {
        setDisplayAlert(false);
      }
      if (currentProduct.moderationStatus.name === "PROCESSED") {
        setProductStatus({
          text: "onModeration",
          className: "red"
        });
      } else if (currentProduct.moderationStatus.name === "EDITED") {
        setProductStatus({
          text: "changedWithoutModeration",
          className: "darkRed"
        });
      } else {
        if (currentProduct.moderationStatus.name === "ACCEPTED") {
          setProductStatus({
            text: "moderated",
            className: "green"
          });
        } else if (currentProduct.moderationStatus.name === "REJECTED") {
          setProductStatus({
            text: "rejected",
            className: "red"
          });
        }
      }
    }
  }, [form, currentProduct, paymentMethodsArr, incotermsArr]);

  const [companyId, setCompanyId] = useState(admin ? customCompanyId : user?.company?.id);

  const fetchDraft = () => {
    draftsAPI.getDraft({ companyId: user?.company?.id, entityType: "product" }).then((res) => {
      // console.log(res.data);
      if (res.data) {
        dispatch(setCurrentProduct(res.data));
        setProductStatus({
          text: "draft",
          className: "darkRed"
        });
      } else {
        // console.log("set current product");
        dispatch(setCurrentProduct(initialCurrentProduct));
        setProductStatus({
          text: "",
          className: ""
        });
      }
    });
  };

  useEffect(() => {
    setCompanyId(admin ? customCompanyId : user?.company?.id);
    if (user && !editMode) {
      fetchDraft();
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!productCategories.length) {
      dispatch(getProductCategories());
    }
    if (!currencies.length) {
      dispatch(getCurrencies());
    }
    if (!deliveryUnits.length) {
      dispatch(getDeliveryUnits());
    }
    if (!deliveryTime.length) {
      dispatch(getDeliveryTime());
    }
    if (!paymentMethodsArr.length) {
      dispatch(getPaymentMethods());
    }
    if (!incotermsArr.length) {
      dispatch(getIncoterms());
    }
  }, []);

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showImgErrorModal, setShowImgErrorModal] = useState(false);
  const [closeAfterEdit, setCloseAfterEdit] = useState(false);
  const [showExitWithoutSavingModal, setShowExitWithoutSavingModal] = useState(false);
  const initialState = {
    category0: false,
    category1: false,
    currency: false,
    deliveryUnitId: false,
    deliveryTimeId: false
  };
  const [openState, setOpenState] = useState(initialState);
  const [videoType, setVideoType] = useState("youTubeLink"); // 'uploadVideo'  || 'youTubeLink'
  const [categories, setCategories] = useState({});
  const [videoLink, setVideoLink] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [videoFileStatus, setVideoFileStatus] = useState("");
  const [videoFileStatusText, setVideoFileStatusText] = useState("");
  const [responseVideoFileName, setResponseVideoFileName] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [imgFileStatus, setImgFileStatus] = useState("");
  const [imgFileStatusText, setImgFileStatusText] = useState("");
  const [responseImgFileName, setResponseImgFileName] = useState("");
  const [localImgUrl, setLocalImgUrl] = useState("");
  const [imgError, setImgError] = useState(false);
  const [subcategoriesError, setSubcategoriesError] = useState(false);
  const previewImagesRef = useRef();

  useEffect(() => {
    if (subcategories.length) {
      setSubcategoriesError(false);
    }
  }, [subcategories.length]);

  const checkImgError = () => {
    if (!responseImgFileName) {
      setImgError(true);
    } else {
      setImgError(false);
    }
  };
  const checkSubcategoriesError = () => {
    if (!subcategories.length) {
      setSubcategoriesError(true);
    } else setSubcategoriesError(false);
  };
  const [additionalImgs, setAdditionalImgs] = useState({
    addImg: {
      localImgUrl: "",
      fileName: "",
      fileStatus: "",
      statusText: "",
      responseFileName: ""
    }
  });
  const [payMethods, setPayMethods] = useState([]);

  useEffect(() => {
    setPreviewImages(() => {
      let newPreviewImages = Object.values(additionalImgs)
        .filter((item) => !!item?.localImgUrl)
        .map((item) => item.localImgUrl);
      newPreviewImages.push(localImgUrl);
      return newPreviewImages;
    });
  }, [additionalImgs, localImgUrl]);
  useEffect(() => {
    setMainPreviewImg(localImgUrl);
  }, [localImgUrl]);
  useEffect(() => {
    setPayMethods([...paymentMethodsArr.map((m) => ({ ...m, checked: false }))]);
  }, [paymentMethodsArr]);
  const [paymentChosen, setPaymentChosen] = useState(false);
  const [paymentChosenError, setPaymentChosenError] = useState(false);
  const checkPaymentIsChosen = () => {
    if (!paymentChosen) {
      setPaymentChosenError(true);
    } else {
      setPaymentChosenError(false);
    }
  };
  useEffect(() => {
    if (payMethods.find((m) => m.checked === true)) {
      setPaymentChosen(true);
    } else {
      setPaymentChosen(false);
    }
  }, [payMethods]);
  const [incterms, setIncTerms] = useState([]);
  const [inctermsChosen, setInctermsChosen] = useState(false);
  const [inctermsChosenError, setInctermsChosenError] = useState(false);
  const checkInctermsIsChosen = () => {
    if (!inctermsChosen) {
      setInctermsChosenError(true);
    } else {
      setInctermsChosenError(false);
    }
  };
  useEffect(() => {
    if (incterms.find((m) => m.checked === true)) {
      setInctermsChosen(true);
    } else {
      setInctermsChosen(false);
    }
  }, [incterms]);

  const [categoriesSelects, setCategoriesSelects] = useState([]);

  function addSelect(index) {
    setCategoriesSelects([...categoriesSelects, { index }]);
  }

  function removeSelect(index) {
    // eslint-disable-next-line no-unused-vars
    const { [index]: ind, ...restCategories } = categories;
    setCategories({ ...restCategories });
    setCategoriesSelects([...categoriesSelects.filter((s) => s.index !== index)]);
  }

  const drawPayMethods = () => {
    return (
      payMethods.length &&
      paymentMethodsArr.map((m) => {
        return (
          <Space key={m.id} align="center" style={{ marginBottom: "15px", display: "flex" }}>
            <Form.Item name={m.name} className={s.no_margin} valuePropName="checked">
              <Checkbox
                style={{ borderColor: "red" }}
                checked={m.checked}
                onChange={() => {
                  setPaymentChosenError(false);
                  setPayMethods((prev) => [
                    ...prev.map((m2) => {
                      if (m2.id === m.id) {
                        return {
                          ...m2,
                          checked: !m2.checked
                        };
                      } else {
                        return m2;
                      }
                    })
                  ]);
                }}
              />
            </Form.Item>
            <span className="fw-500">{t(`${m.name}`, { ns: "PaymentMethods" })}</span>
          </Space>
        );
      })
    );
  };
  const drawIncoterms = () => {
    return (
      incterms.length &&
      incotermsArr.map((m) => {
        return (
          <Space
            key={m.id}
            align="center"
            style={{ marginBottom: "15px", display: "flex", width: "50%" }}
          >
            <Form.Item name={m.name} className={s.no_margin} valuePropName="checked">
              <Checkbox
                style={{ borderColor: "red" }}
                checked={m.checked}
                onChange={() => {
                  setInctermsChosenError(false);
                  setIncTerms((prev) => [
                    ...prev.map((m2) => {
                      if (m2.id === m.id) {
                        return {
                          ...m2,
                          checked: !m2.checked
                        };
                      } else {
                        return m2;
                      }
                    })
                  ]);
                }}
              />
            </Form.Item>
            <span className="fw-500">{t(`${m.name}`, { ns: "Incoterms" })}</span>
          </Space>
        );
      })
    );
  };
  const { getInfo, InfoVisualisation } = useProductServiceInfo(admin, customCompanyId);

  const onFinish = (
    values,
    sendModeration = false,
    showModal = true,
    redirectToEditPage = true
  ) => {
    clearTimeout(autoSaveTimeoutId.current);
    if (videoIsUploading) {
      setModalMessage(t("responses.waitForVideoToUpload"));
      setModalIsVisible(true);
      return;
    }
    const image = responseImgFileName;
    const images = Object.values(additionalImgs)
      .filter((el) => el && el.responseFileName !== "")
      .map((el) => el.responseFileName);

    let video;
    if (videoType === "uploadVideo" && videoFileStatus === "success") {
      video = responseVideoFileName;
    } else {
      video = videoLink;
    }
    const paymentMethods = [...payMethods.filter((m) => m.checked === true).map((m) => m.id)];
    const incoterms = [...incterms.filter((m) => m.checked === true).map((m) => m.id)];

    let keywordsArr = values.keywords ? values.keywords.split(", ") : [];
    const unitPriceX100 = values.unitPrice * 100;
    const fobUnitPriceX100 = values.fobUnitPrice * 100;
    if (editMode === null) {
      dispatch(setIsLoading(true));
      productsAPI
        .createProduct(
          { sendModeration },
          values.productName,
          keywordsArr,
          values.description,
          values.advantage1,
          values.advantage2,
          values.advantage3,
          image,
          images,
          video,
          unitPriceX100,
          fobUnitPriceX100,
          // currencyId,
          values.currency,
          values.minimalAmount || 1,
          values.deliveryUnitId,
          values.deliveryTimeId,
          values.availableCertificates,
          values.visible,
          values.deliveryAddress,
          values.productiveCapacity,
          values.requirementsPackingDetails,
          // values.subcategories,
          subcategories,
          paymentMethods,
          incoterms,
          companyId
        )
        .then(() => {
          requestError.current = false;
          if (sendModeration) {
            if (!isAdmin) {
              setModalMessage(t("responses.success"));
            } else {
              setModalMessage(t("responses.saved"));
            }
            if (redirectToEditPage)
              redirectTo.current = `${
                !isAdmin ? "/account/edit-product-or-service" : "/admin-panel"
              }`;
          } else {
            setModalMessage(t("responses.saved"));
          }
          if (showModal) setModalIsVisible(true);
        })
        .catch((e) => {
          requestError.current = true;
          // console.log(e.response);
          if (e.response.data.message === "PRODUCTS_SERVICES_ADD_LIMIT") {
            setModalMessage(t("responses.PRODUCTS_SERVICES_ADD_LIMIT"));
          } else {
            setModalMessage(t("responses.error"));
          }

          if (showModal) setModalIsVisible(true);
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
      if (imgInputRef.current) imgInputRef.current.value = "";
      if (imgInputRef2.current) imgInputRef2.current.value = "";
    } else {
      dispatch(setIsLoading(true));
      productsAPI
        .updateProduct(
          { sendModeration },
          values.productName,
          keywordsArr,
          values.description,
          values.advantage1,
          values.advantage2,
          values.advantage3,
          image,
          images,
          video,
          unitPriceX100,
          fobUnitPriceX100,
          values.currency,
          values.minimalAmount || 1,
          values.deliveryUnitId,
          values.deliveryTimeId,
          values.availableCertificates,
          values.visible,
          values.deliveryAddress,
          values.productiveCapacity,
          values.requirementsPackingDetails,
          // values.subcategories,
          subcategories,
          paymentMethods,
          incoterms,
          companyId,
          editId
        )
        .then(() => {
          requestError.current = false;
          if (sendModeration) {
            if (!isAdmin) {
              setModalMessage(t("responses.success"));
            } else {
              setModalMessage(t("responses.saved"));
            }
            setCloseAfterEdit(true);
            redirectTo.current = `${
              !isAdmin ? "/account/edit-product-or-service" : "/admin-panel"
            }`;
            setProductStatus({
              text: "onModeration",
              className: "red"
            });
            if (showModal) setModalIsVisible(true);
            setDisplayAlert(true);
          } else {
            setModalMessage(t("responses.saved"));
            setProductStatus({
              text: "changedWithoutModeration",
              className: "orange"
            });
            if (!displayAlert) {
              if (showModal) setModalIsVisible(true);
            }
          }
        })
        .catch((e) => {
          requestError.current = true;
          // console.log(e.response);

          setModalMessage(t("responses.error"));
          if (showModal) setModalIsVisible(true);
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    }
  };
  const onFinishFailed = () => {
    // checkImgError();
    // checkPaymentIsChosen();
    // checkInctermsIsChosen();
  };

  const [visibleCropLogo, setVisibleCropLogo] = useState(false);
  const [visibleAdditionalCropLogo, setVisibleAdditionalCropLogo] = useState(false);
  const [img, setImg] = useState({});
  const [ind, setInd] = useState("");

  const addFoto = (file) => {
    setImgError(false);
    setResponseImgFileName("");
    setImgFileStatusText("");
    setImgFileStatus("");
    setImgFileName(file.name || "");
    setLocalImgUrl("");
    if (file) {
      setLocalImgUrl(URL.createObjectURL(file));
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = function () {
        setImgFileStatus("loading");
        setImgFileStatusText(t("fields.File.status.loading"));

        const formData = new FormData();
        formData.append("file", file);
        formData.append("originalname", file?.name);
        uploadsAPI
          .uploadImage(formData)
          .then((res) => {
            setImgFileStatus("success");
            setImgFileStatusText(t("fields.File.status.succesfullyLoaded"));
            setResponseImgFileName(res.data.filename);
          })
          .catch((e) => {
            // console.log(e);
            setImgFileStatus("failed");
            setImgFileStatusText(t("fields.File.status.uploadFailed"));
          });
      };
    }
  };

  const addAdditionalPhoto = (e) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(e);
    img.onload = function () {
      setAdditionalImgs((prev) => ({
        ...prev,
        ["addImg" + ind]: {
          localImgUrl: URL.createObjectURL(e),
          fileName: e?.name,
          fileStatus: "loading",
          statusText: t("fields.File.status.loading"),
          responseFileName: ""
        }
      }));
      const formData = new FormData();
      formData.append("file", e);
      formData.append("originalname", e.name);
      uploadsAPI
        .uploadImage(formData)
        .then((res) => {
          setAdditionalImgs((prev) => ({
            ...prev,
            ["addImg" + ind]: {
              localImgUrl: URL.createObjectURL(e),
              fileName: e?.name,
              fileStatus: "success",
              statusText: t("fields.File.status.succesfullyLoaded"),
              responseFileName: res.data.filename
            }
          }));
          if (Object.keys(additionalImgs).length >= 1) {
            setAdditionalImgs((prev) => ({
              ...prev,
              ["addImg" + Object.keys(prev).length]: {
                localImgUrl: "",
                fileName: "",
                fileStatus: "",
                statusText: "",
                responseFileName: ""
              }
            }));
          }
        })
        .catch((e) => {
          setAdditionalImgs((prev) => ({
            ...prev,
            addImg: {
              localImgUrl: URL.createObjectURL(e),
              fileName: e?.name,
              fileStatus: "failed",
              statusText: t("fields.File.status.uploadFailed"),
              responseFileName: ""
            }
          }));
        })
        .finally(() => {
          setInd("");
        });
    };
  };

  const onSelectFile = (e, type, ind) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].size < 50000000) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImg({ base64: reader.result?.toString() || "", name: e.target.files[0].name })
      );
      reader.readAsDataURL(e.target.files[0]);
      if (type === "cropLogo") setVisibleCropLogo(true);
      else if (type === "cropAdditionalImg") {
        setVisibleAdditionalCropLogo(true);
        setInd(ind);
      }
    } else if (e.target.files[0].size > 50000000) {
      setShowImgErrorModal(true);
    }
  };

  const handlePickImgInputRef3 = () => {
    imgInputRef3.current.click();
  };

  const autoSave = () => {
    clearTimeout(autoSaveTimeoutId.current);
    if (!displayAlert && !showPreview) {
      autoSaveTimeoutId.current = setTimeout(() => {
        let values = form.getFieldsValue();
        onFinish(values, false, false);
      }, 1000);
    }
  };

  const handleSwitchMainImg = (img, index) => {
    setMainPreviewImg(img);
  };

  const handlerScrollRight = () => {
    previewImagesRef.current.scrollBy(130, 0);
  };
  const handlerScrollLeft = () => {
    previewImagesRef.current.scrollBy(-130, 0);
  };

  useEffect(() => {
    autoSave();
    return () => {
      clearTimeout(autoSaveTimeoutId.current);
    };
  }, [
    displayAlert,
    responseImgFileName,
    additionalImgs,
    responseVideoFileName,
    videoLink,
    videoType,
    payMethods,
    incterms,
    subcategories
  ]);
  useImperativeHandle(
    ref,
    () => {
      return {
        validateAndSave() {
          return validateAndSave();
        }
      };
    },
    [videoIsUploading, responseImgFileName, paymentChosen, inctermsChosen, incterms, payMethods, subcategories]
  );

  return (
    <>
      <AddAndCropLogo
        visible={visibleCropLogo}
        setVisible={setVisibleCropLogo}
        saveCropLogo={addFoto}
        img={img}
        setImg={setImg}
        inputId={"mainImg"}
      />
      <AddAndCropLogo
        visible={visibleAdditionalCropLogo}
        setVisible={setVisibleAdditionalCropLogo}
        saveCropLogo={addAdditionalPhoto}
        img={img}
        setImg={setImg}
        inputId={"additionalPhoto"}
      />
      {OnlyLatinLettersModal}
      <Modal
        visible={showImgErrorModal}
        onOk={() => setShowImgErrorModal("")}
        onCancel={() => setShowImgErrorModal("")}
        footer={[
          <Button
            key="ok"
            className="btn btn_v2"
            onClick={() => {
              setShowImgErrorModal("");
            }}
          >
            OK
          </Button>
        ]}
      >
        <p>{t("logoModalError", { ns: "FS8-changeCompanyProfile" })}</p>
      </Modal>
      {showExitWithoutSavingModal && (
        <Modal
          visible={showExitWithoutSavingModal}
          onOk={() => setEditMode(null)}
          onCancel={() => setShowExitWithoutSavingModal(false)}
          width={"max-content"}
          style={{ maxWidth: "1000px" }}
          footer={[
            <Space
              key={1}
              size={30}
              align="center"
              style={{
                marginBottom: "15px",
                display: "flex",
                justifyContent: "flex-end",
                zIndex: 2,
                position: "relative"
              }}
            >
              <Button
                className={`btn btn_v2  ml-auto`}
                size="large"
                onClick={() => setEditMode(null)}
              >
                {t("buttons.yes", { ns: "utils" })}
              </Button>
              <Button
                className={`btn btn_v8  ml-auto`}
                size="large"
                onClick={() => setShowExitWithoutSavingModal(false)}
              >
                {t("buttons.no", { ns: "utils" })}
              </Button>
            </Space>
          ]}
        >
          <p style={{ whiteSpace: "pre-line" }}>
            {t("confirmLeaveWithoutSaving", { ns: "FS10-AddNewProduct" })}
          </p>
        </Modal>
      )}
      {showPreview && (
        <Modal
          visible={showPreview}
          onOk={() => {
            setShowPreview(false);
          }}
          onCancel={() => setShowPreview(false)}
          width={"max-content"}
          style={{ maxWidth: "1000px" }}
          footer={[
            <Space
              key={1}
              size={30}
              align="center"
              style={{
                marginBottom: "15px",
                display: "flex",
                justifyContent: "flex-end",
                zIndex: 2,
                position: "relative"
              }}
            >
              <Button
                className={`btn btn_v8  ml-auto`}
                size="large"
                onClick={() => {
                  setShowPreview(false);
                }}
              >
                {t("buttons.backToEdit", { ns: "FS10-AddNewProduct" })}
              </Button>
              {showSendForModerationInPreview && (
                <Button
                  className={`btn btn_v2 ml-auto`}
                  size="large"
                  onClick={() => {
                    checkImgError();
                    checkPaymentIsChosen();
                    checkInctermsIsChosen();
                    checkSubcategoriesError();
                    form
                      .validateFields()
                      .then((res) => {
                        if (videoIsUploading) {
                          setModalMessage(t("responses.waitForVideoToUpload"));
                          setModalIsVisible(true);
                          return;
                        }
                        if (!responseImgFileName) {
                          document.getElementById("mainImg").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                          return;
                        }
                        if (!subcategories.length) {
                          document.getElementById("test").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                          return;
                        }
                        // eslint-disable-next-line no-empty
                        if (!paymentChosen || !inctermsChosen) {
                          return;
                        }
                        onFinish(form.getFieldsValue(), true);
                        setShowPreview(false);
                      })
                      .catch((e) => {
                        if (!responseImgFileName) {
                          document.getElementById("mainImg").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                        } else {
                          const errorFieldId =
                            e.errorFields[0].name[0] === "subcategories"
                              ? `#addProduct_${e.errorFields[0].name[0]}_0`
                              : `#addProduct_${e.errorFields[0].name[0]}`;
                          document.querySelector(`${errorFieldId}`).scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                        }
                      });
                  }}
                >
                  {t(`${isAdmin ? "buttons.save" : "buttons.sendForModeration"}`, {
                    ns: "FS10-AddNewProduct"
                  })}
                </Button>
              )}
            </Space>
          ]}
        >
          <div className={s2.main} style={{ fontWeight: 400 }}>
            <div style={{ display: "flex" }}>
              <div>
                <div className={s2.imgWrapper}>
                  <img className={s2.image} src={`${mainPreviewImg}`} alt="" />
                </div>
                <div style={{ position: "relative" }}>
                  <div className={s2.smallImgWrapper} ref={previewImagesRef}>
                    {previewImages.map((img, index) => {
                      return (
                        <img
                          key={index}
                          className={s2.smallImage}
                          src={`${img}`}
                          alt="photo"
                          onClick={() => handleSwitchMainImg(img, index)}
                        />
                      );
                    })}
                  </div>
                  {previewImages.length > 3 && (
                    <div className={s2.arrowLeft} onClick={handlerScrollLeft}>
                      <LeftOutlined />
                    </div>
                  )}
                  {previewImages.length > 3 && (
                    <div className={s2.arrowRight} onClick={handlerScrollRight}>
                      <RightOutlined />
                    </div>
                  )}
                </div>
              </div>
              <div style={{ margin: "0 32px 32px 32px" }}>
                <div className={s2.title}>{form.getFieldValue("productName")}</div>
                <div className={s2.subTitle}>{t("fields.unitPrice.label")}</div>
                <div className={s2.subValue}>
                  {form.getFieldValue("unitPrice")}{" "}
                  {currencies.find((el) => el.id === form.getFieldValue("currency")).name}
                </div>
                <div className={s2.subTitle}>{t("minimumOrder", { ns: "FK2.1-productPage" })}</div>
                <div className={s2.subValue}>
                  {form.getFieldValue("minimalAmount")} {t("amount", { ns: "FK2.1-productPage" })}
                </div>
                <div className={s2.subTitle}>{t("paymentMethod", { ns: "FK2.1-productPage" })}</div>
                <div className={s2.paymentMethod}>
                  {payMethods
                    .filter((m) => m.checked === true)
                    .map((m) => (
                      <div key={m.id} style={{ margin: "2px" }} className={s2.subValue}>
                        {t(m.name, { ns: "FK2.1-productPage" })}
                      </div>
                    ))}
                </div>

                <div className={s2.subTitle}>{t("certificates", { ns: "FK2.1-productPage" })}</div>
                <div className={s2.subValue}>
                  {convertStringToLinks(form.getFieldValue("availableCertificates"))}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "50px" }}>
              <div className={s2.title}>{t("description", { ns: "FK2.1-productPage" })}</div>
              <div className={s2.text}>{form.getFieldValue("description")}</div>
              {form.getFieldValue("advantage1") ||
              form.getFieldValue("advantage2") ||
              form.getFieldValue("advantage3") ? (
                <>
                  <div className={s2.title} style={{ marginTop: "32px" }}>
                    {t("competitiveAdvantages", { ns: "FK2.1-productPage" })}
                  </div>
                  <ul>
                    {form.getFieldValue("advantage1") && (
                      <li className={s2.advantage}>{form.getFieldValue("advantage1")}</li>
                    )}
                    {form.getFieldValue("advantage2") && (
                      <li className={s2.advantage}>{form.getFieldValue("advantage2")}</li>
                    )}
                    {form.getFieldValue("advantage3") && (
                      <li className={s2.advantage}>{form.getFieldValue("advantage3")}</li>
                    )}
                  </ul>
                </>
              ) : null}
            </div>
            <div style={{ marginTop: "32px", width: "500px" }}>
              <div style={{ display: "flex" }}>
                <div className={s2.wrap} style={{ marginRight: "60px" }}>
                  <div>
                    <div className={s2.subTitle}>
                      {t("productionCapacity", { ns: "FK2.1-productPage" })}
                    </div>
                    <div>{form.getFieldValue("productiveCapacity")}</div>
                  </div>
                  <div>
                    <div className={s2.subTitle}>{t("incoterms", { ns: "FK2.1-productPage" })}</div>
                    <div className={s2.paymentMethod}>
                      {incterms
                        .filter((el) => el.checked)
                        .map((item, index) => (
                          <div style={{ padding: "0 2px" }} key={index}>
                            {item.name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={s2.wrap}>
                  <div>
                    <div className={s2.subTitle}>
                      {t("deliveryTime", { ns: "FK2.1-productPage" })}
                    </div>
                    <div>
                      {t(
                        deliveryTime.find((el) => el.id === form.getFieldValue("deliveryTimeId"))
                          .name,
                        { ns: "DeliveryTime" }
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={s2.subTitle}>{t("package", { ns: "FK2.1-productPage" })}</div>
                    <div>
                      {t(
                        deliveryUnits.find((el) => el.id === form.getFieldValue("deliveryUnitId"))
                          .name,
                        { ns: "FK2.1-productPage" }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {((videoType === "youTubeLink" && form.getFieldValue("youtubeLink")) ||
              (videoType === "uploadVideo" &&
                videoFileStatus !== "error" &&
                responseVideoFileName)) && (
              <div style={{ marginTop: "50px", position: "relative", zIndex: 2 }}>
                <div className={s2.title}>{t("productVideo", { ns: "FK2.1-productPage" })}</div>
                {videoType === "uploadVideo" &&
                  videoFileStatus !== "error" &&
                  responseVideoFileName && (
                    <video
                      src={`${mediaUrl}${responseVideoFileName}`}
                      title="video"
                      controls="controls"
                      preload={false}
                      style={{
                        width: "max-content",
                        height: "max-content",
                        maxHeight: "260px",
                        maxWidth: "390px"
                      }}
                    />
                  )}
                {videoType === "youTubeLink" && form.getFieldValue("youtubeLink") && (
                  <>
                    <YouTube
                      videoId={
                        form
                          .getFieldValue("youtubeLink")
                          .match(
                            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
                          )[7]
                      }
                      style={{ width: "390px", height: "260px" }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onOk={() => {
            if (!editMode && !requestError.current) {
              fetchDraft();
              getInfo();
              if (redirectTo.current) {
                navigate(redirectTo.current, { state: { tab: "product" } });
              }
            }
            setModalIsVisible(false);
            setShowPreview(false);
          }}
          onCancel={() => {
            if (!editMode && !requestError.current) {
              fetchDraft();
              getInfo();
            }
            setModalIsVisible(false);
            setShowPreview(false);
          }}
          className={s.modal}
          footer={[
            <Button
              style={{ marginBottom: "20px" }}
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                if (!editMode && !requestError.current) {
                  fetchDraft();
                  getInfo();
                  if (redirectTo.current) {
                    navigate(redirectTo.current, { state: { tab: "product" } });
                  }
                }
                setModalIsVisible(false);
                setShowPreview(false);
                if (closeAfterEdit) {
                  setEditMode(null);
                }
              }}
            >
              ОК
            </Button>
          ]}
        >
          <p style={{ whiteSpace: "pre-line" }}>
            <Trans components={{ h3: <h3 style={{ marginBottom: 10 }} /> }}>{modalMessage}</Trans>
          </p>
        </Modal>
      ) : null}
      <Row ref={containerRef}>
        <Col xxl={{ span: 24 }} className={`regular_block`}>
          {editMode !== null && (
            <div className={s.close} onClick={() => setEditMode(null)}>
              <Close />
            </div>
          )}
          {isLoading && <Loader />}
          <Form
            disabled={formDisabled}
            form={form}
            style={{ width: "100%", position: "relative" }}
            colon={false}
            name="addProduct"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelWrap
            requiredMark={false}
            validateTrigger="onSubmit"
            scrollToFirstError={true}
            // onFieldsChange={(e) => {
            //     autoSave()
            // }}
            // onValuesChange={autoSave}
          >
            {editMode &&
              (currentProduct.moderationStatus.name === "ACCEPTED" ||
                currentProduct.moderationStatus.name === "PROCESSED") && (
                <EditFormAlert saveAsDraft={() => onFinish(form.getFieldsValue())} />
              )}
            {showStatistics && (
              <>
                <Row gutter={{ xl: 100 }}>
                  <Col span={12} className="mb-18">
                    <p style={{ fontSize: 18, fontWeight: 500 }}>{t("formatDesc")}</p>
                  </Col>
                  {InfoVisualisation()}
                </Row>
                <div style={{ borderBottom: "1px solid #C9D4DB", marginBottom: 25 }}></div>
              </>
            )}
            <Row gutter={{ xl: 100 }}>
              <Col span={12}>
                <p className="fw-500 mb-30 orange">{t("p1")}</p>
                <Form.Item
                  label={
                    <div className={`${`label`} ${`req`}`}>{t("fields.productName.label")}</div>
                  }
                  name="productName"
                  // className={s.no_margin}
                  style={{ marginBottom: 36 }}
                  rules={[
                    {
                      required: true,
                      message: t("fields.productName.errors.required")
                    },
                    {
                      min: 2,
                      message: t("fields.productName.errors.min")
                    },
                    {
                      max: 50,
                      message: t("fields.productName.errors.max")
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length === 0) {
                          return Promise.reject(t("fields.productName.errors.required"));
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    maxLength={50}
                    showCount
                    autoSize={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "productName",
                        form,
                        true,
                        null,
                        null,
                        true,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`} ${`req`}`}>{t("fields.keywords.label")}</div>}
                  name="keywords"
                  // className={s.no_margin}
                  rules={[
                    {
                      min: 2,
                      message: t("fields.keywords.errors.min")
                    },
                    {
                      max: 150,
                      message: t("fields.keywords.errors.max")
                    },
                    customKeyWordsValidator(form, t)
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    maxLength={100}
                    showCount
                    autoSize={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "keywords",
                        form,
                        true,
                        null,
                        null,
                        true,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <p className="fz-12 grey fw300 mb-18">{t("p2")}</p>
                <Form.Item
                  label={
                    <div className={`${`label`} ${`req`}`}>{t("fields.description.label")}</div>
                  }
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: t("fields.description.errors.required")
                    },
                    {
                      min: 30,
                      message: t("fields.description.errors.min")
                    },
                    {
                      max: 700,
                      message: t("fields.description.errors.max")
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length === 0) {
                          return Promise.reject(t("fields.productName.errors.required"));
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                  className={s.margin}
                >
                  <Input.TextArea
                    size="large"
                    maxLength={700}
                    showCount
                    autoSize={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "description",
                        form,
                        true,
                        null,
                        null,
                        true,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <p className="fz-12 grey fw300 mb-18">{t("descriptionHint", { ns: "utils" })}</p>
                <Form.Item
                  className={s.formItem}
                  label={<div className={`${`label`} ${`req`}`}>{t("fields.category.label")}</div>}
                  name={"subcategories"}
                >
                  <CategoriesSelect
                    isValidate={subcategoriesError}
                    translateTitle={"productCategories"}
                    categories={productCategories}
                    translate={"ProductCategories"}
                    node={containerRef}
                    currentSubId={subcategories}
                    setCurrentSubId={setSubcategories}
                  />
                </Form.Item>
                <p className="fz-12 grey fw300 mb-80">{t("p9")}</p>
                <Row>
                  <Col span={20}>
                    <div className="border-grey">
                      <h5>{t("h5")}</h5>
                      <p className="fw-300 mb-12">{t("p3")}</p>
                      <Radio.Group
                        value={videoType}
                        onChange={(e) => {
                          setVideoType(e.target.value);
                        }}
                      >
                        <Space direction="vertical">
                          <Radio disabled={videoLink || videoFileName} value={"youTubeLink"}>
                            {t("radio.youtubeLink")}
                          </Radio>
                          <Radio disabled={videoLink || videoFileName} value={"uploadVideo"}>
                            {t("radio.uploadVideo")}
                          </Radio>
                        </Space>
                      </Radio.Group>

                      {videoType === "youTubeLink" && (
                        <Form.Item
                          className="mt-12"
                          name="youtubeLink"
                          value={videoLink}
                          onChange={(e) => setVideoLink(e.target.value)}
                          rules={[
                            {
                              validator: (_, value) => {
                                if (
                                  value &&
                                  value.trim().length !== 0 &&
                                  !/^(https?\:\/\/)?((www\.)?(youtube\.com|youtu\.be))\/.+$/.test(
                                    value
                                  )
                                ) {
                                  return Promise.reject(t("fields.youtubeLink.errors.correctLink"));
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]}
                        >
                          <Input placeholder={t("fields.youtubeLink.label")} size="large" />
                        </Form.Item>
                      )}

                      {videoType === "uploadVideo" && (
                        <>
                          <br />
                          <div style={{ display: "flex" }}>
                            <label
                              htmlFor="videoInput"
                              className="word-break mt-12 btn btn_v5"
                              style={{ width: "max-content", cursor: "pointer", minHeight: "62px" }}
                            >
                              {!videoFileName && <img src={plusSvg} className="mr-20" />}
                              <span
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {videoFileName || t("buttons.addVideoFile")}
                              </span>
                            </label>
                            <div className={s.trash}>
                              {videoFileName && (
                                <Trash
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setVideoFileName("");
                                    setVideoFileStatus("");
                                    document.getElementById("videoInput").value = "";
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <input
                            accept=".mp4"
                            id="videoInput"
                            type="file"
                            className="d-none"
                            onChange={(e) => {
                              setVideoFileStatusText("");
                              setVideoFileStatus("");
                              setVideoFileName(e.target.files[0]?.name || "");
                              if (e.target.files[0] && e.target.files[0].size > 1073741824) {
                                setVideoFileStatus("error");
                                setVideoFileStatusText(t("fields.File.status.tooBig"));
                              } else if (e.target.files[0]) {
                                setVideoIsUploading(true);
                                setVideoFileStatus("loading");
                                setVideoFileStatusText(t("fields.File.status.loading"));

                                const formData = new FormData();
                                formData.append("file", e.target.files[0]);
                                formData.append("originalname", e.target.files[0].name);
                                uploadsAPI
                                  .uploadVideo(formData)
                                  .then((res) => {
                                    setVideoFileStatus("success");
                                    setVideoFileStatusText(
                                      t("fields.File.status.succesfullyLoaded")
                                    );
                                    setResponseVideoFileName(res.data.filename);
                                  })
                                  .catch((e) => {
                                    // console.log(e);
                                    setVideoFileStatus("failed");
                                    setVideoFileStatusText(t("fields.File.status.uploadFailed"));
                                  })
                                  .finally(() => {
                                    setVideoIsUploading(false);
                                  });
                              }
                            }}
                            disabled={videoFileName}
                          />

                          {videoFileStatus && (
                            <div
                              className={`${
                                videoFileStatus === "error"
                                  ? "red"
                                  : videoFileStatus === "loading"
                                  ? "orange"
                                  : "green"
                              }`}
                            >
                              {videoFileStatusText}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <p className="fw-500 mb-30 orange">{t("p4")}</p>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.advantage1.label")}</div>}
                  name="advantage1"
                  style={{ marginBottom: 36 }}
                  className={s.no_margin}
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    maxLength={100}
                    showCount={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "advantage1",
                        form,
                        true,
                        null,
                        null,
                        false,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.advantage2.label")}</div>}
                  name="advantage2"
                  className={s.no_margin}
                  style={{ marginBottom: 36 }}
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    maxLength={100}
                    showCount={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "advantage2",
                        form,
                        true,
                        null,
                        null,
                        false,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.advantage3.label")}</div>}
                  className={s.no_margin}
                  name="advantage3"
                  style={{ marginBottom: 36 }}
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    maxLength={100}
                    showCount={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "advantage3",
                        form,
                        true,
                        null,
                        null,
                        false,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <div className={`imgWrapper ${imgError ? "border-red" : ""}`}>
                  {localImgUrl ? (
                    <img
                      style={{ height: "80px", width: "80px", objectFit: "contain" }}
                      src={localImgUrl}
                    />
                  ) : (
                    <Img id={"1"} />
                  )}
                  {localImgUrl ? (
                    <Trash
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!formDisabled) {
                          setImgError(false);
                          setImgFileName("");
                          setImgFileStatus("");
                          setLocalImgUrl("");
                          setResponseImgFileName("");
                          imgInputRef.current.value = "";
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <label>
                    <p className="link-heading">{t("fields.photo1.heading") + "*"}</p>
                    <p className={`link`}>{imgFileName || t("fields.photo1.label")}</p>
                    <input
                      disabled={formDisabled}
                      id="mainImg"
                      ref={imgInputRef}
                      className={`file`}
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => onSelectFile(e, "cropLogo", "")}
                    />
                  </label>
                  {imgFileStatus && (
                    <div
                      className={`ml-15 ${
                        imgFileStatus === "error"
                          ? "red"
                          : imgFileStatus === "loading"
                          ? "orange"
                          : "green"
                      }`}
                    >
                      {imgFileStatusText}
                    </div>
                  )}
                </div>
                {imgError && <div className={"red"}>{t("fields.img.errors.required")}</div>}
                <p className="fz-12 grey fw300 mb-45">
                  {t("p5_1")}
                  <br />
                  {t("p5_2")}
                </p>
                <p className="fw-500 mb-20">{t("p6")}</p>
                <div className={`imgWrapper`}>
                  {additionalImgs.addImg.localImgUrl ? (
                    <img
                      style={{ height: "80px", width: "80px", objectFit: "contain" }}
                      src={additionalImgs.addImg.localImgUrl}
                    />
                  ) : (
                    ""
                  )}
                  {/*<Img/>*/}
                  {additionalImgs.addImg.localImgUrl ? (
                    <Trash
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!formDisabled) {
                          imgInputRef2.current.value = "";
                          let activeCount = 0;
                          Object.values(additionalImgs).forEach((el) => {
                            if (el) ++activeCount;
                          });

                          if (activeCount === 2) {
                            setAdditionalImgs({
                              addImg: {
                                localImgUrl: "",
                                fileName: "",
                                fileStatus: "",
                                statusText: "",
                                responseFileName: ""
                              }
                            });
                          } else {
                            setAdditionalImgs((prev) => ({
                              ...prev,
                              addImg: {
                                localImgUrl: "",
                                fileName: "",
                                fileStatus: "",
                                statusText: "",
                                responseFileName: ""
                              }
                            }));
                          }
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {additionalImgs.addImg.localImgUrl ? (
                    <label>
                      <p className="link-heading">{t("fields.photo2.heading")}</p>
                      <p className={`link`}>
                        {additionalImgs.addImg.fileName || t("fields.photo1.label")}
                      </p>
                      <input
                        disabled={formDisabled}
                        ref={imgInputRef2}
                        className={`file`}
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={(e) => {
                          setAdditionalImgs((prev) => ({
                            ...prev,
                            addImg: {
                              localImgUrl: "",
                              fileName: "",
                              fileStatus: "",
                              statusText: "",
                              responseFileName: ""
                            }
                          }));

                          if (e.target.files[0].size < 50000000) {
                            let img = new Image();
                            img.src = window.URL.createObjectURL(e.target.files[0]);
                            img.onload = function () {
                              setAdditionalImgs((prev) => ({
                                ...prev,
                                addImg: {
                                  localImgUrl: URL.createObjectURL(e.target.files[0]),
                                  fileName: e.target.files[0]?.name,
                                  fileStatus: "loading",
                                  statusText: t("fields.File.status.loading"),
                                  responseFileName: ""
                                }
                              }));
                              const formData = new FormData();
                              formData.append("file", e.target.files[0]);
                              formData.append("originalname", e.target.files[0].name);
                              uploadsAPI
                                .uploadImage(formData)
                                .then((res) => {
                                  setAdditionalImgs((prev) => ({
                                    ...prev,
                                    addImg: {
                                      localImgUrl: URL.createObjectURL(e.target.files[0]),
                                      fileName: e.target.files[0]?.name,
                                      fileStatus: "success",
                                      statusText: t("fields.File.status.succesfullyLoaded"),
                                      responseFileName: res.data.filename
                                    }
                                  }));
                                  if (Object.keys(additionalImgs).length === 1) {
                                    setAdditionalImgs((prev) => ({
                                      ...prev,
                                      ["addImg" + Object.keys(prev).length]: {
                                        localImgUrl: "",
                                        fileName: "",
                                        fileStatus: "",
                                        statusText: "",
                                        responseFileName: ""
                                      }
                                    }));
                                  }
                                })
                                .catch((e) => {
                                  setAdditionalImgs((prev) => ({
                                    ...prev,
                                    addImg: {
                                      localImgUrl: URL.createObjectURL(e.target.files[0]),
                                      fileName: e.target.files[0]?.name,
                                      fileStatus: "failed",
                                      statusText: t("fields.File.status.uploadFailed"),
                                      responseFileName: ""
                                    }
                                  }));
                                });
                            };
                          } else {
                            setShowImgErrorModal(true);
                            let activeCount = 0;
                            Object.values(additionalImgs).forEach((el) => {
                              if (el) ++activeCount;
                            });

                            if (activeCount === 2) {
                              setAdditionalImgs({
                                addImg: {
                                  localImgUrl: "",
                                  fileName: "",
                                  fileStatus: "",
                                  statusText: "",
                                  responseFileName: ""
                                }
                              });
                            }
                          }
                        }}
                      />
                    </label>
                  ) : (
                    ""
                  )}
                  {additionalImgs.addImg.fileStatus && (
                    <div
                      className={`ml-15 ${
                        additionalImgs.addImg.fileStatus === "error"
                          ? "red"
                          : additionalImgs.addImg.fileStatus === "loading"
                          ? "orange"
                          : "green"
                      }`}
                    >
                      {additionalImgs.addImg.statusText}
                    </div>
                  )}
                </div>
                {drawImgInputs(
                  additionalImgs,
                  setAdditionalImgs,
                  t,
                  "1",
                  setShowImgErrorModal,
                  onSelectFile
                )}
                {Object.keys(additionalImgs).length === 1 &&
                additionalImgs?.addImg?.statusText === "" &&
                !!!Object.values(additionalImgs).find((f) => f !== null && f.responseFileName) ? (
                  <div onClick={handlePickImgInputRef3} className={`imgWrapper`}>
                    <Img id={1} />
                    <label>
                      <p className="link-heading">{t("fields.photo2.heading")}</p>
                      <p className={`link`}>{t("fields.photo1.label")}</p>
                    </label>
                    <input
                      id={"additionalPhoto"}
                      ref={imgInputRef3}
                      accept=".jpg,.jpeg,.png"
                      disabled={formDisabled}
                      className={`file`}
                      type="file"
                      onChange={(e) => {
                        setAdditionalImgs((prev) => ({
                          ...prev,
                          addImg: {
                            localImgUrl: "",
                            fileName: "",
                            fileStatus: "",
                            statusText: "",
                            responseFileName: ""
                          }
                        }));
                        if (e.target.files[0].size < 50000000) {
                          onSelectFile(e, "cropAdditionalImg", "");
                        } else {
                          setShowImgErrorModal(true);
                          let activeCount = 0;
                          Object.values(additionalImgs).forEach((el) => {
                            if (el) ++activeCount;
                          });

                          if (activeCount === 2) {
                            setAdditionalImgs({
                              addImg: {
                                localImgUrl: "",
                                fileName: "",
                                fileStatus: "",
                                statusText: "",
                                responseFileName: ""
                              }
                            });
                          }
                        }
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                <p className="fz-12 grey fw300 mb-95">{t("p7")}</p>
              </Col>
              <Col span={24}>
                <h2 className="mt-50 mb-24">{t("h2")}</h2>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<div className={`${`label`} ${`req`}`}>{t("fields.unitPrice.label")}</div>}
                  name="unitPrice"
                  // value={unitPrice}
                  onChange={(e) => {
                    form.setFields([{ name: "unitPrice", errors: [] }]);
                  }}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value && value > 0) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(t("fields.unitPrice.errors.required"));
                        }
                      }
                    }
                  ]}
                >
                  <Input type="number" step="0.01" size="large" />
                </Form.Item>
                <Form.Item
                  label={
                    <div className={`${`label`} ${`req`}`}>{t("fields.fobUnitPrice.label")}</div>
                  }
                  name="fobUnitPrice"
                  // value={fobUnitPrice}
                  // onChange={(e) => setFobUnitPrice(e.target.value)}
                  onChange={(e) => {
                    form.setFields([{ name: "fobUnitPrice", errors: [] }]);
                  }}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value && value > 0) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(t("fields.fobUnitPrice.errors.required"));
                        }
                      }
                    }
                  ]}
                >
                  <Input type="number" step="0.01" size="large" />
                </Form.Item>
                <Form.Item
                  name="currency"
                  label={<span className={`${`label`} ${`req`}`}>{t("currencies.label")}</span>}
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 17 }}
                  rules={[
                    {
                      required: true,
                      message: t("currencies.errors.required")
                    }
                  ]}
                  validateTrigger={"onChange"}
                >
                  <Select
                    onDropdownVisibleChange={(state) => {
                      setOpenState({ ...initialState, currency: state });
                    }}
                    // onChange={(val) => {
                    //   handleCurrencySelect(val);
                    // }}
                    open={openState.currency}
                    placeholder={t("fields.select.placeholder")}
                    size="large"
                    suffixIcon={
                      <DropdownIcon
                        onClick={() => {
                          setOpenState((prevState) => ({
                            ...initialState,
                            currency: !prevState.currency
                          }));
                        }}
                      />
                    }
                  >
                    {currencies.length &&
                      currencies.map((cat) => (
                        <Select.Option key={cat.id} value={cat.id}>
                          {cat.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.minimalAmount.label")}</div>}
                  name="minimalAmount"
                  // value={minimalAmount}
                  // onChange={(e) => setMinimalAmount(Number(e.target.value))}
                >
                  <Input type="number" size="large" />
                </Form.Item>

                <Form.Item
                  name="deliveryUnitId"
                  label={<span className={`${`label`} ${`req`}`}>{t("deliveryUnitId.label")}</span>}
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 17 }}
                  rules={[
                    {
                      required: true,
                      message: t("deliveryUnitId.errors.required")
                    }
                  ]}
                  validateTrigger={"onChange"}
                >
                  <Select
                    onDropdownVisibleChange={(state) => {
                      setOpenState({ ...initialState, deliveryUnitId: state });
                    }}
                    // onChange={(val) => {
                    //   handleDeliveryUnitIdSelect(val);
                    // }}
                    open={openState.deliveryUnitId}
                    placeholder={t("fields.select.placeholder")}
                    size="large"
                    suffixIcon={
                      <DropdownIcon
                        onClick={() => {
                          setOpenState((prevState) => ({
                            ...initialState,
                            deliveryUnitId: !prevState.deliveryUnitId
                          }));
                        }}
                      />
                    }
                  >
                    {deliveryUnits.length &&
                      deliveryUnits.map((cat) => (
                        <Select.Option key={cat.id} value={cat.id}>
                          {t(`deliveryUnitId.${cat.name}`)}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="deliveryTimeId"
                  label={<span className={`${`label`} ${`req`}`}>{t("deliveryTimeId.label")}</span>}
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 17 }}
                  rules={[
                    {
                      required: true,
                      message: t("deliveryTimeId.errors.required")
                    }
                  ]}
                  validateTrigger={"onChange"}
                >
                  <Select
                    onDropdownVisibleChange={(state) => {
                      setOpenState({ ...initialState, deliveryTimeId: state });
                    }}
                    // onChange={(val) => {
                    //   handleDeliveryTimeIdSelect(val);
                    // }}
                    open={openState.deliveryTimeId}
                    placeholder={t("fields.select.placeholder")}
                    size="large"
                    suffixIcon={
                      <DropdownIcon
                        onClick={() => {
                          setOpenState((prevState) => ({
                            ...initialState,
                            deliveryTimeId: !prevState.deliveryTimeId
                          }));
                        }}
                      />
                    }
                  >
                    {deliveryTime.length &&
                      deliveryTime.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {t(`${d.name}`, { ns: "DeliveryTime" })}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <div className={`${`label`}`}>{t("fields.availableCertificates.label")}</div>
                  }
                  name="availableCertificates"
                >
                  <Input.TextArea
                    size="large"
                    autoSize={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "availableCertificates",
                        form,
                        true,
                        null,
                        null,
                        false,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.deliveryAddress.label")}</div>}
                  name="deliveryAddress"
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "deliveryAddress",
                        form,
                        true,
                        null,
                        null,
                        false,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div className={`${s.bigLabel} ${`label`}`}>
                      {t("fields.productiveCapacity.label")}
                    </div>
                  }
                  name="productiveCapacity"
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "productiveCapacity",
                        form,
                        true,
                        null,
                        null,
                        false,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div className={`${`label`}`}>
                      {t("fields.requirementsPackingDetails.label")}
                    </div>
                  }
                  name="requirementsPackingDetails"
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "requirementsPackingDetails",
                        form,
                        true,
                        null,
                        null,
                        false,
                        autoSave
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className={`mt-50 ${s.paymentRow}`}>
              <Col span={8}>
                <h5>{t("h5_2") + "*"}</h5>
                <p className="fz-12 grey fw300 mb-24">{t("p8")}</p>

                {drawPayMethods()}
                {paymentChosenError && (
                  <div className={"red"}>{t("fields.paymentMethods.errors.required")}</div>
                )}
              </Col>
              <Col span={16}>
                <h5>{t("h5_3") + "*"}</h5>
                <p className="fz-12 grey fw300 mb-24">{t("p8")}</p>
                <div style={{ display: "flex", flexWrap: "wrap" }}>{drawIncoterms()}</div>

                {inctermsChosenError && (
                  <div className={"red"}>{t("fields.incoterms.errors.required")}</div>
                )}
              </Col>
            </Row>
            {showButtonsAndStatus && (
              <Row>
                <Col span={24}>
                  <div
                    style={
                      !isAdmin
                        ? {
                            marginLeft: "auto",
                            marginBottom: "15px",
                            display: "flex",
                            justifyContent: "flex-end"
                          }
                        : {
                            display: "flex",
                            justifyContent: "center"
                          }
                    }
                  >
                    {editMode && (
                      <Button
                        className={`submit ml-auto`}
                        type="default"
                        size="large"
                        style={{ flex: "1 1 0", width: 0, position: "relative", zIndex: 3 }}
                        onClick={checkIfFormWasChanged}
                      >
                        {t("buttons.leaveWithoutSaving")}
                      </Button>
                    )}
                    {!admin && (
                      <Button
                        style={{
                          flex: "1 1 0",
                          width: 0,
                          marginLeft: 15,
                          position: "relative",
                          zIndex: 3
                        }}
                        className={`submit ml-auto`}
                        type="default"
                        size="large"
                        onClick={(e) => {
                          checkImgError();
                          checkPaymentIsChosen();
                          checkInctermsIsChosen();
                          checkSubcategoriesError();
                          form
                            .validateFields()
                            .then((res) => {
                              if (videoIsUploading) {
                                setModalMessage(t("responses.waitForVideoToUpload"));
                                setModalIsVisible(true);
                                return;
                              }
                              if (!responseImgFileName) {
                                document.getElementById("mainImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              if (!subcategories.length) {
                                document.getElementById("test").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              // eslint-disable-next-line no-empty
                              if (!paymentChosen || !inctermsChosen) {
                                return;
                              }
                              setShowSendForModerationInPreview(false);
                              setShowPreview(true);
                            })
                            .catch((e) => {
                              if (!responseImgFileName) {
                                document.getElementById("mainImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              } else {
                                const errorFieldId =
                                  e.errorFields[0].name[0] === "subcategories"
                                    ? `#addProduct_${e.errorFields[0].name[0]}_0`
                                    : `#addProduct_${e.errorFields[0].name[0]}`;
                                document.querySelector(`${errorFieldId}`).scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              }
                            });
                        }}
                      >
                        {t("buttons.preview")}
                      </Button>
                    )}
                    <Button
                      className={`submit ml-auto`}
                      type="default"
                      size="large"
                      style={
                        !isAdmin
                          ? { flex: "1 1 0", width: 0, marginLeft: 15, flexWrap: "wrap" }
                          : { width: "maxContent", marginLeft: 15, flexWrap: "wrap" }
                      }
                      onClick={() => {
                        if (isAdmin) {
                          checkImgError();
                          checkPaymentIsChosen();
                          checkInctermsIsChosen();
                          checkSubcategoriesError();
                          form
                            .validateFields()
                            .then((res) => {
                              if (videoIsUploading) {
                                setModalMessage(t("responses.waitForVideoToUpload"));
                                setModalIsVisible(true);
                                return;
                              }
                              if (!responseImgFileName) {
                                document.getElementById("mainImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              if (!subcategories.length) {
                                document.getElementById("test").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              // eslint-disable-next-line no-empty
                              if (!paymentChosen || !inctermsChosen) {
                                return;
                              }
                              setShowSendForModerationInPreview(true);
                              setShowPreview(true);
                            })
                            .catch((e) => {
                              if (!responseImgFileName) {
                                document.getElementById("mainImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              } else {
                                const errorFieldId =
                                  e.errorFields[0].name[0] === "subcategories"
                                    ? `#addProduct_${e.errorFields[0].name[0]}_0`
                                    : `#addProduct_${e.errorFields[0].name[0]}`;
                                document.querySelector(`${errorFieldId}`).scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              }
                            });
                        } else {
                          onFinish(form.getFieldsValue());
                        }
                      }}
                    >
                      {t("buttons.save")}
                    </Button>
                    {!isAdmin && (
                      <Button
                        style={{ flex: "1 1 0", width: 0, marginLeft: 15, flexWrap: "wrap" }}
                        className={`btn btn_v2 ml-auto`}
                        type="default"
                        size="large"
                        onClick={(e) => {
                          checkImgError();
                          checkPaymentIsChosen();
                          checkInctermsIsChosen();
                          checkSubcategoriesError();
                          form
                            .validateFields()
                            .then((res) => {
                              if (videoIsUploading) {
                                setModalMessage(t("responses.waitForVideoToUpload"));
                                setModalIsVisible(true);
                                return;
                              }
                              if (!responseImgFileName) {
                                document.getElementById("mainImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              if (!subcategories.length) {
                                document.getElementById("test").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              // eslint-disable-next-line no-empty
                              if (!paymentChosen || !inctermsChosen) {
                                return;
                              }
                              setShowSendForModerationInPreview(true);
                              setShowPreview(true);
                            })
                            .catch((e) => {
                              if (!responseImgFileName) {
                                document.getElementById("mainImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              } else {
                                const errorFieldId =
                                  e.errorFields[0].name[0] === "subcategories"
                                    ? `#addProduct_${e.errorFields[0].name[0]}_0`
                                    : `#addProduct_${e.errorFields[0].name[0]}`;
                                document.querySelector(`${errorFieldId}`).scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              }
                            });
                        }}
                      >
                        {t("buttons.sendForModeration", { ns: "FS10-AddNewProduct" })}
                      </Button>
                    )}
                  </div>
                </Col>
                {productStatus.text && (
                  <>
                    <Col span={10}></Col>
                    <Col span={14}>
                      <p className={productStatus.className} style={{ textAlign: "right" }}>
                        {t(productStatus.text, {
                          ns: "FS10-AddNewProduct"
                        })}
                      </p>
                    </Col>
                  </>
                )}
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );

  function checkIfFormWasChanged() {
    const currentFormState = form.getFieldsValue();
    const lastStateOfItem = currentProduct.moderations || currentProduct;

    const { LC, DA, DP, TT, WU, MG, OTHER } = currentFormState;
    const payMethods = { LC, DA, DP, TT, WU, MG, OTHER };
    const payMethodsArr = Object.keys(payMethods).filter((el) => payMethods[el]);

    const { CFR, DAF, DES, FOB, CPT, DEQ, FCA, CIP, DDU, FAS, CIF, DDP, EXW } = currentFormState;
    const incoterms = { CFR, DAF, DES, FOB, CPT, DEQ, FCA, CIP, DDU, FAS, CIF, DDP, EXW };
    const incotermsArr = Object.keys(incoterms).filter((el) => incoterms[el]);
    if (
      lastStateOfItem.productName === currentFormState.productName &&
      lastStateOfItem.productKeywords.length ===
        currentFormState.keywords
          .split(",")
          .map((el) => el.trim())
          .filter((el) => el.length > 0).length &&
      lastStateOfItem.productKeywords.map((k) => k.keyword).join("") ===
        currentFormState.keywords
          .split(",")
          .map((el) => el.trim())
          .join("") &&
      lastStateOfItem.description === currentFormState.description &&
      lastStateOfItem.subcategories.length === currentFormState.subcategories.length &&
      lastStateOfItem.subcategories
        .map((el) => el.id)
        .sort()
        .join("") === currentFormState.subcategories.sort().join("") &&
      (lastStateOfItem.video === currentFormState.youtubeLink ||
        (videoType === "uploadVideo" && lastStateOfItem.video === videoFileName)) &&
      lastStateOfItem.advantage1 === currentFormState.advantage1 &&
      lastStateOfItem.advantage2 === currentFormState.advantage2 &&
      lastStateOfItem.advantage3 === currentFormState.advantage3 &&
      lastStateOfItem.image === imgFileName &&
      Object.values(lastStateOfItem.productPhotos)
        .map((i) => i.photo)
        .join("") ===
        Object.values(additionalImgs)
          .map((i) => i.fileName)
          .join("") &&
      lastStateOfItem.unitPrice / 100 === Number(currentFormState.unitPrice) &&
      lastStateOfItem.fobUnitPrice / 100 === Number(currentFormState.fobUnitPrice) &&
      lastStateOfItem.minimalAmount === Number(currentFormState.minimalAmount) &&
      lastStateOfItem.deliveryUnitId === currentFormState.deliveryUnitId &&
      lastStateOfItem.deliveryTimeId === currentFormState.deliveryTimeId &&
      lastStateOfItem.availableCertificates === currentFormState.availableCertificates &&
      lastStateOfItem.deliveryAddress === currentFormState.deliveryAddress &&
      lastStateOfItem.productiveCapacity === currentFormState.productiveCapacity &&
      lastStateOfItem.requirementsPackingDetails === currentFormState.requirementsPackingDetails &&
      lastStateOfItem.paymentMethods.length === payMethodsArr.length &&
      lastStateOfItem.paymentMethods
        .map((el) => el.name)
        .sort()
        .join("") === payMethodsArr.sort().join("") &&
      lastStateOfItem.incoterms.length === incotermsArr.length &&
      lastStateOfItem.incoterms
        .map((el) => el.name)
        .sort()
        .join("") === incotermsArr.sort().join("")
    ) {
      setEditMode(null);
    } else {
      setShowExitWithoutSavingModal(true);
    }
  }
  function validateAndSave() {
    checkImgError();
    checkPaymentIsChosen();
    checkInctermsIsChosen();
    checkSubcategoriesError();
    return form
      .validateFields()
      .then((res) => {
        if (videoIsUploading) {
          setModalMessage(t("responses.waitForVideoToUpload"));
          setModalIsVisible(true);
          return Promise.reject();
        }
        if (!responseImgFileName) {
          document.getElementById("mainImg").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
          return Promise.reject();
        }
        if (!subcategories.length) {
          document.getElementById("test").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
          return Promise.reject();
        }
        // eslint-disable-next-line no-empty
        if (!paymentChosen || !inctermsChosen) {
          return Promise.reject();
        }
        onFinish(form.getFieldsValue(), true, false, false);
      })
      .catch((e) => {
        if (!responseImgFileName) {
          document.getElementById("mainImg").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        } else {
          const errorFieldId =
            e.errorFields[0].name[0] === "subcategories"
              ? `#addProduct_${e.errorFields[0].name[0]}_0`
              : `#addProduct_${e.errorFields[0].name[0]}`;
          document.querySelector(`${errorFieldId}`).scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        }
        return Promise.reject();
      });
  }
});
