import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {mediaUrl, productsAPI, serviceAPI} from "../../api/api";
import s from './ShowcaseSelection.module.less'
import style from '../FK3-сompanyProfile/About/ProductItem/ProductItem.module.less'
import {Tooltip} from "antd";
import {setShowcase} from "../../store/changeCompanyProfileSlice";


const ExampleItem = ({id}) => {
  const dispatch = useDispatch();
  const {showcase} = useSelector((state) => state.changeCompanyProfile.customProfile);

  const [data, setData] = useState({});

  useEffect(() => {
    if (!showcase[data.id]) {
      setData({})
    }
  }, [showcase])

  useEffect(() => {
    if (showcase[id]) {
      if (showcase[id].entityType === 'product') {
        productsAPI
          .getProductId(showcase[id].entityId)
          .then((res) => {
            setData(res.data);
          })
          .catch((e) => {
            // console.log(e.response);
          });
      } else if (showcase[id].entityType === 'service') {
        serviceAPI.getServiceById(showcase[id].entityId).then((res) => {
          setData(res.data);
        }).catch((e) => {
          // console.log(e.response);
        });
      }
    }
  }, [id, showcase]);

  const deleteItem = () => {
    if (data.id) dispatch(setShowcase(showcase.filter(f => f.entityId !== data.id)))
  }

  const option = () => {
    if (data && data?.productName) {
      return <div className={`${s.wrapperItem}`}>
        <div className={s.img}>
          <img
            src={`${mediaUrl}${data.image}`}
            className={s.img}
            alt={data.productName}
          />
        </div>
        <div className={style.flex}>
          <div className={style.title} style={{maxWidth: "100%"}}>
            {data.productName.slice(0, 13)}
            {data.productName.length > 13 ? (
              <Tooltip placement="topLeft" title={data.productName} arrowPointAtCenter>
                ...
              </Tooltip>
            ) : null}
          </div>
          <div>
            {data.unitPrice / 100} {data.currency.name}
          </div>
        </div>
      </div>
    } else if (data && data?.serviceName) {
      return <div className={`${s.wrapperItem}`}>
        <div className={s.img}>
          <img
            src={`${mediaUrl}${data.image}`}
            className={s.img}
            alt={data.serviceName}
          />
        </div>
        <div className={style.flex}>
          <div className={style.title} style={{maxWidth: "100%"}}>
            {data.serviceName.slice(0, 20)}
            {data.serviceName.length > 20 ? (
              <Tooltip placement="topLeft" title={data.serviceName} arrowPointAtCenter>
                ...
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    } else {
      return <div className={showcase.length === id ? `${s.plusItem} ${s.chosen}` : s.plusItem}><Plus/></div>
    }
  }


  return (
    <div onClick={deleteItem}>
      {option()}
    </div>
  );
};


const Plus = (props) => (
  <svg
    width={26}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={11} width={4} height={26} rx={2} fill="#343543"/>
    <rect
      y={15}
      width={4}
      height={26}
      rx={2}
      transform="rotate(-90 0 15)"
      fill="#343543"
    />
  </svg>
)


export default ExampleItem;
