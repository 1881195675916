/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Paginator from "../Paginator/Paginator";
import styles from "./exhibition-table.module.less";
import ExhibitionTableRow from "./ExhibitionTableRow/ExhibitionTableRow";
import { Form } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CreateExhibitionPanel from "../CreateExhibitionPanel";
import { useEffect } from "react";
import { useState } from "react";
import { exhibitionAPI } from "../../api/api";
import { useLocation } from "react-router-dom";
import { notificationsFeedAPI } from "../../api/api";
import { setExhibitionInitialValues } from "../../store/exhibitionSlice";
import { useDispatch } from "react-redux";

const ExhibitionTable = ({
  handleOpenModal,
  tableData,
  sorting = {},
  current,
  pageSize,
  onChange,
  handleSort = () => {},
  total,
  setStandInfo,
  form,
  onFinish,
  clearImage,
  passageDiagram,
  setPassageDiagram,
  setExhibitionForDelete,
  isArchive = false,
  showCreateExhibitionPanel = false,
  fetchExhibitionData,
  setCurrentItem = () => {},
  setIsImageDeleted,
  isImageDeleted,
  closePanel,
  handleEdit,
  savedEditData,
  prevSavedEditData
}) => {
  const dispatch = useDispatch();
  const { editStandId, editExhibitionId, isEditMode, exhibitionInitialValues } = useSelector(
    (state) => state.exhibitions
  );
  const { isAdmin } = useSelector((state) => state.auth);
  const [editindExhibition, setEditingExhibition] = useState();
  const [editingStand, setEditingStand] = useState();
  const [errors, setErrors] = useState([]);
  const [standDescription, setStandDescription] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const [creatorData, setCreatorData] = useState({});
  const isUserExhibition =
    user.id === creatorData?.creator?.id && creatorData?.creatorType !== "admin";

  const unreadExhibition =
    tableData.length && tableData.filter((obj) => obj.act).map((obj) => obj.id);
  const standArray = tableData.length && tableData.map((exhibition) => exhibition?.stands?.[0]);
  const unreadStand =
    tableData.length && standArray?.filter((obj) => obj?.act).map((obj) => obj.id);

  useEffect(() => {
    if (unreadExhibition.length && !isAdmin) {
      notificationsFeedAPI.markNotificationsAsReadNew(unreadExhibition, "exhibition");
    }
    if (unreadStand.length) {
      notificationsFeedAPI.markNotificationsAsReadNew(unreadStand, "stand");
    }
  }, [unreadExhibition.length, unreadStand.length]);

  useEffect(() => {
    if (Object.keys(exhibitionInitialValues).length > 0 && !isArchive) {
      form.setFieldsValue({ ...exhibitionInitialValues });
    }
  }, [exhibitionInitialValues]);

  const unsetFromPlatfrom = async (findEditingExhibition) => {
    const isBA1Page = pathname.includes("edit-and-view-exhibition");
    let findStandEditing = {};
    if (!isBA1Page) {
      findStandEditing = findEditingExhibition.stands.find((stand) => stand.id === editStandId);
    }

    const { boothNumber, description, isVisible, passageDiagram } = findStandEditing;
    const { areaName, city, countryId, endDate, exhibitionName, isPrivate, startDate, website } =
      findEditingExhibition;

    const exhibitionValue = {
      areaName: areaName,
      description: description,
      city: city,
      countryId: countryId,
      startDate: startDate,
      endDate: endDate,
      exhibitionName: exhibitionName,
      times: findEditingExhibition.times?.map((t) => {
        return {
          startTime: t?.startTime,
          endTime: t?.endTime
        };
      }),
      isPrivate: isPrivate,
      website: website
    };

    const standValue = {
      boothNumber,
      description,
      isVisible,
      passageDiagram
    };
    if (isBA1Page) {
      exhibitionValue.description = findEditingExhibition.description;
    }

    if (!passageDiagram) {
      standValue.passageDiagram = "";
    }

    if (isUserExhibition || (isAdmin && !pathname.includes("/admin-panel/users-list"))) {
      await exhibitionAPI.editExhibitionById(editExhibitionId, {
        ...exhibitionValue,
        sendModeration: false
      });
    }
    if (!isBA1Page) {
      await exhibitionAPI.editStandById(editStandId, { ...standValue, sendModeration: false });
    }
    fetchExhibitionData();
  };

  useEffect(() => {
    if (isEditMode) {
      const findEditingExhibition = tableData.find((item) => item.id === editExhibitionId);
      const creator = findEditingExhibition?.creator;
      const creatorType = findEditingExhibition?.creatorType;
      setCreatorData({ creator, creatorType });
      setEditingExhibition(findEditingExhibition);
      (!savedEditData || prevSavedEditData?.exhibitionId !== savedEditData.exhibitionId) &&
        unsetFromPlatfrom(findEditingExhibition);
    }
  }, [isEditMode, editExhibitionId]);

  useEffect(() => {
    if (!isArchive) {
      const findEditingStand = editindExhibition?.stands?.find((stand) => stand.id === editStandId);
      setEditingStand(findEditingStand);
      form.setFieldsValue({
        exhibitionName: editindExhibition?.exhibitionName,
        website: editindExhibition?.website,
        city: editindExhibition?.city,
        areaName: editindExhibition?.areaName,
        exhibition: {
          countryId: editindExhibition?.country?.id,
          startDate: dayjs(editindExhibition?.startDate),
          endDate: dayjs(editindExhibition?.endDate),
          isPrivate: editindExhibition?.isPrivate
        },
        boothNumber: editingStand?.boothNumber,
        description: editingStand?.description || editindExhibition?.description,
        stand: {
          passageDiagram: editingStand?.passageDiagram,
          isVisible: editingStand?.isVisible
        }
      });
      dispatch(
        setExhibitionInitialValues({
          exhibitionName: editindExhibition?.exhibitionName,
          website: editindExhibition?.website,
          city: editindExhibition?.city,
          areaName: editindExhibition?.areaName,
          exhibition: {
            countryId: editindExhibition?.country?.id,
            startDate: dayjs(editindExhibition?.startDate),
            endDate: dayjs(editindExhibition?.endDate),
            isPrivate: editindExhibition?.isPrivate
          },
          boothNumber: editingStand?.boothNumber,
          description: editingStand?.description || editindExhibition?.description,
          stand: {
            passageDiagram: editingStand?.passageDiagram,
            isVisible: editingStand?.isVisible
          }
        })
      );
      setStandDescription(editingStand?.description);
    }
  }, [editindExhibition, isEditMode, editStandId]);

  useEffect(() => {
    if (!isArchive || pathname.includes("/admin-panel/users-list")) {
      form.setFieldsValue({
        boothNumber: editingStand?.boothNumber,
        description: editingStand?.description,
        stand: {
          passageDiagram: editingStand?.passageDiagram,
          isVisible: editingStand?.isVisible
        }
      });
    }
  }, [editingStand, isEditMode]);
  const handleErrors = (errorFields) => {
    errorFields.forEach(({ name }) => {
      const errorField = name.at(-1);
      setErrors((prevErrors) => {
        return { ...prevErrors, [errorField]: true };
      });
    });
  };

  return (
    <>
      <section
        id="wrapper"
        className={styles.wrapper}
        style={{
          display:
            !isAdmin && tableData.length === 0 && !showCreateExhibitionPanel ? "none" : "block",
          // minWidth: "94.34%"
          maxWidth: "1743px"        }}
      >
        <section className={styles.scrollable} style={{ minWidth: "auto" }}>
          {tableData?.map((item, index) => {
            return (
              <Form
                key={`${item.id}_${index}`}
                form={form}
                labelAlign="left"
                onFinish={onFinish}
                onFinishFailed={({ errorFields }) => handleErrors(errorFields)}
                scrollToFirstError
                onClick={() => setCurrentItem(item)}
                name="edtiExhibitionFormTable"
                autoComplete="off"
                requiredMark={false}
                validateTrigger="onSubmit"
                initialValues={{
                  ...exhibitionInitialValues
                }}
              >
                <ExhibitionTableRow
                  item={item}
                  isAdmin={isAdmin}
                  isFirstElement={index === 0}
                  handleOpenModal={handleOpenModal}
                  sorting={sorting}
                  handleSort={handleSort}
                  form={form}
                  setStandInfo={setStandInfo}
                  clearImage={clearImage}
                  passageDiagram={passageDiagram}
                  setPassageDiagram={setPassageDiagram}
                  errors={errors}
                  isArchive={isArchive}
                  standDescription={standDescription}
                  setExhibitionForDelete={setExhibitionForDelete}
                  setIsImageDeleted={setIsImageDeleted}
                  isImageDeleted={isImageDeleted}
                  isUserExhibition={isUserExhibition}
                  handleEdit={handleEdit}
                />
              </Form>
            );
          })}
          {!isAdmin && !isArchive && (
            <div style={{ display: showCreateExhibitionPanel ? "block" : "none" }}>
              <CreateExhibitionPanel
                num={tableData.length + 1}
                showCreateExhibitionPanel={showCreateExhibitionPanel}
                fetchData={fetchExhibitionData}
                closePanel={closePanel}
              />
            </div>
          )}
        </section>
      </section>
      <Paginator
        pagination={{ current, pageSize }}
        setCurrentPage={onChange}
        total={total}
        style={{ justifyContent: "center", marginTop: 24 }}
      />
    </>
  );
};

ExhibitionTable.propTypes = {
  handleOpenModal: PropTypes.func,
  tableData: PropTypes.array,
  lang: PropTypes.string,
  t: PropTypes.func,
  sorting: PropTypes.object,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  setStandInfo: PropTypes.func,
  handleSort: PropTypes.func,
  onFinish: PropTypes.func,
  form: PropTypes.any,
  clearImage: PropTypes.func,
  passageDiagram: PropTypes.string,
  setPassageDiagram: PropTypes.func
};

export default ExhibitionTable;
