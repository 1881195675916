/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { getCountries } from "../../store/resourcesSlice";
import s from "./ExhibitionPreview.module.less";
import { Col, Image, Row } from "antd";
import { mediaUrl } from "../../api/api";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const ExhibitionPreview = ({ exhibition, isModal = false, isImageDeleted = false }) => {
  const { t } = useTranslation(["ExhibitionPreview", "Countries"]);
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.resources);

  useEffect(() => {
    if (!countries.length) {
      dispatch(getCountries());
    }
  }, []);

  const convertDate = (date) => {
    return date.replaceAll("-", ".");
  };

  const country = useMemo(() => {
    return countries.length > 0 && exhibition.countryId
      ? countries.find((c) => c.id === exhibition.countryId).name
      : undefined;
  }, [countries, exhibition]);

  return (
    <div className={s.preview} style={{ fontWeight: isModal ? 400 : "unset" }}>
      <div className={s.flex}>
        <div className={s.left}>{t("name")}: </div>
        <div>{exhibition?.exhibitionName}</div>
      </div>
      <div className={s.flex}>
        <div className={s.left} style={{ minWidth: 200 }}>
          {t("site")}:{" "}
        </div>
        <div style={{ wordBreak: "break-all" }}>
          {exhibition?.website ? (
            <a
              className={"orange blue_hover"}
              href={exhibition?.website}
              target={"_blank"}
              rel="noreferrer"
            >
              {exhibition?.website}
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={s.flex}>
        <div className={s.left}>{t("country")}: </div>
        <div>{country && t(country, { ns: "Countries" })}</div>
      </div>
      <div className={s.flex}>
        <div className={s.left}>{t("city")}: </div>
        <div>{exhibition?.city}</div>
      </div>
      <div className={s.flex}>
        <div className={s.left}>{t("platform")}: </div>
        <div>{exhibition?.areaName}</div>
      </div>
      <div className={s.flex}>
        <div className={s.left}>{t("startDate")}: </div>
        <div>{convertDate(exhibition.startDate)}</div>
      </div>
      <div className={s.flex}>
        <div className={s.left}>{t("endDate")}: </div>
        <div>{convertDate(exhibition.endDate)}</div>
      </div>
      <div className={s.flex}>
        <div className={s.left}>{t("workingHours")}: </div>
        <div>
          {exhibition?.times.length > 0 &&
            exhibition?.times.map((t, i) => (
              <p key={`${i + 1}_${i}`} style={{ fontWeight: isModal ? 400 : "unset" }}>
                {convertDate(t.startDate.replace(/\d{2}:\d{2}:\d{2}/, ""))}:{" "}
                {t.timeRange ? t.timeRange[i].startTime : t.startTime} -{" "}
                {t.timeRange ? t.timeRange[i].endTime : t.endTime}
              </p>
            ))}
        </div>
      </div>
      <Row
        style={{
          marginTop: 24,
          justifyContent: isModal ? "space-between" : "unset"
        }}
      >
        <Col
          span={exhibition?.stand?.passageDiagram ? 12 : 18}
          style={{
            maxWidth:
              isModal && !exhibition?.stand?.passageDiagram
                ? "54%"
                : exhibition?.stand?.passageDiagram
                ? "43%"
                : "39%",
            display: "flex",
            flexDirection: !exhibition?.stand?.passageDiagram ? "row-reverse" : "row",
            justifyContent: "space-between",
            alignItems: "baseline",
            gap: 96,
            paddingRight: "30px"
          }}
        >
          {/* {!exhibition?.stand?.passageDiagram && (
            <p style={{ width: 330 }}>
              {t("standNum")}: {exhibition?.stand?.boothNumber}
            </p>
          )} */}
          <div>
            <p className={s.descriptionTitle} style={{ textAlign: "left" }}>
              {t("description")}:
            </p>
            <p
              className={s.description}
              style={{
                marginTop: 18,
                textAlign: "left",
                fontWeight: isModal ? 400 : "unset",
                wordBreak: "break-all"
              }}
            >
              {exhibition?.stand?.description}
            </p>
          </div>
        </Col>

        <Col span={8}>
          <div className={s.flex}>
            <div className={s.left}>{t("standNum")}: </div>
            <div>{exhibition?.stand?.boothNumber}</div>
          </div>
          {!isImageDeleted && exhibition?.stand?.passageDiagram && (
            <>
              <p
                style={{
                  marginTop: 18,
                  marginBottom: 18,
                  textAlign: "left",
                  fontWeight: isModal ? 400 : "unset"
                }}
              >
                {t("passWayScheme")}:
              </p>
              <Image
                className={s.standImg}
                src={`${mediaUrl}${exhibition.stand.passageDiagram}`}
                preview={{
                  toolbarRender: () => <></>
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default ExhibitionPreview;
