/* eslint-disable react/prop-types */
import { Button, DatePicker, Form } from "antd";
import { DebounceSearchSelect, SearchSelect } from "../Search";
import { getAllFeedbackMainStatuses, getCountries } from "../../store/resourcesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { dateFormat } from "Helpers/timeDate";
import { recordsAPI } from "../../api/api";
import s from "Components/Search/Search.module.less";
import { useTranslation } from "react-i18next";

const RecordsOfDeletionsSearch = ({ onFiltersChange, onFiltersSubmit, onFiltersEmpty }) => {
  const [recordNames, setRecordNames] = useState([]);
  const [creatorNames, setCreatorNames] = useState([]);
  const [destroyerNames, setDestroyerNames] = useState([]);
  const { t } = useTranslation([
    "FA100-RecordsOfDeletions",
    "FA35-Promocodes",
    "FA51-MainRequestList"
  ]);
  const { allFeedbackMainStatuses, countries } = useSelector((state) => state.resources);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(getAllFeedbackMainStatuses());
    dispatch(getCountries());
  }, []);
  const onReset = () => {
    onFiltersEmpty();
    form.resetFields();
  };

  const saveRecords = (data) => {
    setRecordNames(
      data.map(({ name }) => ({
        label: name,
        value: name
      }))
    );
  };

  const saveCreators = (data) => {
    setCreatorNames(
      data.map(({ id, type, email, companyName }) => ({
        label: type === "admin" ? email : companyName,
        value: JSON.stringify({ id, type })
      }))
    );
  };

  const saveDestroyers = (data) => {
    setDestroyerNames(
      data.map(({ id, type, email, companyName }) => ({
        label: type === "admin" ? email : companyName,
        value: JSON.stringify({ id, type })
      }))
    );
  };

  const typeOptions = useMemo(
    () => [
      {
        label: t("types.product"),
        value: "product"
      },
      {
        label: t("types.service"),
        value: "service"
      },
      {
        label: t("types.exhibition"),
        value: "exhibition"
      },
      {
        label: t("types.stand"),
        value: "stand"
      }
    ],
    [t, countries]
  );

  return (
    <>
      <section className={` ${s.section}`}>
        <Form
          form={form}
          autoComplete="off"
          colon={false}
          labelAlign="left"
          layout="vertical"
          initialValues={{
            promocodesIds: []
          }}
          onValuesChange={onFiltersChange}
          onFinish={({ creatorId, destroyerId, ...otherData }) => {
            const parsedCreator = creatorId?.length > 0 && JSON.parse(creatorId[0]);
            const parsedDestroyer = destroyerId?.length > 0 && JSON.parse(destroyerId[0]);
            const newData = {
              ...(parsedCreator
                ? {
                    creatorId: parsedCreator.id,
                    creatorType: parsedCreator.type
                  }
                : {}),
              ...(parsedDestroyer
                ? {
                    destroyerId: parsedDestroyer.id,
                    destroyerType: parsedDestroyer.type
                  }
                : {}),
              ...otherData
            };
            console.log(destroyerId);
            onFiltersSubmit(newData);
          }}
        >
          <div className={s.scrollable} style={{ marginBottom: 32 }}>
            <div className={s.filters} style={{ minWidth: "1395px", gap: 32 }}>
              <Form.Item name="name" label={t("filters.Name")} style={{ minWidth: 140 }}>
                <DebounceSearchSelect
                  mode="multiple"
                  search={(text) => recordsAPI.searchDeletionHistory({ name: text })}
                  store={saveRecords}
                  options={recordNames}
                />
              </Form.Item>
              <Form.Item name="type" label={t("filters.Type")} style={{ minWidth: 140 }}>
                <SearchSelect allowClear options={typeOptions} />
              </Form.Item>
              <Form.Item name="creatorId" label={t("filters.Creator")} style={{ minWidth: 140 }}>
                <DebounceSearchSelect
                  mode="multiple"
                  search={(text) => recordsAPI.searchDeletionHistory({ creator: text })}
                  store={saveCreators}
                  options={creatorNames}
                />
              </Form.Item>

              <Form.Item
                name={["date", "createdFrom"]}
                label={t("filters.dateCreatedStartFrom")}
                style={{ flexDirection: "column" }}
              >
                <DatePicker
                  style={{ minWidth: 140 }}
                  format={dateFormat}
                  placeholder={t("all", { ns: "FA35-Promocodes" })}
                  placement="bottomRight"
                  showToday={false}
                />
              </Form.Item>

              <Form.Item
                name={["date", "createdTo"]}
                label={t("filters.dateCreatedEndTo")}
                style={{ flexDirection: "column" }}
              >
                <DatePicker
                  style={{ minWidth: 140 }}
                  format={dateFormat}
                  placeholder={t("all", { ns: "FA35-Promocodes" })}
                  placement="bottomRight"
                  showToday={false}
                />
              </Form.Item>

              <Form.Item
                name={["date", "destroyerFrom"]}
                label={t("filters.dateDestroyedStartFrom")}
                style={{ flexDirection: "column" }}
              >
                <DatePicker
                  style={{ minWidth: 140 }}
                  format={dateFormat}
                  placeholder={t("all", { ns: "FA35-Promocodes" })}
                  placement="bottomRight"
                  showToday={false}
                />
              </Form.Item>

              <Form.Item
                name={["date", "destroyerTo"]}
                label={t("filters.dateDestroyedEndTo")}
                style={{ flexDirection: "column" }}
              >
                <DatePicker
                  style={{ minWidth: 140 }}
                  format={dateFormat}
                  placeholder={t("all", { ns: "FA35-Promocodes" })}
                  placement="bottomRight"
                  showToday={false}
                />
              </Form.Item>

              <Form.Item
                name="destroyerId"
                label={t("filters.destroyer")}
                style={{ minWidth: 140 }}
              >
                <DebounceSearchSelect
                  mode="multiple"
                  search={(text) => recordsAPI.searchDeletionHistory({ destroyer: text })}
                  store={saveDestroyers}
                  options={destroyerNames}
                />
              </Form.Item>
            </div>
          </div>
          <div className={s.controls}>
            <Form.Item style={{ marginLeft: "auto" }}>
              <Button className={s.reset} onClick={onReset}>
                {t("reset", { ns: "FA35-Promocodes" })}
              </Button>
            </Form.Item>

            <Form.Item>
              <Button className={s.show} htmlType="submit">
                {t("show", { ns: "FA35-Promocodes" })}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </section>
    </>
  );
};

RecordsOfDeletionsSearch.propTypes = {
  qParams: PropTypes.object,
  setQParams: PropTypes.func
};
export default RecordsOfDeletionsSearch;
