export const SELLER_ROUTES = {
  outgoingRequestsMeetingSeller: "outgoing-requests-meeting-seller",
  outgoingRequestsMeetingModerSeller: "outgoing-requests-meeting-moder-seller",
  incomingRequestsSeller: "incoming-requests-seller",
  incomingRequestsMeetingSeller: "incoming-requests-meeting-seller",
  incomingRequestsMeetingModerSeller: "incoming-requests-meeting-moder-seller",
  addProduct: "add-product",
  addService: "add-service",
  editProductOrService: "edit-product-or-service",
  productAndServicePromotion: "product-and-service-promotion",
  myExhibitions: "my-exhibitions"
};

export const BUYER_ROUTES = {
  leaveRequestBuyer: "leave-request-buyer",
  outgoingRequestsBuyer: "outgoing-requests-buyer",
  outgoingRequestsMeetingBuyer: "outgoing-requests-meeting-buyer",
  outgoingRequestsMeetingModerBuyer: "outgoing-requests-meeting-moder-buyer",
  incomingRequestsMeetingBuyer: "incoming-requests-meeting-buyer",
  incomingRequestsMeetingModerBuyer: "incoming-requests-meeting-moder-buyer"
};

export const ROUTES = {
  ...SELLER_ROUTES,
  ...BUYER_ROUTES,
  catalog: "main-catalog",
  dialogs: "dialogs",
  paymentApplications: "payment-applications",
  userProfile: "user-profile",
  companyProfile: "change-company-profile",
  reportTechIssues: "report-tech-issues",
  allTechIssues: "all-tech-issues",
  makeComplaint: "make-complaint",
  allComplaints: "all-complaints",
  settings: "settings"
};

export const ADMIN_ROUTES = {
  exporterIncomingRequestMailTemplate: "exporter-incoming-request-mail-template",
  emailConfirmationMailTemplate: "email-confirmation-mail-template",
  passwordRecoveryTemplate: "password-recovery-template",
  meetingInvitationMailTemplate: "meeting-invitation-template",
  meetingInvitationModerMailTemplate: "meeting-invitation-moder-template",
  tariffs: "tariffs",
  employeesList: "employees-list",
  inquiries: "inquiries",
  technicalProblems: "technical-problems",
  requestsList: "requests-list",
  translationList: "translation-list",
  subscribedAccounts: "subscribed-accounts",
  meetingsList: "meetings-list",
  meetingsListModeration: "meetings-with-moderation-list",
  usersList: "users-list",
  addUser: "add-user",
  promotedGoods: "promoted-goods",
  complaints: "complaints",
  recordsOfDeletion: "records-of-deletions",
  promocodes: "promocodes",
  mainRequests: "main-requests",
  matchMakingRequests: "matchmaking-requests",
  searchRequestsWithoutMatch: "search-requests-with-no-match",
  paymentConfirmationMailTemplate: "payment-confirmation-mail-template",
  buyerAndSellerFeedbackMailTemplate: "buyer-and-seller-feedback-mail-template",
  questionsAndSuggestions: "questions-and-suggestions",
  moderationChanges: "moderation-changes",
  rejectedChangesNotificationMailTemplate: "rejected-changes-notification-mail-template",
  meetingInvitationFromSellerTemplate: "meeting-invitation-from-seller-template",
  meetingInvitationFromSellerModerTemplate: "meeting-invitation-from-seller-moder-template",
  meetingNotificationTemplate: "meeting-notification-mail-template",
  editAndViewExhibition: "edit-and-view-exhibition",
  addNewExhibition: "add-new-exhibition",
  standCanceledMailTemplate: "stand-canceled-mail-template"
};
