import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Tabs} from 'antd';
import PreviewAbout from '../PreviewAbout';
import PreviewProduct from '../PreviewProduct/PreviewProduct';
import PreviewService from '../PreviewService/PreviewService';
import {productsAPI, serviceAPI} from '../../../api/api';

const PreviewNav = ({prof}) => {
  const {t} = useTranslation('FK3-сompanyProfile');
  const [total, setTotal] = useState({service: 0, product: 0})
  useEffect(() => {
    Promise.all([
      productsAPI.getProducts('', '', '', '', '', prof.profile.id, true),
      serviceAPI.getServices('', '', '', '', '', prof.profile.id, true)
    ]).then((res) => {
      setTotal({product: res[0].data.total, service: res[1].data.total});
    })
  }, []);

  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab={t('link1')} key={'1'}><PreviewAbout profile={prof}/></Tabs.TabPane>
      <Tabs.TabPane tab={t('link8', {field: total.product})} key={'2'}><PreviewProduct profile={prof}/></Tabs.TabPane>
      <Tabs.TabPane tab={t('link7', {field: total.service})} key={'3'}><PreviewService profile={prof}/></Tabs.TabPane>
    </Tabs>
  );
};

export default PreviewNav;
