import { useEffect, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { formatUTCTime } from "Helpers/timeDate";

const filterFields = (fields) => {
  let isEmpty = true;

  const filteredFields = Object.entries(fields).reduce((obj, [key, value]) => {
    if (Array.isArray(value)) {
      if (value.length) {
        obj[key] = value.join(",");
        isEmpty = false;
      }
      return obj;
    }

    if (value || value === 0) {
      obj[key] = value;
      isEmpty = false;
    }

    return obj;
  }, {});

  return isEmpty ? null : filteredFields;
};

const filterDate = (date) => {
  let isEmpty = true;

  const filteredDate = Object.entries(date).reduce((obj, [key, value]) => {
    if (value) {
      obj[key] = formatUTCTime(value, "YYYY-MM-DD");
      isEmpty = false;
    }
    return obj;
  }, {});

  return isEmpty ? null : filteredDate;
};

const filterAll = ({ date, ...fields }) => {
  let filteredFields, filteredDate;

  if (fields) filteredFields = filterFields(fields);
  if (date) filteredDate = filterDate(date);

  if (!filteredFields && !filteredDate) return null;
  if (!filteredFields) return filteredDate;
  if (!filteredDate) return filteredFields;
  return {
    ...filteredFields,
    ...filteredDate
  };
};

const init = ({ defaultSorting, filtersApi }) => ({
  apiType: "WITH_Filters",
  filtersApi,
  api: filtersApi,
  filters: null,
  pagination: { pageSize: 10, current: 1 },
  sorting: defaultSorting,
  loading: false,
  error: null
});

const searchReducer = (state, { type, payload }) => {
  switch (type) {
    case "PAGE_SIZE":
      return {
        ...state,
        pagination: { pageSize: payload, current: 1 }
      };
    case "CURRENT_PAGE":
      return {
        ...state,
        pagination: { ...state.pagination, current: payload }
      };
    //////////////////////////
    case "SORTING":
      return {
        ...state,
        sorting: payload
      };
    //////////////////////////
    case "API":
      return {
        ...state,
        pagination: { ...state.pagination, current: 1 },
        api: payload.api,
        apiType: payload.withFilters ? "WITH_Filters" : "NO_Filters"
      };
    //////////////////////////
    case "FILTER":
      return {
        ...state,
        pagination: { ...state.pagination, current: 1 },
        api: state.filtersApi,
        apiType: "WITH_Filters",
        filters: filterAll(payload)
      };
    case "FILTER_RESET":
      return { ...state, pagination: { ...state.pagination, current: 1 }, filters: null };
    //////////////////////////
    case "FETCH_INIT":
      return {
        ...state,
        loading: true,
        error: null
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        loading: false,
        error: payload
      };
    //////////////////////////
    default:
      throw new Error();
  }
};

const useSearch = (
  filtersApi,
  store = () => {
  },
  type = "submit",
  fetchOnInit = false,
  complementDate = null,
  defaultSorting = { sortColumn: "createdAt", sortDirection: "DESC" }
) => {
  const [{ api, apiType, filters, pagination, sorting, loading, error }, dispatch] = useReducer(
    searchReducer,
    { defaultSorting, filtersApi },
    init
  );

  const isCanFetchRef = useRef(fetchOnInit);
  const isFiltersChangedRef = useRef(false);
  const controller = new AbortController();

  const fetchData = () => {
    if (isCanFetchRef.current) {
      let params = {
        ...sorting,
        page: pagination.current,
        limit: pagination.pageSize
      };

      if (filters) params = { ...params, ...filters };

      if (complementDate) {
        Object.entries(complementDate).forEach(([to, from]) => {
          params[to] = params[from];
        });
      }

      dispatch({ type: "FETCH_INIT" });
      api({
        params,
        signal: controller.signal
      })
        .then((res) => {
          store(res.data);
          dispatch({ type: "FETCH_SUCCESS" });
        })
        .catch((err) => {
          dispatch({ type: "FETCH_FAILURE", payload: err });
        });
    }
  };

  useEffect(() => {
    fetchData();
    return () => controller.abort;
  }, [api, filters, pagination, sorting]);

  const tmpFunc = () => {
    isFiltersChangedRef.current = true;
    isCanFetchRef.current = true;
  };
  const allowFetch = () => {
    isCanFetchRef.current = true;
  };
  const onChangeApi = (api, withFilters = true) => {
    dispatch({ type: "API", payload: { api, withFilters } });
  };

  const onFiltersChange = (_, filters) => {
    if (type === "submit") {
      isFiltersChangedRef.current = true;
    }
    if (type === "change") {
      isCanFetchRef.current = true;
      dispatch({ type: "FILTER", payload: filters });
    }
  };

  const onFiltersSubmit = (filters) => {
    if (type === "submit" && isFiltersChangedRef.current) {
      isFiltersChangedRef.current = false;
      isCanFetchRef.current = true;
      dispatch({ type: "FILTER", payload: filters });
    }
  };

  const onFiltersEmpty = () => {
    if (filters && apiType === "WITH_Filters") dispatch({ type: "FILTER_RESET" });
  };

  const setPageSize = (pageSize) => {
    dispatch({ type: "PAGE_SIZE", payload: pageSize });
  };

  const setCurrentPage = (currentPage) => {
    dispatch({ type: "CURRENT_PAGE", payload: currentPage });
  };

  const setSorting = (sorting) => {
    dispatch({ type: "SORTING", payload: sorting });
  };

  return [
    { filters, pagination, sorting, loading, error },
    {
      fetchData,
      onChangeApi,
      onFiltersChange,
      onFiltersSubmit,
      onFiltersEmpty,
      setPageSize,
      setCurrentPage,
      setSorting,
      tmpFunc,
      allowFetch
    }
  ];
};

useSearch.propTypes = {
  filtersApi: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }),
  store: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  fetchOnInit: PropTypes.bool,
  complementDate: PropTypes.object,
  defaultSorting: PropTypes.object
};

export default useSearch;
