import { createSlice } from "@reduxjs/toolkit";
import { adminAuthAPI, authAPI } from "../api/api";
import { clearAdditionalState } from "./changeCompanyProfileSlice";
import { clearNotifications } from "./notificationsFeedSlice";

const initialState = {
  lang: "",
  isLoading: false,
  isAdmin: false,
  authLoading: true,
  user: null,
  userType: ""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setAuthLoading(state, action) {
      state.authLoading = action.payload;
    },
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setLang(state, action) {
      state.lang = action.payload;
    }
  }
});

export const { setUser, setIsAdmin, setAuthLoading, setIsLoading, setUserType, setLang } =
  authSlice.actions;

export const authMe = () => (dispatch) => {
  dispatch(setAuthLoading(true));
  Promise.allSettled([authAPI.whoAmI(), adminAuthAPI.whoAmI()])
    .then((results) => {
      const fulfilled = results.find((r) => r.status === "fulfilled");
      if (fulfilled) {
        dispatch(setUser(fulfilled.value.data.admin || fulfilled.value.data.user));
        if (fulfilled.value.data.admin) {
          dispatch(setIsAdmin(true));
        }
      }
    })
    .finally(() => {
      dispatch(setAuthLoading(false));
    });
};

export const me = () => (dispatch) => {
  dispatch(setAuthLoading(true));
  authAPI
    .whoAmI()
    .then((res) => {
      dispatch(setUser(res.data.user));
      dispatch(setIsAdmin(false));
    })
    .catch((e) => {
      if (e.response.status === 401) {
        dispatch(setUser(null));
        dispatch(setIsAdmin(false));
      }
    })
    .finally(() => {
      dispatch(setAuthLoading(false));
    });
};

export const userLogout = () => async (dispatch) => {
  await dispatch(setIsLoading(true));
  await authAPI.logout().finally(() => {
    dispatch(setUser(null));
    dispatch(setIsAdmin(false));
    dispatch(setUserType(""));
    dispatch(setIsLoading(false));
    dispatch(clearNotifications());
    localStorage.removeItem("SAVED_EDIT_DATA");
    localStorage.removeItem("PREV_SAVED_EDIT_DATA");
  });
  await dispatch(clearAdditionalState());
};

export const adminMe = () => (dispatch) => {
  dispatch(setAuthLoading(true));
  adminAuthAPI
    .whoAmI()
    .then((res) => {
      dispatch(clearNotifications());
      dispatch(setUser(res.data.admin));
      dispatch(setIsAdmin(true));
    })
    .catch((e) => {
      if (e.response.status === 401) {
        dispatch(setUser(null));
        dispatch(setIsAdmin(false));
      }
    })
    .finally(() => {
      dispatch(setAuthLoading(false));
    });
};
export const adminLogout = () => (dispatch) => {
  dispatch(setIsLoading(true));
  adminAuthAPI.logout().finally(() => {
    dispatch(setUser(null));
    dispatch(setIsAdmin(false));
    dispatch(setIsLoading(false));
    localStorage.removeItem("SAVED_EDIT_DATA");
    localStorage.removeItem("PREV_SAVED_EDIT_DATA");
  });
};

export default authSlice.reducer;
